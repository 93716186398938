import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Col, Row, Select } from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { required } from '../../../../../../utils/utils';

const propTypes = {
  fieldArray: PropTypes.arrayOf(PropTypes.object),
};

function RelatedFields({ fieldArray }) {
  const { locale, formatMessage } = useIntl();

  const relatedFields = fieldArray?.filter((field) => field.type === 'text');

  const options = [];
  (relatedFields || []).forEach((opt) => {
    const fieldLabel = opt.label?.find(
      (field) => field?.locale === locale,
    )?.value;
    options.push({ value: opt.name, label: fieldLabel });
  });

  return (
    <Row>
      <Col xs={6}>
        <Field
          //   id={`inventory-templates-form-generator-${type}-field-${index}`}
          component={Select}
          fullWidth
          name="relatedTextField"
          label={
            <FormattedMessage
              id="ui-plugin-inventory-templates.driveNewFieldsModal.relatedTextField.label"
              defaultMessage="Related text field"
            />
          }
          dataOptions={[
            {
              label: formatMessage({
                id: 'ui-plugin-inventory-templates.driveNewFieldsModal.selectRelatedTextField.chooseMsg',
                defaultMessage: 'Choose related text field',
              }),
              value: '',
            },
            ...options,
          ]}
          required
          validate={required}
          disabled={options.length === 1}
        />
      </Col>
    </Row>
  );
}

RelatedFields.propTypes = propTypes;

export default RelatedFields;
