import { FormattedMessage } from 'react-intl';

// eslint-disable-next-line import/prefer-default-export
export const required = (value) => (value ? undefined : (
  <strong>
    <FormattedMessage
      id="ui-reports.validation.required"
      defaultMessage="required"
    />
  </strong>
));

export const removeAllFields = (fields) => {
  fields.forEach((field) => {
    fields.remove(
      fields.value.findIndex(
        (fieldId) => fieldId.rowIndex === field.rowIndex,
      ),
    );
  });
};
