import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IfPermission } from '@folio/stripes-core';
import ChooseTemplateRoute from './routes/ChooseTemplateRoute';
import CreateNewInstanceRoute from './routes/CreateNewInstanceRoute';
import DataProvider from './providers/DataProvider';
import TemplatesListRoute from './routes/TemplatesListRoute';
import ViewTemplateRoute from './routes/ViewTemplateRoute';
import EditTemplateRoute from './routes/EditTemplateRoute';
import TemplateBuilderRoute from './routes/TemplateBuilderRoute';
import CustomFieldsRoute from './routes/CustomFieldsRoute';
import CustomFieldsManager from './components/CustomFields/components/CustomFieldsEdit';

function InventoryTemplatesPlugin({ basePath, redirectTo }) {
  return (
    <IfPermission perm="ui-plugin-inventory-templates.create">
      <DataProvider>
        <Switch>
          {redirectTo && (
            <Redirect exact from={basePath} to={`${basePath}/${redirectTo}`} />
          )}
          <Route
            path={`${basePath}/choose-source`}
            render={() => <ChooseTemplateRoute basePath={basePath} />}
          />
          <Route
            path={`${basePath}/create/:templateId`}
            render={() => <CreateNewInstanceRoute basePath={basePath} />}
          />
          <Route
            path={`${basePath}/list-templates`}
            render={() => <TemplatesListRoute basePath={basePath} />}
          />
          <Route
            path={`${basePath}/template-builder/create`}
            render={() => <TemplateBuilderRoute basePath={basePath} />}
          />
          <Route
            path={`${basePath}/template-builder/:templateId/edit`}
            render={() => <TemplateBuilderRoute basePath={basePath} />}
          />
          <Route
            path={`${basePath}/:templateId/view`}
            render={() => <ViewTemplateRoute basePath={basePath} />}
          />
          <Route
            path={`${basePath}/:templateId/edit`}
            render={() => <EditTemplateRoute basePath={basePath} />}
          />

          <Route
            path={`${basePath}/custom-fields-list`}
            render={() => <CustomFieldsRoute basePath={basePath} />}
          />
          <Route
            path={`${basePath}/customfields/create`}
            render={() => <CustomFieldsManager basePath={basePath} />}
          />
          <Route
            path={`${basePath}/customfields/:customFieldId/edit`}
            render={() => <CustomFieldsManager basePath={basePath} />}
          />
        </Switch>
      </DataProvider>
    </IfPermission>
  );
}

InventoryTemplatesPlugin.propTypes = {
  basePath: PropTypes.string,
  redirectTo: PropTypes.string,
};

export default InventoryTemplatesPlugin;
