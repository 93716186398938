/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Icon,
  Row,
  TextField,
} from '@folio/stripes-components';
import { Field } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { uniqueId } from 'lodash';
import { required } from '../../../../../../utils/utils';

const propTypes = {
  fields: PropTypes.object,
  drivedFields: PropTypes.object,
  fieldData: PropTypes.object,
  unitLabel: PropTypes.string,
};

function RenderDrivedField({ fields, drivedFields, fieldData, unitLabel }) {
  const onUpdateFields = () => {
    fields.push({
      [drivedFields.name]: fieldData.value,
      [drivedFields.relatedTextField]: '',
    });
  };

  return (
    <>
      {fields.map((fieldName, index) => {
        return (
          <>
            <Row key={fieldName}>
              <Col xs={11}>
                <Field
                  id={`inventory-templates-form-generator-renderDrivedField-${drivedFields?.type}-field-${index}`}
                  component={TextField}
                  fullWidth
                  name={`${fieldName}.${drivedFields?.relatedTextField}`}
                  required={fieldData?.isRequired}
                  validate={fieldData?.isRequired ? required : undefined}
                  type="text"
                  disabled={!fieldData?.canEdit}
                />
              </Col>
              <Col xs={1}>
                <Button
                  id={`inventory-templates-renderDrivedField-remove-${drivedFields.name}-${index}-btn`}
                  buttonStyle="slim"
                  onClick={() => fields.remove(index)}
                >
                  <Icon icon="trash" />
                </Button>
              </Col>
            </Row>
          </>
        );
      })}
      <Button
        id={uniqueId('inventory-templates-renderDrivedField-add-btn')}
        onClick={() => onUpdateFields()}
      >
        <FormattedMessage
          id="ui-plugin-inventory-templates.formGenerator.renderDrivedField.addBtn"
          values={{ unitLabel }}
          defaultMessage="Add {unitLabel}"
        />
      </Button>
    </>
  );
}

RenderDrivedField.propTypes = propTypes;

export default RenderDrivedField;
