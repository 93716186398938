import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import {
  Button,
  Col,
  Icon,
  Modal,
  ModalFooter,
  Row,
  TextField,
} from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldArray } from 'react-final-form-arrays';
import FieldsBuilder from '../FieldsBuilder';
import LocalizedTextField from '../../LocalizedTextField';

function AddNewSectionForm({
  open,
  onClose,
  arrayFields,
  defaultScheme,
  fieldName,
  initialValues,
  actionType,
}) {
  const intl = useIntl();

  /** Handle calling submit button from outside the final form */
  let submit;

  const onHandleSubmit = (values) => {
    if (actionType === 'create') {
      arrayFields.push({
        sectionId: crypto.randomUUID(),
        sectionLabel: values.sectionLabel,
        sectionFields: values.sectionFields,
      });
    } else {
      const sectionIndex = arrayFields.value.findIndex(
        (field) => field.sectionId === initialValues.sectionId,
      );
      arrayFields.update(sectionIndex, {
        sectionId: crypto.randomUUID(),
        sectionLabel: values.sectionLabel,
        sectionFields: values.sectionFields,
      });
    }

    onClose();
  };

  const renderFooter = (pristine, submitting, values) => {
    return (
      <ModalFooter>
        <Button
          id="inventory-template-plugin-add-field-modal-footer-add-btn"
          buttonStyle="primary"
          type="submit"
          onClick={(event) => {
            submit(event);
          }}
          disabled={pristine || submitting || !values.sectionLabel}
        >
          <Icon icon="plus-sign">
            <FormattedMessage
              id="ui-plugin-inventory-templates.buttons.add"
              defaultMessage="Add"
            />
          </Icon>
        </Button>
        <Button
          id="inventory-template-plugin-add-field-modal-footer-close-btn"
          buttonStyle="slim"
          onClick={onClose}
        >
          <Icon icon="times-circle-solid" size="large">
            <FormattedMessage id="stripes-core.button.close" />
          </Icon>
        </Button>
      </ModalFooter>
    );
  };

  return (
    <Form
      onSubmit={onHandleSubmit}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
      }}
    >
      {({ handleSubmit, pristine, submitting, values }) => {
        submit = handleSubmit;
        return (
          <Modal
            id="inventory-template-plugin-add-field-modal"
            footer={renderFooter(pristine, submitting, values)}
            open={open}
            onClose={onClose}
            dismissible
            label={
              <Icon icon={actionType === 'create' ? 'plus-sign' : 'edit'}>
                {actionType === 'create' ? (
                  <FormattedMessage
                    id="ui-plugin-inventory-templates.addNewSectionModal.label.create"
                    defaultMessage="Add new section"
                  />
                ) : (
                  <FormattedMessage
                    id="ui-plugin-inventory-templates.addNewSectionModal.label.edit"
                    values={{
                      sectionName: initialValues.sectionLabel.find(
                        (locale) => locale.locale === intl.locale,
                      )?.value,
                    }}
                    defaultMessage="Edit {sectionName} section"
                  />
                )}
              </Icon>
            }
            size="large"
          >
            <form id="new-section-form" onSubmit={handleSubmit}>
              <Row>
                <Col xs={6}>
                  <FieldArray
                    id={`localizedArrayField-${fieldName}`}
                    name="sectionLabel"
                    component={LocalizedTextField}
                    fieldComponent={TextField}
                    fieldLabel={
                      <FormattedMessage
                        id="ui-plugin-inventory-templates.templateSection.fields.label"
                        defaultMessage="Section Label"
                      />
                    }
                    isRequired
                  />
                </Col>
              </Row>
              <FieldArray
                id={`template-fields-builder-${fieldName}`}
                name="sectionFields"
                component={FieldsBuilder}
                defaultScheme={defaultScheme}
                actionType={actionType}
              />
            </form>
          </Modal>
        );
      }}
    </Form>
  );
}

AddNewSectionForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  arrayFields: PropTypes.arrayOf(PropTypes.object),
  defaultScheme: PropTypes.arrayOf(PropTypes.object),
  fieldName: PropTypes.string,
  initialValues: PropTypes.object,
  actionType: PropTypes.string,
};

export default AddNewSectionForm;
