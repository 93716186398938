/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import PropTypes from 'prop-types';
import { Label, MultiColumnList, NoValue } from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';
import InfoModal from '../../../ui-kit/InfoModal';

const propTypes = {
  sectionFields: PropTypes.arrayOf(PropTypes.object),
};

function SectionFieldsList({ sectionFields }) {
  const intl = useIntl();

  const fieldLabelFormatter = (field) => {
    return (
      <Label>
        {field.label.find((locale) => locale.locale === intl.locale)?.value}
      </Label>
    );
  };

  const schemaFormatter = (field) => {
    return (
      <FormattedMessage
        id={`ui-plugin-inventory-templates.templateView.sectionFieldsList.column.schema.${field.schema}`}
        defaultMessage={field.schema}
      />
    );
  };

  const defaultValueFormatter = (field) => {
    if (field.type !== 'select') {
      return <NoValue />;
    }

    return field.fieldDataOption
      .find((opt) => opt.value === field.defaultValue)
      ?.label?.find((lab) => lab?.locale === intl.locale)?.value;
  };

  const infoFieldFormatter = (field) => {
    return (
      <InfoModal
        infoTitle={
          <strong>
            {field.label.find((locale) => locale.locale === intl.locale)?.value}
          </strong>
        }
        allowAnchorClick
        iconSize="medium"
        infoContent={
          field.helpeText || (
            <FormattedMessage
              id="ui-plugin-inventory-templates.customFieldsView.info.defaultContent"
              defaultMessage="No helpe text found."
            />
          )
        }
        buttonLabel={
          <FormattedMessage
            id="ui-plugin-inventory-templates.templateField.info.btnLabel.readMore"
            defaultMessage="Reade more"
          />
        }
        buttonHref="https://docs.folio.org/docs"
        buttonTarget="_blank"
      />
    );
  };

  return (
    <MultiColumnList
      interactive={false}
      contentData={sectionFields}
      visibleColumns={[
        'label',
        'schema',
        'defaultValue',
        'isRequired',
        'canEdit',
        'isRepeatable',
        'info',
      ]}
      columnWidths={{
        label: '30%',
        schema: '15%',
        defaultValue: '15%',
        isRequired: '10%',
        canEdit: '10%',
        isRepeatable: '10%',
        info: '10%',
      }}
      columnMapping={{
        label: intl.formatMessage({
          id: 'ui-plugin-inventory-templates.templateView.sectionFieldsList.column.label',
          defaultMessage: 'Label',
        }),
        schema: intl.formatMessage({
          id: 'ui-plugin-inventory-templates.templateView.sectionFieldsList.column.schema',
          defaultMessage: 'Schema',
        }),
        defaultValue: intl.formatMessage({
          id: 'ui-plugin-inventory-templates.templateView.sectionFieldsList.column.defaultValue',
          defaultMessage: 'Default value',
        }),
        isRequired: intl.formatMessage({
          id: 'ui-plugin-inventory-templates.templateView.sectionFieldsList.column.isRequired',
          defaultMessage: 'Required',
        }),
        canEdit: intl.formatMessage({
          id: 'ui-plugin-inventory-templates.templateView.sectionFieldsList.column.canEdit',
          defaultMessage: 'Editable',
        }),
        isRepeatable: intl.formatMessage({
          id: 'ui-plugin-inventory-templates.templateView.sectionFieldsList.column.isRepeatable',
          defaultMessage: 'Repeatable',
        }),
        info: intl.formatMessage({
          id: 'ui-plugin-inventory-templates.templateView.sectionFieldsList.column.info',
          defaultMessage: 'Info',
        }),
      }}
      formatter={{
        label: (field) => fieldLabelFormatter(field),
        schema: (field) => schemaFormatter(field),
        isRequired: (field) => field.isRequired?.toString(),
        canEdit: (field) => field.canEdit?.toString(),
        isRepeatable: (field) => field.isRepeatable?.toString(),
        defaultValue: (field) => defaultValueFormatter(field),
        info: (field) => infoFieldFormatter(field),
      }}
    />
  );
}

SectionFieldsList.propTypes = propTypes;

export default SectionFieldsList;
