import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  Button,
  Checkbox,
  Col,
  Icon,
  Label,
  Row,
  Select,
  TextArea,
  TextField,
} from '@folio/stripes-components';
import { Field } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { OnChange } from 'react-final-form-listeners';
import { FieldArray } from 'react-final-form-arrays';
import LocalizedTextField from '../../../ui-kit/LocalizedTextField';
import { required } from '../../../../utils/utils';
import RenderFieldDataOption from './RenderFieldDataOption';
import styles from '../styles.css';

const propTypes = {};

function FormFields(props) {
  const [isRepeatable, setIsRepeatable] = useState(false);
  const [fieldDataType, setFieldDataType] = useState('');
  const { formatMessage } = useIntl();

  const fieldTypesDataOptions = [
    {
      label: formatMessage({
        id: 'ui-plugin-inventory-templates.customFieldsForm.fieldtypes.select.chooseMsg',
        defaultMessage: 'Choose field data type ',
      }),
      value: '',
    },
    {
      label: formatMessage({
        id: 'ui-plugin-inventory-templates.customFieldsForm.fieldtypes.textField',
        defaultMessage: 'Text field',
      }),
      value: 'text',
    },
    {
      label: formatMessage({
        id: 'ui-plugin-inventory-templates.customFieldsForm.fieldtypes.textArea',
        defaultMessage: 'Text area field',
      }),
      value: 'textArea',
    },
    {
      label: formatMessage({
        id: 'ui-plugin-inventory-templates.customFieldsForm.fieldtypes.select',
        defaultMessage: 'Select field',
      }),
      value: 'select',
    },
    {
      label: formatMessage({
        id: 'ui-plugin-inventory-templates.customFieldsForm.fieldtypes.date',
        defaultMessage: 'Date field',
      }),
      value: 'date',
    },
    {
      label: formatMessage({
        id: 'ui-plugin-inventory-templates.customFieldsForm.fieldtypes.checkbox',
        defaultMessage: 'Checkbox field',
      }),
      value: 'checkbox',
    },
    {
      label: formatMessage({
        id: 'ui-plugin-inventory-templates.customFieldsForm.fieldtypes.fieldArray',
        defaultMessage: 'Array field',
      }),
      value: 'fieldArray',
    },
    {
      label: formatMessage({
        id: 'ui-plugin-inventory-templates.customFieldsForm.fieldtypes.toggleButton',
        defaultMessage: 'Toggle button',
      }),
      value: 'toggleButton',
    },
  ];

  return (
    <div className={styles.form_container}>
      <OnChange name="isRepeatable">
        {(value) => {
          setIsRepeatable(value);
        }}
      </OnChange>
      <OnChange name="type">
        {(value) => {
          setFieldDataType(value);
        }}
      </OnChange>
      <Row>
        <Col xs={4}>
          <Field
            id="isRepeatable"
            name="isRepeatable"
            component={Checkbox}
            // checked={isRepeatable}
            fullWidth
            label={
              <FormattedMessage
                id="ui-plugin-inventory-templates.customFields.editForm.formFields.isRepeatable"
                defaultMessage="Is repeatable"
              />
            }
            type="checkbox"
          />
        </Col>
        <Col xs={4}>
          <Field
            id="isRequired"
            name="isRequired"
            component={Checkbox}
            // checked={isRequired}
            fullWidth
            label={
              <FormattedMessage
                id="ui-plugin-inventory-templates.customFields.editForm.formFields.isRequired"
                defaultMessage="Is required"
              />
            }
            type="checkbox"
          />
        </Col>
        <Col xs={4}>
          <Field
            id="canEdit"
            name="canEdit"
            component={Checkbox}
            // checked={canEdit}
            fullWidth
            label={
              <FormattedMessage
                id="ui-plugin-inventory-templates.customFields.editForm.formFields.canEdit"
                defaultMessage="Can edit"
              />
            }
            type="checkbox"
          />
        </Col>
      </Row>
      <hr />
      <pr />
      <Row className={styles.row_container}>
        <Col xs={6}>
          <FieldArray
            name="label"
            component={LocalizedTextField}
            fieldComponent={TextField}
            fieldLabel={
              <FormattedMessage
                id="ui-plugin-inventory-templates.customFields.editForm.formFields.label"
                defaultMessage="Label"
              />
            }
          />
        </Col>
        <Col xs={6}>
          <Field
            component={TextArea}
            fullWidth
            fitContent
            name="helpeText"
            label={
              <FormattedMessage
                id="ui-plugin-inventory-templates.customFields.editForm.formFields.helpeText"
                defaultMessage="Helpe text"
              />
            }
            // required
            // validate={required}
            type="text"
          />
        </Col>
      </Row>

      <Row className={styles.row_container}>
        {isRepeatable && (
          <Col xs={6}>
            <FieldArray
              name="singularLabel"
              component={LocalizedTextField}
              fieldComponent={TextField}
              fieldLabel={
                <FormattedMessage
                  id="ui-plugin-inventory-templates.customFields.editForm.formFields.singularLabel"
                  defaultMessage="Singular Label"
                />
              }
            />
          </Col>
        )}
      </Row>

      <Row className={styles.row_container}>
        <Col xs={6}>
          <Field
            component={TextField}
            fullWidth
            name="name"
            label={
              <FormattedMessage
                id="ui-plugin-inventory-templates.customFields.editForm.formFields.fieldName"
                defaultMessage="Field name"
              />
            }
            required
            validate={required}
            type="text"
          />
        </Col>
        <Col xs={6}>
          <Field
            component={Select}
            fullWidth
            name="type"
            label={
              <FormattedMessage
                id="ui-plugin-inventory-templates.customFields.editForm.formFields.fieldType"
                defaultMessage="Field type"
              />
            }
            dataOptions={fieldTypesDataOptions}
            required
            validate={required}
            type="text"
          />
        </Col>
      </Row>
      {fieldDataType === 'select' && (
        <Row className={styles.row_container}>
          <Col sm={12}>
            <Accordion
              id="custom-field-data-option}"
              label={
                <FormattedMessage
                  id="ui-plugin-inventory-templates.customFieldsForm.fieldtypes.select.fieldDataOption"
                  defaultMessage="Field Data options"
                />
              }
              closedByDefault={false}
              separator
            >
              <FieldArray
                name="fieldDataOption"
                component={RenderFieldDataOption}
              />
            </Accordion>
          </Col>
        </Row>
      )}
    </div>
  );
}

FormFields.propTypes = propTypes;

export default FormFields;
