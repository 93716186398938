import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  Icon,
  Loading,
  NavList,
  NavListItem,
  Pane,
  Paneset,
} from '@folio/stripes-components';
import useTemplate from '../../../hooks/useTemplate';

function TemplatesList({ basePath }) {
  const { templates, isLoading } = useTemplate();

  const intl = useIntl();

  return (
    <Paneset>
      <Pane
        id="inventory-plugin-template-list-pane"
        paneTitle={
          <FormattedMessage
            id="ui-plugin-inventory-templates.templatesList.pane.paneTitle"
            defaultMessage="Inventory templates"
          />
        }
        fluidContentWidth
        centeredContent
        lastMenu={
          <Button
            id="template-list-pane-lastMenu-new-btn2"
            buttonStyle="primary"
            marginBottom0
            to={`${basePath}/template-builder/create`}
          >
            <Icon icon="plus-sign">
              <FormattedMessage
                id="ui-plugin-inventory-templates.buttons.templateBuilder"
                defaultMessage="Template Builder"
              />
            </Icon>
          </Button>
        }
      >
        <NavList>
          {isLoading && <Loading size="large" />}
          {!isLoading &&
            templates.map((template) => {
              const localizedName = template.name?.find(
                (field) => field?.locale === intl.locale,
              )?.value;

              return (
                <NavListItem
                  key={template.id}
                  to={`${basePath}/${template.id}/view`}
                >
                  {localizedName}
                </NavListItem>
              );
            })}
        </NavList>
      </Pane>
    </Paneset>
  );
}

TemplatesList.propTypes = {
  basePath: PropTypes.string,
};

export default TemplatesList;
