import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  Layer,
  Loading,
  Pane,
} from '@folio/stripes-components';
import { useHistory, useParams } from 'react-router-dom';
import { IfPermission } from '@folio/stripes-core';
import { FormattedMessage, useIntl } from 'react-intl';
import { ViewMetaData } from '@folio/stripes/smart-components';
import useTemplate from '../../../hooks/useTemplate';
import css from '../../../shared/sharedStyles.css';
import TemplateInfoAccordion from './TemplateInfoAccordion';
import TemplateFieldsAccordion from './TemplateFieldsAccordion';

function ViewTemplate({ basePath }) {
  const history = useHistory();
  const params = useParams();
  const { templates, isLoading } = useTemplate(params.templateId);
  const { locale } = useIntl();
  const localizedName = templates?.name?.find(
    (field) => field?.locale === locale,
  )?.value;

  const getActionMenu = ({ onToggle }) => {
    return (
      <div>
        <IfPermission perm="ui-plugin-inventory-templates.create">
          <Button
            id="inventory-templates-plugin-view-template-edit-btn"
            buttonStyle="dropdownItem"
            to={`${basePath}/template-builder/${params.templateId}/edit`}
          >
            <Icon icon="edit">
              <FormattedMessage
                id="ui-plugin-inventory-templates.buttons.edit"
                defaultMessage="Edit template"
              />
            </Icon>
          </Button>
        </IfPermission>

        <IfPermission perm="ui-plugin-inventory-templates.create">
          <Button
            id="inventory-templates-plugin-view-template-clone-btn"
            buttonStyle="dropdownItem"
            onClick={() => {
              onToggle();
              //   this.toggleDuplicateConfirmModal();
            }}
            disabled
          >
            <Icon icon="duplicate">
              <FormattedMessage
                id="ui-plugin-inventory-templates.buttons.clone"
                defaultMessage="Clone template"
              />
            </Icon>
          </Button>
        </IfPermission>

        <IfPermission perm="ui-plugin-inventory-templates.create">
          <Button
            id="inventory-templates-plugin-view-template-delete-btn"
            buttonStyle="dropdownItem"
            onClick={() => {
              onToggle();
              //   this.showConfirmDelete();
            }}
            disabled
          >
            <Icon icon="trash">
              <FormattedMessage
                id="ui-plugin-inventory-templates.buttons.delete"
                defaultMessage="Delete template"
              />
            </Icon>
          </Button>
        </IfPermission>
      </div>
    );
  };

  return (
    <Layer contentLabel="View inventory template" isOpen>
      {isLoading && (
        <div className={css.loading}>
          <Loading size="xlarge" />
        </div>
      )}
      {!isLoading && (
        <Pane
          actionMenu={getActionMenu}
          id="inventory-templates-plugin-view-template-pane"
          defaultWidth="fill"
          paneTitle={
            <FormattedMessage
              id="ui-plugin-inventory-templates.viewTemplate.pane.paneTitle"
              values={{ template: localizedName }}
              defaultMessage="View {template} template"
            />
          }
          dismissible
          onClose={() => history.push(`${basePath}/list-templates`)}
        >
          <div className={css.formStyle}>
            <ViewMetaData metadata={templates.metadata} />
            <TemplateInfoAccordion template={templates} />
            <TemplateFieldsAccordion template={templates} />
          </div>
        </Pane>
      )}
    </Layer>
  );
}

ViewTemplate.propTypes = {
  basePath: PropTypes.string,
};

export default ViewTemplate;
