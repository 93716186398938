import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  Pane,
  Paneset,
  Tooltip,
} from '@folio/stripes-components';
import { FieldArray } from 'react-final-form-arrays';
import { FormattedMessage } from 'react-intl';
import { FormSpy } from 'react-final-form';
import FormPreView from '../../FormPreView';
import RenderFieldArray from './RenderFieldArray';

const propTypes = {
  paneTitle: PropTypes.node,
  appIcon: PropTypes.node,
  paneId: PropTypes.string,
  fieldArrayName: PropTypes.string,
  defaultScheme: PropTypes.arrayOf(PropTypes.object),
  initialValues: PropTypes.arrayOf(PropTypes.object),
};

function SectionsBuilder({
  paneTitle,
  paneId,
  appIcon,
  fieldArrayName,
  defaultScheme,
  initialValues,
}) {
  let arrayFields;
  const [showPreviewPane, setShowPreviewPane] = useState(true);
  const [openSectionBuilder, setOpenSectionBuilder] = useState(false);
  const [actionType, setActionType] = useState('create');
  const [sectionInitValues, setSectionInitValues] = useState({
    sectionLabel: [
      {
        locale: 'ar',
        value: '',
      },
      {
        locale: 'en-US',
        value: '',
      },
    ],
    sectionFields: [],
  });

  useEffect(() => {
    if (actionType === 'create') {
      setSectionInitValues({
        sectionLabel: [
          {
            locale: 'ar',
            value: '',
          },
          {
            locale: 'en-US',
            value: '',
          },
        ],
        sectionFields: [],
      });
    }
  }, [actionType]);

  return (
    <Paneset>
      <Pane
        id={`${paneId}-builder-pane`}
        // defaultWidth="60%"
        appIcon={appIcon || <div />}
        paneTitle={paneTitle}
        fluidContentWidth
        centeredContent
        padContent
        lastMenu={
          !showPreviewPane ? (
            <Tooltip
              id="fields-builder-hide-pane-tooltip"
              text={
                <FormattedMessage
                  id="ui-plugin-inventory-templates.tooltip.showformPreviewPane.msg"
                  defaultMessage="Show form preview pane"
                />
              }
            >
              {({ ref, ariaIds }) => (
                <Button
                  buttonStyle="dropdownItem"
                  id="fields-builder-hide-pane-btn"
                  marginBottom0
                  onClick={() => setShowPreviewPane(true)}
                  aria-labelledby={ariaIds.text}
                  ref={ref}
                >
                  <Icon icon="eye-open" size="large" />
                </Button>
              )}
            </Tooltip>
          ) : (
            <div />
          )
        }
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            onClick={() => {
              setOpenSectionBuilder(true);
              setActionType('create');
            }}
            onMouseDown={(e) => e.preventDefault()}
          >
            <Icon icon="plus-sign">
              <FormattedMessage
                id="ui-plugin-inventory-templates.templateFields.builder.openSectionsBuilder"
                defaultMessage="New section"
              />
            </Icon>
          </Button>
          <Button
            onClick={() => {
              initialValues.map((field) => arrayFields.push(field));
            }}
            onMouseDown={(e) => e.preventDefault()}
          >
            <Icon icon="lightning">
              <FormattedMessage
                id="ui-plugin-inventory-templates.templateFields.builder.importSections"
                defaultMessage="Import sections from default scheme"
              />
            </Icon>
          </Button>
        </div>
        <div>
          <FieldArray
            id={`template-${paneId}-sections-builder`}
            name={`templateFields.${fieldArrayName}`}
            render={({ fields }) => {
              arrayFields = fields;
              return (
                <RenderFieldArray
                  fields={fields}
                  openSectionBuilder={openSectionBuilder}
                  setActionType={setActionType}
                  setOpenSectionBuilder={setOpenSectionBuilder}
                  setSectionInitValues={setSectionInitValues}
                  defaultScheme={defaultScheme}
                  actionType={actionType}
                  sectionInitValues={sectionInitValues}
                />
              );
            }}
          />
        </div>
      </Pane>
      {showPreviewPane && (
        <Pane
          id={`${paneId}-preview-pane`}
          defaultWidth="50%"
          height="100%"
          appIcon={appIcon || <div />}
          paneTitle={
            <FormattedMessage
              id="ui-plugin-inventory-templates.templateFields.formPreview.paneTitle"
              defaultMessage="Form Preview"
            />
          }
          fluidContentWidth
          centeredContent
          padContent
          lastMenu={
            <Tooltip
              id="form-preview-hide-pane-tooltip"
              text={
                <FormattedMessage
                  id="ui-plugin-inventory-templates.tooltip.hideFormPreviewPane.msg"
                  defaultMessage="Hide form preview pane"
                />
              }
            >
              {({ ref, ariaIds }) => (
                <Button
                  buttonStyle="dropdownItem"
                  id="form-preview-hide-pane-btn"
                  marginBottom0
                  onClick={() => setShowPreviewPane(!showPreviewPane)}
                  aria-labelledby={ariaIds.text}
                  ref={ref}
                >
                  <Icon icon="eye-closed" size="large" />
                </Button>
              )}
            </Tooltip>
          }
        >
          <FormSpy subscription={{ values: true }}>
            {({ values }) => (
              <FormPreView
                formValues={
                  values?.templateFields
                    ? values?.templateFields[fieldArrayName]
                    : []
                }
              />
            )}
          </FormSpy>
        </Pane>
      )}
    </Paneset>
  );
}

SectionsBuilder.propTypes = propTypes;

export default SectionsBuilder;
