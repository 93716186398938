import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Layer, Loading } from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { useOkapiKy, CalloutContext } from '@folio/stripes-core';
import WizardForm from '../../ui-kit/WizardForm';
import TemplateFormPages from './pages';
import useTemplate from '../../../hooks/useTemplate';
import css from '../../../shared/sharedStyles.css';

function TemplateBuilder(props) {
  const history = useHistory();
  const okapiKy = useOkapiKy();
  const context = useContext(CalloutContext);
  const params = useParams();
  const { templates, isLoading } = useTemplate(params.templateId);
  const { locale } = useIntl();
  const [initialValues, setInitialValues] = useState({
    templateInfo: {
      name: [
        {
          value: '',
          locale: 'ar',
        },
        {
          locale: 'en-US',
          value: '',
        },
      ],
      description: [
        {
          value: '',
          locale: 'ar',
        },
        {
          value: '',
          locale: 'en-US',
        },
      ],
      code: '',
      isDefault: false,
      isLocked: false,
      active: true,
    },
    templateFields: {
      items: [],
      holdings: [],
      instance: [],
    },
  });

  const localizedName = templates?.name?.find(
    (field) => field?.locale === locale,
  )?.value;

  useEffect(() => {
    if (params.templateId && templates) {
      setInitialValues({
        templateInfo: {
          name: templates?.name,
          description: templates?.description,
          code: templates?.code,
          isDefault: templates?.isDefault,
          isLocked: templates?.isLocked,
          active: templates?.active,
        },
        templateFields: templates?.templateFields,
      });
    }
  }, [params.templateId, templates]);

  const onAdd = (values) => {
    okapiKy
      .post('inventory-templates', {
        timeout: 30000,
        method: 'POST',
        json: {
          ...values.templateInfo,
          templateFields: values.templateFields,
        },
      })
      .json()
      .then((res) => {
        console.log(res);
        if (res.id) {
          const message = (
            <FormattedMessage
              id="ui-plugin-inventory-templates.editTemplate.callout.updated"
              defaultMessage="Template created successfully"
            />
          );
          history.push(`${props.basePath}/${res.id}/view`);
          context.sendCallout({ message });
        }
      })
      .catch(async (err) => {
        console.log(err);
        const res = err.response;
        const text = await res.text();
        console.log(text);
        context.sendCallout({ message: text });
      });
  };

  const onEdit = (values) => {
    okapiKy
      .put(`inventory-templates/${params.templateId}`, {
        timeout: 30000,
        method: 'PUT',
        headers: {
          'content-Type': 'application/json',
          accept: 'text/plain',
        },
        json: {
          ...values.templateInfo,
          templateFields: values.templateFields,
        },
      })
      .json()
      .then(() => {
        const message = (
          <FormattedMessage
            id="ui-plugin-inventory-templates.editTemplate.callout.updated"
            defaultMessage="Template updated successfully"
          />
        );
        history.push(`${props.basePath}/${params.templateId}/view`);
        context.sendCallout({ message });
      })
      .catch(async (err) => {
        const res = err.response;
        const text = await res.text();
        context.sendCallout({ message: text });
      });
  };

  const onSubmit = (values) => {
    if (params.templateId) {
      onEdit(values);
    } else {
      onAdd(values);
    }
  };

  return (
    <Layer contentLabel="Create new inventory template wizard form" isOpen>
      {isLoading && (
        <div className={css.loading}>
          <Loading size="xlarge" />
        </div>
      )}
      {!isLoading && (
        <WizardForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          pages={TemplateFormPages}
          onClose={() => history.go(-1)}
          paneTitle={
            params.templateId ? (
              <FormattedMessage
                id="ui-plugin-inventory-templates.editTemplate.pane.paneTitle"
                values={{ template: localizedName }}
                defaultMessage="Edit {template} template"
              />
            ) : (
              <FormattedMessage
                id="ui-plugin-inventory-templates.createTemplate.pane.paneTitle"
                defaultMessage="Create new inventory template"
              />
            )
          }
          formId="inventory-templates-plugin-create-template"
          {...props}
        >
          {TemplateFormPages.map((page) => (
            <WizardForm.Page key={page.id}>
              <page.contenet testProp={`${page.id}-testProp`} />
            </WizardForm.Page>
          ))}
        </WizardForm>
      )}
    </Layer>
  );
}

TemplateBuilder.propTypes = {
  basePath: PropTypes.string,
};

export default TemplateBuilder;
