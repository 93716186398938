/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Label, MultiColumnList } from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';
import InfoModal from '../../../InfoModal';
import { removeAllFields } from '../../../../../utils/utils';
import useDataByName from '../../../../../hooks/useDataByName';

function CustomFields({ fields, actionType }) {
  const customFields = useDataByName('inventoryCustomFields');
  const [selectedFields, setSelectedFields] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const intl = useIntl();

  console.log('customFields: ', customFields);

  useEffect(() => {
    if (customFields.length === selectedFields.length) {
      setSelectAll(true);
    }
  }, [selectedFields]);

  useEffect(() => {
    if (actionType === 'edit' && fields.value) {
      setSelectedFields([...fields.value]);
    }
  }, []);

  const addBulkData = () => {
    customFields.forEach((field) => {
      fields.push(field);
    });
  };

  const updateSelection = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      const selectedIndex = customFields.findIndex((s) => s.name === value);
      setSelectedFields([...selectedFields, customFields[selectedIndex]]);
      const { id, ...rest } = customFields[selectedIndex];
      fields.push(rest);
    } else {
      setSelectedFields(selectedFields?.filter((s) => s.name !== value));
      setSelectAll(false);
      const removedIndex = fields.value.findIndex(
        (fieldId) => fieldId.name === value,
      );

      fields.remove(removedIndex);
    }
  };

  const toggleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedFields([...customFields]);
      setSelectAll(true);
      // removeAllFields();
      addBulkData();
    } else {
      setSelectedFields([]);
      setSelectAll(false);
      // removeAllFields();
    }
  };

  return (
    <MultiColumnList
      interactive={false}
      contentData={customFields}
      visibleColumns={[
        'selected',
        'label',
        'isRequired',
        'canEdit',
        'isRepeatable',
        'info',
      ]}
      columnWidths={{
        selected: '5%',
        label: '50%',
        isRequired: '10%',
        canEdit: '10%',
        isRepeatable: '10%',
        info: '15%',
      }}
      columnMapping={{
        selected: (
          <Checkbox
            value="selectAll"
            onChange={toggleSelectAll}
            checked={selectAll}
          />
        ),
        label: intl.formatMessage({
          id: 'ui-plugin-inventory-templates.fieldsDefaultScheme.column.label',
          defaultMessage: 'Label',
        }),
        isRequired: intl.formatMessage({
          id: 'ui-plugin-inventory-templates.fieldsDefaultScheme.column.isRequired',
          defaultMessage: 'Required',
        }),
        canEdit: intl.formatMessage({
          id: 'ui-plugin-inventory-templates.fieldsDefaultScheme.column.canEdit',
          defaultMessage: 'Editable',
        }),
        isRepeatable: intl.formatMessage({
          id: 'ui-plugin-inventory-templates.fieldsDefaultScheme.column.isRepeatable',
          defaultMessage: 'Repeatable',
        }),
        info: intl.formatMessage({
          id: 'ui-plugin-inventory-templates.fieldsDefaultScheme.column.info',
          defaultMessage: 'Info',
        }),
      }}
      formatter={{
        selected: (field) => (
          <Checkbox
            value={field.name}
            onChange={updateSelection}
            checked={
              selectAll ||
              selectedFields.findIndex((s) => s.name === field.name) !== -1
            }
          />
        ),
        label: (field) => (
          <Label>
            {field.label.find((locale) => locale.locale === intl.locale)?.value}
          </Label>
        ),
        isRequired: (field) => field.required?.toString(),
        canEdit: (field) => field.canEdit?.toString(),
        isRepeatable: (field) => field.isRepeatable?.toString(),
        info: (field) => (
          <InfoModal
            infoTitle={
              <strong>
                {
                  field.label.find((locale) => locale.locale === intl.locale)
                    ?.value
                }
              </strong>
            }
            allowAnchorClick
            iconSize="medium"
            infoContent={
              <FormattedMessage
                id="ui-plugin-inventory-templates.templateField.resourceType.info.content"
                defaultMessage="Put your content her."
              />
            }
            buttonLabel={
              <FormattedMessage
                id="ui-plugin-inventory-templates.templateField.info.btnLabel.readMore"
                defaultMessage="Reade more"
              />
            }
            buttonHref="https://docs.folio.org/docs"
            buttonTarget="_blank"
          />
        ),
      }}
      isSelected={({ item }) => selectedFields[item.name]}
    />
  );
}

CustomFields.propTypes = {
  // customFields: PropTypes.arrayOf(PropTypes.object),
  fields: PropTypes.object,
  actionType: PropTypes.string,
};

export default CustomFields;
