import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  TextField,
  Accordion,
  FilterAccordionHeader,
  TextArea,
  Checkbox,
} from '@folio/stripes/components';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { FieldArray } from 'react-final-form-arrays';
import LocalizedTextField from '../../../ui-kit/LocalizedTextField';
import styles from '../../../../shared/sharedStyles.css';

function TemplateInfo(props) {
  const [toggle, setToggle] = useState(true);

  return (
    <Accordion
      id="plugin-inventory-templates-form-fields-templateInfo-accordion"
      label={
        <FormattedMessage
          id="ui-plugin-inventory-templates.form.templateInfo.fields.accordion.label"
          defaultMessage="Template info"
        />
      }
      onToggle={() => setToggle(!toggle)}
      open={toggle}
      separator
      header={FilterAccordionHeader}
    >
      <Row>
        <Col xs={6} className={styles.fieldRow}>
          <FieldArray
            id="localizedArrayField-templateInfo-name"
            name="templateInfo.name"
            component={LocalizedTextField}
            fieldComponent={TextField}
            validateFields={[]}
            fieldLabel={
              <FormattedMessage
                id="ui-plugin-inventory-templates.form.fields.localizedField.name.label"
                defaultMessage="Template name"
              />
            }
            isRequired
          />
          <FieldArray
            id="localizedArrayField-templateInfo-description"
            name="templateInfo.description"
            component={LocalizedTextField}
            fieldComponent={TextArea}
            validateFields={[]}
            fieldLabel={
              <FormattedMessage
                id="ui-plugin-inventory-templates.form.templateInfo.fields.description"
                defaultMessage="Description"
              />
            }
            isRequired
          />

          <Field
            component={TextField}
            fullWidth
            label={
              <FormattedMessage
                id="ui-plugin-inventory-templates.form.templateInfo.fields.code"
                defaultMessage="Code"
              />
            }
            name="templateInfo.code"
            type="text"
          />
        </Col>
        <Col xs={6} className={styles.fieldRow}>
          <Field
            component={Checkbox}
            type="checkbox"
            fullWidth
            label={
              <FormattedMessage
                id="ui-plugin-inventory-templates.form.templateInfo.fields.active"
                defaultMessage="Active"
              />
            }
            name="templateInfo.active"
          />
          <Field
            component={Checkbox}
            type="checkbox"
            fullWidth
            label={
              <FormattedMessage
                id="ui-plugin-inventory-templates.form.templateInfo.fields.isDefault"
                defaultMessage="Is default"
              />
            }
            name="templateInfo.isDefault"
          />
          <Field
            component={Checkbox}
            type="checkbox"
            fullWidth
            label={
              <FormattedMessage
                id="ui-plugin-inventory-templates.form.templateInfo.fields.isLocked"
                defaultMessage="Locked"
              />
            }
            name="templateInfo.isLocked"
          />
        </Col>
      </Row>
    </Accordion>
  );
}

TemplateInfo.propTypes = {};

export default TemplateInfo;
