import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Label, TextArea } from '@folio/stripes-components';

import { useIntl } from 'react-intl';
import { required } from '../../../../utils/utils';

const propTypes = {
  fieldValues: PropTypes.object,
  index: PropTypes.number,
  fieldName: PropTypes.string,
  parentField: PropTypes.object,
};

function TextAreaFieldComponent({ fieldValues, index, fieldName, parentField }) {
  const { locale } = useIntl();
  const { label, type, isRequired, canEdit, isRepeatable } =
    fieldValues;

  const fieldLabel = label.find((field) => field.locale === locale)?.value;

  return (
    <Field
      id={`inventory-templates-form-generator-${type}-field-${index}`}
      component={TextArea}
      fullWidth
      name={fieldName}
      label={
        isRepeatable || (parentField?.type === 'fieldArray' && index !== 0) ? (
          ''
        ) : (
          <Label fullWidth>{fieldLabel}</Label>
        )
      }
      required={isRequired}
      validate={isRequired ? required : undefined}
      type="text"
      disabled={!canEdit}
    />
  );
}

TextAreaFieldComponent.propTypes = propTypes;

export default TextAreaFieldComponent;
