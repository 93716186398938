/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/no-extraneous-dependencies */
import { useNamespace, useOkapiKy } from '@folio/stripes-core';
import { useQuery } from 'react-query';

function useTemplate(templateId) {
  const okapiKy = useOkapiKy();
  const [namespace] = useNamespace({ key: 'templates' });

  const {
    isLoading,
    isError,
    data = {},
    refetch,
    ...rest
  } = useQuery(
    [namespace, templateId],
    () =>
      okapiKy
        .get(
          templateId
            ? `inventory-templates/${templateId}`
            : 'inventory-templates',
        )
        .json(),
    { enabled: true },
  );

  return {
    isLoading,
    isError,
    templates: templateId ? data : data.templates,
    refetch,
    rest,
  };
}

export default useTemplate;
