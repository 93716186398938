/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
/* kware added new */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Badge,
  Button,
  Row,
  Col,
  Icon,
  Card,
  MultiColumnList,
  Select,
  Checkbox
} from '@folio/stripes/components';

import { getLocaleLabel, ImportLocalesIcones } from '@folio/stripes-core';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import DateFormats from '../utils/DateFormats';
import SupportedLocales from '../utils/SupportedLocales';
import TenantLocalesModal from './TenantLocalesModal';

class TenantAvailableLocales extends Component {
  static propTypes = {
    tenantDefualtLocale: PropTypes.string,
    showLocaleIcon: PropTypes.bool,
    intl: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      showChooseTenantLocalesModal: false,
      useLocalesIcons: true
    };

    this.tenantLocales = [];
  }

  renderAddTenantLocalesButton() {
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
          padding: '0.30em'
        }}
      >
        <div>{this.renderShowLocaleIcon()}</div>
        <div>
          <Button
            id="add-user-locales-btn"
            onClick={() =>
              this.setState({ showChooseTenantLocalesModal: true })
            }
          >
            <Icon icon="plus-sign">
              <FormattedMessage id="ui-tenant-settings.settings.locales.addLocales" />
            </Icon>
          </Button>
        </div>
      </div>
    );
  }

  onAddNewTenantLocales = (newTenantLocales) => {
    return newTenantLocales.map((locale) => this.tenantLocales.push(locale));
  };

  renderTenantLocaleModal(fields) {
    const modalLocales = SupportedLocales.filter(
      (all) => !fields.value.find((loc) => all.value === loc.value)
    );

    return (
      <TenantLocalesModal
        onClose={() => this.setState({ showChooseTenantLocalesModal: false })}
        open={this.state.showChooseTenantLocalesModal}
        onSave={this.onAddNewTenantLocales}
        tenantLocales={modalLocales}
      />
    );
  }

  onChangeDefaultDateFormat = (tl, e) => {
    tl.defaultDateFormat = e.target.value;
    this.tenantLocales.update(tl.rowIndex, tl);
  };

  renderDefaultDateFormatFormatter = (tl) => {
    const dataOptions = [
      ...DateFormats.map((option) => ({
        label: option.label,
        value: option.value,
        disabled: option.disabled
      }))
    ];

    return (
      <div>
        <Select
          id={`defaultDateFormat-${tl.value}`}
          marginBottom0
          name="defaultDateFormat"
          value={tl.defaultDateFormat}
          dataOptions={dataOptions}
          onChange={(e) => this.onChangeDefaultDateFormat(tl, e)}
        />
      </div>
    );
  };

  renderTenantLocaleFormatter = (tl) => {
    const flags = ImportLocalesIcones();

    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
          padding: '0.30em'
        }}
      >
        <div>
          {this.props.showLocaleIcon ? (
            <Icon icon={flags[tl.value] ? flags[tl.value] : 'flag'}>
              {getLocaleLabel(tl.value, this.props.intl)}
            </Icon>
          ) : (
            getLocaleLabel(tl.value, this.props.intl)
          )}
        </div>
        {this.props.tenantDefualtLocale &&
          tl.value === this.props.tenantDefualtLocale && (
            <div>
              <Badge>
                <FormattedMessage id="stripes-core.languageSwitcher.tenantDefaultLocale" />
              </Badge>
            </div>
          )}
      </div>
    );
  };

  onChangeUseLocalesIcons = () => {
    this.setState((state) => ({
      useLocalesIcons: !state.useLocalesIcons
    }));
  };

  renderShowLocaleIcon = () => {
    return (
      <Field
        component={Checkbox}
        type="checkbox"
        label={
          <FormattedMessage id="ui-tenant-settings.settings.userLocales.showLocaleIcon" />
        }
        name="showLocaleIcon"
        inline
      />
    );
  };

  renderTenantLocales = ({ fields }) => {
    this.tenantLocales = fields;

    return (
      <>
        <span>
          <strong>
            <FormattedMessage id="ui-tenant-settings.settings.locales.tenantAvailableLanguages" />
          </strong>
          {'  '}
          <Badge>{fields.value.length}</Badge>
        </span>
        <MultiColumnList
          interactive={false}
          contentData={fields.value}
          visibleColumns={['name', 'defaultDateFormat', 'Actions']}
          columnWidths={{
            name: '60%',
            defaultDateFormat: '25%',
            Actions: '15%'
          }}
          columnMapping={{
            name: (
              <FormattedMessage id="ui-tenant-settings.settings.locales.column.label" />
            ),
            defaultDateFormat: (
              <FormattedMessage id="ui-tenant-settings.settings.locales.column.defaultDateFormat" />
            ),
            Actions: (
              <FormattedMessage id="ui-tenant-settings.settings.locales.column.Actions" />
            )
          }}
          formatter={{
            name: (tl) => this.renderTenantLocaleFormatter(tl),
            defaultDateFormat: (tl) =>
              this.renderDefaultDateFormatFormatter(tl),
            Actions: (tl) => (
              <Button
                buttonStyle="fieldControl"
                align="end"
                type="button"
                id={`clickable-remove-tenantLocales-${tl.value}`}
                disabled={tl.value === this.props.tenantDefualtLocale}
                onClick={() => fields.remove(tl.rowIndex)}
              >
                <Icon icon="trash" />
              </Button>
            )
          }}
        />
        <br />
        {this.renderAddTenantLocalesButton()}
        {this.renderTenantLocaleModal(fields)}
        <hr />
      </>
    );
  };

  render() {
    return (
      <FieldArray name="tenantLocales" component={this.renderTenantLocales} />
    );
  }
}

export default injectIntl(TenantAvailableLocales);
