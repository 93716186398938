import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { Layer, Pane } from '@folio/stripes-components';
import { useIntl } from 'react-intl';
import useTemplate from '../../../hooks/useTemplate';

function EditTemplate(props) {
  const history = useHistory();
  const params = useParams();
  const { template } = useTemplate(params.templateId);
  const { locale } = useIntl();

  const localizedName = template?.name?.find(
    (field) => field?.locale === locale,
  )?.value;


  return (
    <Layer contentLabel="Edit inventory template" isOpen>
      <Pane
        id="order-settings-order-template-view"
        defaultWidth="fill"
        paneTitle="Edit Template"
        dismissible
        onClose={() => history.go(-1)}
      >
        <div />
      </Pane>
    </Layer>
  );
}

EditTemplate.propTypes = {};

export default EditTemplate;
