import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Icon, Label, Row } from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';
import styles from './styles.css';
import AddFieldsTabs from './AddFieldsTabs';
import DefaultScheme from './DefaultScheme';
import CustomFields from './CustomFields';
import instanceCustomFields from '../../../../constants/scheme/instanceCustomFields';

function FieldsBuilder({ fields, defaultScheme, actionType }) {
  const [currentTab, setCurrentTab] = useState('');
  const [openFieldsBuilder, setOpenFieldsBuilder] = useState(false);

  useEffect(() => {
    if (actionType === 'edit') {
      setOpenFieldsBuilder(true);
      setCurrentTab('defaultScheme');
    }
  }, [actionType]);

  return (
    <>
      <Row>
        <Col xs={12}>
          <Label required>
            <FormattedMessage
              id="ui-plugin-inventory-templates.templateSection.fields.sectionFields.label"
              defaultMessage="Section Fields"
            />
          </Label>
        </Col>
      </Row>
      {openFieldsBuilder && (
        <div className={styles.sectionFieldsBuilder}>
          <AddFieldsTabs
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
          <Row className={styles.sectionRow}>
            <Col xs={12} className={styles.sectionFieldsTabs}>
              {currentTab === 'defaultScheme' && (
                <DefaultScheme
                  defaultScheme={defaultScheme}
                  fields={fields}
                  actionType={actionType}
                />
              )}
              {currentTab === 'customFields' && (
                <CustomFields
                  customFields={instanceCustomFields}
                  fields={fields}
                  actionType={actionType}
                />
              )}
            </Col>
          </Row>
        </div>
      )}
      {!openFieldsBuilder && (
        <Row className={styles.sectionRow}>
          <Col xs={12} className={styles.sectionFieldsContainer}>
            <div className={styles.buttonsContainer}>
              <div
                className={styles.button}
                id="add-section-fields-from-default-scheme-btn"
                role="button"
                onClick={() => {
                  setOpenFieldsBuilder(true);
                  setCurrentTab('defaultScheme');
                }}
                onKeyDown={() => {
                  setOpenFieldsBuilder(true);
                  setCurrentTab('defaultScheme');
                }}
                tabIndex="0"
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Icon icon="plus-sign" />
                  <FormattedMessage
                    id="ui-plugin-inventory-templates.buttons.addFromDefaultScheme"
                    defaultMessage="Add from default scheme"
                  />
                </div>
              </div>
              <div
                className={styles.button}
                id="add-section-fields-from-custom-fields-btn"
                role="button"
                onClick={() => {
                  setOpenFieldsBuilder(true);
                  setCurrentTab('customFields');
                }}
                onKeyDown={() => {
                  setOpenFieldsBuilder(true);
                  setCurrentTab('customFields');
                }}
                tabIndex="0"
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Icon icon="plus-sign" />
                  <FormattedMessage
                    id="ui-plugin-inventory-templates.buttons.addCustomField"
                    defaultMessage="Add custom field"
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
}

FieldsBuilder.propTypes = {
  fields: PropTypes.object,
  defaultScheme: PropTypes.arrayOf(PropTypes.object),
  actionType: PropTypes.string,
};

export default FieldsBuilder;
