import React from 'react';
import PropTypes from 'prop-types';
import Copycat from '../components/Copycat/Copycat';
import CopycatMarcEdit from '../components/Copycat/components/CopycatRecord/CopycatEdit/CopycatMarcEdit/CopycatMarcEdit';

function CopycatMarcEditRoute(props) {
  return <CopycatMarcEdit {...props} />;
}

CopycatMarcEditRoute.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.object,
};

export default CopycatMarcEditRoute;
