import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field } from 'react-final-form';
import {
  Col,
  languageOptions,
  MultiSelection,
  Row,
  Select,
} from '@folio/stripes-components';
import useDataByName from '../../../../../../../hooks/useDataByName';

const propTypes = {
  fieldName: PropTypes.string,
  fieldData: PropTypes.object,
};

function SelectFieldType({ fieldName, fieldData }) {
  const data = useDataByName(fieldData.dataOptionsSource);
  const intl = useIntl();
  const langOptions = languageOptions(intl);

  const handleDataOptions = () => {
    const options = [];
    (data || []).forEach((opt) => {
      options.push({ value: opt.id, label: opt.name });
    });

    if (fieldData.schema === 'default') {
      if (fieldData.name === 'languages') {
        return langOptions;
      } else {
        return options;
      }
    } else {
      return fieldData.fieldDataOption;
    }
  };

  const formatter = ({ option }) => {
    if (fieldData.schema === 'default') {
      return (
        <div>
          {intl.formatMessage({
            id: `ui-inventory.${
              fieldData.tableName || fieldData.dataOptionsSource
            }.name.${option.label}`,
            defaultMessage: option.label,
          })}
        </div>
      );
    }

    return (
      <div>
        {option.label.find((locale) => locale.locale === intl.locale)?.value}
      </div>
    );
  };

  const defaultValueFormatter = () => {
    const defaultData = fieldData?.dataOptionsLimits?.length
      ? fieldData?.dataOptionsLimits
      : handleDataOptions();

    if (fieldData.schema === 'default') {
      return defaultData?.map((opt) => ({
        label: intl.formatMessage({
          id: `ui-inventory.${
            fieldData.tableName || fieldData.dataOptionsSource
          }.name.${opt.label}`,
          defaultMessage: opt.label,
        }),
        value: opt.value,
      }));
    }

    return defaultData?.map((opt) => ({
      label: opt.label.find((locale) => locale.locale === intl.locale)?.value,
      value: opt.value,
    }));
  };

  const itemToString = (item) => {
    // console.log('item: ', item);
    // return crypto.randomUUID();
    return item?.value;
  };

  return (
    <Row>
      <Col xs={6}>
        <Field
          id={`${fieldName}.dataOptions`}
          name={`${fieldName}.dataOptions`}
          type="text"
          component={MultiSelection}
          itemToString={(item) => (item ? item.value : '')}
          dataOptions={handleDataOptions()}
          formatter={formatter}
          fullWidth
          label={
            <FormattedMessage
              id="ui-plugin-inventory-templates.templateField.dataOptions.label"
              defaultMessage="Data options"
            />
          }
        />
      </Col>
      <Col xs={6}>
        <Field
          name={`${fieldName}.defaultValue`}
          type="text"
          component={Select}
          dataOptions={[
            {
              label: intl.formatMessage({
                id: 'ui-plugin-inventory-templates.templateField.defaultValue.select.chooseMsg',
                defaultMessage: 'Choose default value',
              }),
              value: '',
            },
            ...defaultValueFormatter(),
          ]}
          fullWidth
          label={
            <FormattedMessage
              id="ui-plugin-inventory-templates.templateField.defaultValue.label"
              defaultMessage="Default value"
            />
          }
        />
      </Col>
    </Row>
  );
}

SelectFieldType.propTypes = propTypes;

export default SelectFieldType;
