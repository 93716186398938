import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  Layout,
  Loading,
  Pane,
  Tooltip,
} from '@folio/stripes/components';
import { FormattedMessage } from 'react-intl';
import { isEqual } from 'lodash';
import EmptyData from './components/emptyData/EmptyData';
import ResultsPreview from './components/ResultsPreview';
import ImportedListMenu from './components/ImportedList/ImportedListMenu';
import VisibleColumnsMenu from './components/VisibleColumnsMenu';
import ResultsActionsMenu from './components/ResultsActionsMenu';
import ErrorMessage from './components/errorMessage/ErrorMessage';
import { sortedVisibleColumns } from '../../utils';
import ConfirmDeleteCartListModal from './components/ConfirmDeleteCartListModal';
import ResultsPaneFooter from './components/ResultsPaneFooter';

const getAllServerData = (serversData) => {
  const data = [];
  serversData.forEach((server) => {
    data.push(...server.serverData);
  });
  return data;
};

const constructSelectedSources = (listOfSources, data) => {
  const sources = [];
  const allResults = {
    serverName: 'All',
    totalRecords: data.totalRecords,
    serverData: getAllServerData(data.serversData),
    searchTime: 0,
  };

  if (listOfSources.length > 1) {
    sources.push(allResults);
  }

  listOfSources.forEach((selectedSource) => {
    const server = data?.serversData.find(
      (serv) => serv.serverName === selectedSource
    );
    if (server) {
      sources.push({
        serverName: server.serverName,
        totalRecords: server?.totalRecords,
        serverData: server?.serverData,
        searchTime: server.searchTime,
      });
    } else {
      sources.push({
        serverName: selectedSource,
        totalRecords: 0,
        serverData: [],
        searchTime: 0,
      });
    }
  });
  return sources;
};

function CopycatResultsPreview({
  showQueryPane,
  setShowQueryPane,
  selectedSources,
  setCurrentPage,
  currentPage,
  searchResponse,
  pageSize,
  setShowRecordPane,
  selectedItem,
  setSelectedItem,
  selectedItems,
  setSelectedItems,
  setOpenViewSourceModal,
  importedList,
}) {
  const { resultSets, error, isLoading } = searchResponse;
  const [cartList, setCartList] = useState([]);
  const [showCartList, setShowCartList] = useState(false);
  const [openConfirmDeleteCartModal, setOpenConfirmDeleteCartModal] =
    useState(false);
  const [contentData, setContentData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [selection, setSelection] = useState({});
  const [currentTab, setcurrentTab] = useState('All');
  const [constructedSources, setConstructedSources] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState(sortedVisibleColumns);

  useEffect(() => {
    if (resultSets?.serversData?.length) {
      setConstructedSources(
        constructSelectedSources(selectedSources, resultSets)
      );
    } else {
      setConstructedSources([]);
    }
  }, [resultSets, selectedSources]);

  useEffect(() => {
    if (!isLoading && constructedSources.length !== 0) {
      let server;
      if (constructedSources.length > 1) {
        server = constructedSources.find(
          (selecteSrc) => selecteSrc.serverName === currentTab
        );
      } else {
        server = constructedSources[0];
      }
      if (server?.serverData?.length) {
        setSearchResults(server?.serverData);
      }
    } else {
      setSearchResults([]);
    }
  }, [currentTab, constructedSources, resultSets, isLoading]);

  useEffect(() => {
    if (!showCartList) {
      setContentData(searchResults);
    } else if (showCartList) {
      setContentData(cartList);
    } else {
      setContentData([]);
    }
  }, [showCartList, cartList, searchResults]);

  useEffect(() => {
    if (Object.values(selection).length) {
      const selected = contentData.filter((item) => selection[item.id]);
      setSelectedItems(selected);
    }
  }, [selection]);

  const handleAddToCartListBtn = () => {
    if (!showCartList) {
      /** Add to cart */
      setCartList([...new Set([...selectedItems, ...cartList])]);
    } else if (isEqual(cartList, selectedItems)) {
      /** Delete the cart, If all items selected */
      setOpenConfirmDeleteCartModal(true);
    } else {
      /** Remove from cart */
      setCartList(
        cartList.filter((a) => !selectedItems.map((b) => b.id).includes(a.id))
      );
    }
  };

  const renderFirstMenu = () => {
    return (
      <>
        {!showQueryPane ? (
          <Tooltip
            id="copycat-show-query-builder-pane-tooltip"
            copycat-query-builder-pane-footer-search-btn
            text={
              <FormattedMessage
                id="ui-inventory.copycat.queryBuilder.pane.tooltip.showPane.msg"
                defaultMessage="Show query builder panel"
              />
            }
          >
            {({ ref, ariaIds }) => (
              <Button
                buttonStyle="slim"
                id="copycat-query-builder-pane-showPane-btn"
                marginBottom0
                onClick={() => setShowQueryPane(!showQueryPane)}
                aria-labelledby={ariaIds.text}
                ref={ref}
              >
                <Icon icon="chevron-double-right" />
              </Button>
            )}
          </Tooltip>
        ) : (
          <div />
        )}
        <Tooltip
          id="copycat-query-builder-pane-show-columns-tooltip"
          copycat-query-builder-pane-footer-search-btn
          text={
            <FormattedMessage
              id="ui-inventory.copycat.queryBuilder.pane.tooltip.showColumns.msg"
              defaultMessage="Show query builder panel"
            />
          }
        >
          {({ ref, ariaIds }) => (
            <VisibleColumnsMenu
              id="copycat-query-builder-pane-show-columns-menu-btn"
              visibleColumns={visibleColumns}
              setVisibleColumns={setVisibleColumns}
              aria-labelledby={ariaIds.text}
              ref={ref}
            />
          )}
        </Tooltip>
      </>
    );
  };

  const onConfirmDeleteCartList = () => {
    setCartList([]);
    setShowCartList(!showCartList);
    setOpenConfirmDeleteCartModal(false);
  };

  const renderLastMenu = () => {
    return (
      <>
        {importedList.length !== 0 && (
          <div style={{ padding: '3px' }}>
            <ImportedListMenu importedList={importedList} />
          </div>
        )}
        {cartList.length !== 0 && (
          <div style={{ padding: '3px' }}>
            {showCartList && cartList?.length ? (
              <Tooltip
                id="copycat-results-preview-pane-deleteCartList-tooltip"
                text={
                  <FormattedMessage
                    id="ui-inventory.copycat.resultsPreview.pane.lastMenu.tooltip.deleteCartList.msg"
                    defaultMessage="Delete cart list"
                  />
                }
              >
                {({ ref, ariaIds }) => (
                  <Button
                    onClick={() => {
                      setOpenConfirmDeleteCartModal(true);
                    }}
                    onMouseDown={(e) => e.preventDefault()}
                    buttonStyle="slim"
                    marginBottom0
                    aria-labelledby={ariaIds.text}
                    ref={ref}
                  >
                    <Icon icon="trash" />
                  </Button>
                )}
              </Tooltip>
            ) : (
              <div />
            )}
            <Tooltip
              id="copycat-results-preview-pane-showCartList-tooltip"
              text={
                !showCartList && cartList?.length ? (
                  <FormattedMessage
                    id="ui-inventory.copycat.resultsPreview.pane.lastMenu.tooltip.showCartList.msg"
                    defaultMessage="Show cart list"
                  />
                ) : (
                  <FormattedMessage
                    id="ui-inventory.copycat.resultsPreview.pane.lastMenu.tooltip.returnToresults.msg"
                    defaultMessage="Back to results"
                  />
                )
              }
            >
              {({ ref, ariaIds }) => (
                <Button
                  onClick={() => setShowCartList(!showCartList)}
                  onMouseDown={(e) => e.preventDefault()}
                  buttonStyle="slim"
                  marginBottom0
                  aria-labelledby={ariaIds.text}
                  ref={ref}
                >
                  {!showCartList && cartList?.length ? (
                    <Icon icon="cart" size="large">
                      {cartList?.length}
                    </Icon>
                  ) : (
                    <Icon icon="list" />
                  )}
                </Button>
              )}
            </Tooltip>
          </div>
        )}
        {contentData.length !== 0 && (
          <ResultsActionsMenu
            selectedItems={selectedItems}
            handleAddToCartListBtn={handleAddToCartListBtn}
            setOpenViewSourceModal={setOpenViewSourceModal}
            showCartList={showCartList}
          />
        )}
      </>
    );
  };

  const renderPaneFooter = () => {
    return (
      <ResultsPaneFooter
        currentPage={currentPage}
        isLoading={isLoading}
        resultSets={resultSets}
        setCurrentPage={setCurrentPage}
        showCartList={showCartList}
      />
    );
  };

  const renderResultSets = () => {
    if (error) {
      return <ErrorMessage error={error.toString()} />;
    }

    return (
      <>
        {isLoading ? (
          <Layout
            className="centered full"
            style={{ maxWidth: '15rem', height: '8rem' }}
          >
            &nbsp;
            <Loading size="xlarge" />
          </Layout>
        ) : (
          <ResultsPreview
            selectedSources={constructedSources}
            cartList={cartList}
            visibleColumns={visibleColumns}
            showCartList={showCartList}
            contentData={contentData}
            currentTab={currentTab}
            setcurrentTab={setcurrentTab}
            selection={selection}
            setSelection={setSelection}
            currentPage={currentPage}
            pageSize={pageSize}
            setShowRecordPane={setShowRecordPane}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
          />
        )}
      </>
    );
  };

  const renderPaneContent = () => {
    if (!contentData.length && !isLoading) {
      return <EmptyData />;
    }
    return <>{renderResultSets()}</>;
  };

  const getPaneTitle = () => {
    if (selectedSources.length === 1 && !isLoading && resultSets) {
      return (
        <FormattedMessage
          id="ui-inventory.copycat.resultsPreview.pane.singleServer.paneTitle"
          values={{
            server: (
              <strong>
                <FormattedMessage
                  id={`ui-inventory.targetProfiles.name.${selectedSources[0]}`}
                  defaultMessage={selectedSources[0]}
                />
              </strong>
            ),
          }}
          defaultMessage="Results Preview of {server} server"
        />
      );
    }

    if (showCartList && cartList?.length) {
      return (
        <FormattedMessage
          id="ui-inventory.copycat.resultsPreview.pane.cartList.paneTitle"
          defaultMessage="Cart List Preview"
        />
      );
    }

    return (
      <FormattedMessage
        id="ui-inventory.copycat.resultsPreview.pane.allResults.paneTitle"
        defaultMessage="Results Preview"
      />
    );
  };

  const getPaneSub = () => {
    if (showCartList) {
      return cartList.length;
    }

    return !isLoading && resultSets?.totalRecords
      ? resultSets.totalRecords
      : 0;
  };

  return (
    <Pane
      appIcon={<Icon icon="preview" size="large" />}
      fluidContentWidth
      noOverflow
      firstMenu={renderFirstMenu()}
      lastMenu={renderLastMenu()}
      footer={renderPaneFooter()}
      id="copycat-results-preview-pane"
      centeredContent
      paneTitle={getPaneTitle()}
      paneSub={
        <FormattedMessage
          id="stripes-smart-components.searchResultsCountHeader"
          values={{
            count: getPaneSub(),
          }}
        />
      }
    >
      {renderPaneContent()}
      <ConfirmDeleteCartListModal
        open={openConfirmDeleteCartModal}
        onClose={setOpenConfirmDeleteCartModal}
        onConfirmDeleteCartList={onConfirmDeleteCartList}
      />
    </Pane>
  );
}

CopycatResultsPreview.propTypes = {
  showQueryPane: PropTypes.bool.isRequired,
  setShowQueryPane: PropTypes.func.isRequired,
  setShowRecordPane: PropTypes.func,
  selectedSources: PropTypes.arrayOf(PropTypes.object),
  searchResponse: PropTypes.object,
  importedList: PropTypes.arrayOf(PropTypes.object),
  setCurrentPage: PropTypes.func,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  selectedItem: PropTypes.object,
  setSelectedItem: PropTypes.func,
  selectedItems: PropTypes.arrayOf(PropTypes.object),
  setSelectedItems: PropTypes.func,
  setOpenViewSourceModal: PropTypes.func,
};

export default CopycatResultsPreview;
