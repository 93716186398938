/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import stripesFinalForm from '@folio/stripes-final-form';
import {
  Button,
  Icon,
  Layout,
  Loading,
  Modal,
  ModalFooter,
} from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import ImportSingleRecordForm from './ImportSingleRecordForm';
import ErrorMessage from '../../components/CopycatResultsPreview/components/errorMessage/ErrorMessage';
import withLocation from '../../../../withLocation';
import CheckForDuplicates from '../checkForDuplicates/CheckForDuplicates';

function RenderImportModal({
  handleSubmit,
  jobProfiles,
  onClose,
  open,
  isLoading,
  response,
  goTo,
  itemToImport,
  importedList,
  setImportedList,
  error,
  checkForDuplicates,
  setCheckForDuplicates,
  isDuplicated,
  setIsDuplicated,
  isDuplicatedError,
  setIsDuplicatedError,
}) {
  const viewInInventory = () => {
    goTo(
      response.internalIdentifier
        ? `/inventory/view/${response.internalIdentifier}`
        : '/inventory/view'
    );
  };

  const renderSuccessResponse = () => {
    return (
      <>
        {response.internalIdentifier ? (
          <FormattedMessage
            id="ui-inventory.copycat.importSingleRecord.modal.successImport.bodyMsg"
            values={{
              title: (
                <Link
                  to={`/inventory/view/${response.internalIdentifier}`}
                  target="_blank"
                >
                  <strong>{itemToImport?.title}</strong>
                </Link>
              ),
              server: (
                <strong>
                  <FormattedMessage
                    id={`ui-inventory.targetProfiles.name.${itemToImport?.serverName}`}
                    defaultMessage={itemToImport?.serverName}
                  />
                </strong>
              ),
              br: <br />,
            }}
          />
        ) : (
          <FormattedMessage
            id="ui-inventory.copycat.importSingleRecord.modal.successImport.no-id.bodyMsg"
            values={{
              title: <strong>{itemToImport?.title}</strong>,
              br: <br />,
            }}
          />
        )}

        {response.internalIdentifier && (
          <>
            <br />
            <Button
              id="copycat-import-single-record-modal-openInNewTab-btn"
              buttonStyle="default"
              to={`/inventory/view/${response.internalIdentifier}`}
              target="_blank"
              onMouseDown={(e) => e.preventDefault()}
            >
              <FormattedMessage
                id="ui-inventory.copycat.buttons.openInNewTab"
                defaultMessage="Open in new tab"
              />
            </Button>
            <br />
            <Button
              id="copycat-import-single-record-modal-editInInventory-btn"
              buttonStyle="default"
              to={`/inventory/edit/${response.internalIdentifier}/instance`}
              target="_blank"
              onMouseDown={(e) => e.preventDefault()}
            >
              <FormattedMessage
                id="ui-inventory.copycat.buttons.editInInventory"
                defaultMessage="Edit in inventory"
              />
            </Button>
            <br />
            <Button
              id="copycat-import-single-record-modal-editInMarcEditor-btn"
              buttonStyle="default"
              to={`/inventory/quick-marc/edit-bib/${response.internalIdentifier}`}
              target="_blank"
              onMouseDown={(e) => e.preventDefault()}
            >
              <FormattedMessage
                id="ui-inventory.copycat.buttons.editInMarcEditor"
                defaultMessage="Edit in marc editor"
              />
            </Button>
          </>
        )}
      </>
    );
  };

  const renderLoadingRecordToInventory = () => {
    return (
      <>
        <FormattedMessage
          id="ui-inventory.copycat.importSingleRecord.modal.loadingRecord.bodyMsg"
          values={{
            title: <strong>{itemToImport?.title}</strong>,
            server: (
              <strong>
                <FormattedMessage
                  id={`ui-inventory.targetProfiles.name.${itemToImport?.serverName}`}
                  defaultMessage={itemToImport?.serverName}
                />
              </strong>
            ),
            br: <br />,
          }}
          defaultMessage=" جاري استيراد العنوان {title}.{br} من خادم: {server} {br}."
        />
        <Layout
          className="centered"
          style={{ maxWidth: '20rem', height: '10rem' }}
        >
          <br />
          &nbsp; &nbsp; &nbsp;
          <Loading size="xlarge" />
        </Layout>
      </>
    );
  };

  const renderCheckForDuplicates = () => {
    const options = {
      title: itemToImport.title.trim(),
      author: itemToImport.author,
      edition: itemToImport.edition,
      publicationYear: itemToImport.publicationYear,
      ISBN: itemToImport.ISBN,
      publisher: itemToImport.publisher,
    };
    return (
      <CheckForDuplicates
        checkForDuplicatesOptions={options}
        handleSubmit={handleSubmit}
        setIsDuplicated={setIsDuplicated}
        setCheckForDuplicates={setCheckForDuplicates}
        serverName={itemToImport.serverName}
        setIsDuplicatedError={setIsDuplicatedError}
      />
    );
  };

  const renderModalFooter = () => {
    if (!isLoading && response && !checkForDuplicates) {
      return (
        <ModalFooter>
          <Button
            id="copycat-import-single-record-modal-footer-continue-btn"
            buttonStyle="primary"
            onClick={() => {
              setImportedList([
                Object.assign(itemToImport, {
                  internalIdentifier: response.internalIdentifier,
                }),
                ...importedList,
              ]);
              onClose();
            }}
          >
            <FormattedMessage
              id="ui-inventory.copycat.buttons.contnue"
              defaultMessage="Continue"
            />
          </Button>
          <Button
            id="copycat-import-single-record-modal-footer-closeAndViewInInventory-btn"
            buttonStyle="primary"
            onClick={() => viewInInventory()}
          >
            <FormattedMessage
              id="ui-inventory.copycat.buttons.closeAndViewInInventory"
              defaultMessage="Close and view in inventory"
            />
          </Button>
        </ModalFooter>
      );
    } else if ((isDuplicated || isDuplicatedError) && !isLoading && !response) {
      return (
        <ModalFooter>
          {!isDuplicatedError && (
            <Button
              id="copycat-import-single-record-modal-footer-importAnyway-btn"
              buttonStyle="primary"
              onClick={() => {
                handleSubmit();
                setCheckForDuplicates(false);
                setIsDuplicated(false);
              }}
            >
              <FormattedMessage
                id="ui-inventory.copycat.buttons.importAnyway"
                defaultMessage="Import anyway"
              />
            </Button>
          )}
          <Button
            id="copycat-import-single-record-modal-footer-returnToResults-btn"
            buttonStyle="primary"
            onClick={onClose}
          >
            <FormattedMessage
              id="ui-inventory.copycat.buttons.returnToResults"
              defaultMessage="Return to search results"
            />
          </Button>
        </ModalFooter>
      );
    } else if (!checkForDuplicates && !isLoading && !response) {
      return (
        <ModalFooter>
          <Button
            id="copycat-import-single-record-modal-footer-import-btn"
            buttonStyle="primary"
            onClick={() => setCheckForDuplicates(true)}
          >
            <FormattedMessage
              id="ui-inventory.copycat.buttons.import"
              defaultMessage="Import"
            />
          </Button>
          <Button
            id="copycat-import-single-record-modal-footer-cancel-btn"
            buttonStyle="slim"
            onClick={onClose}
          >
            <Icon icon="times-circle-solid" size="large">
              <FormattedMessage
                id="ui-inventory.copycat.buttons.cancel"
                defaultMessage="Cancel"
              />
            </Icon>
          </Button>
        </ModalFooter>
      );
    }

    return null;
  };

  return (
    <Modal
      id="copycat-import-single-record-modal"
      footer={renderModalFooter()}
      open={open}
      // dismissible
      onClose={onClose}
      label={
        <Icon icon="info" size="large" color="red">
          <FormattedMessage
            id="ui-inventory.copycat.importSingleRecord.modal.label"
            defaultMessage="Import single record"
          />
        </Icon>
      }
    >
      {checkForDuplicates &&
        !isLoading &&
        !response &&
        renderCheckForDuplicates()}
      {!checkForDuplicates && !isLoading && !response && (
        <ImportSingleRecordForm
          handleSubmit={handleSubmit}
          jobProfiles={jobProfiles}
          recordTitle={itemToImport?.title}
          recordServer={itemToImport?.serverName}
        />
      )}
      {!checkForDuplicates && isLoading && renderLoadingRecordToInventory()}
      {!checkForDuplicates && !isLoading && response && renderSuccessResponse()}
      {!checkForDuplicates && !isLoading && error && (
        <ErrorMessage error={error.toString()} />
      )}
    </Modal>
  );
}

RenderImportModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  jobProfiles: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  isLoading: PropTypes.bool,
  response: PropTypes.object,
  error: PropTypes.object,
  goTo: PropTypes.func.isRequired,
  itemToImport: PropTypes.object,
  importedList: PropTypes.arrayOf(PropTypes.object),
  setImportedList: PropTypes.func,
  checkForDuplicates: PropTypes.bool,
  setCheckForDuplicates: PropTypes.func,
  isDuplicated: PropTypes.bool,
  setIsDuplicated: PropTypes.func,
  isDuplicatedError: PropTypes.bool,
  setIsDuplicatedError: PropTypes.func,
};

export default stripesFinalForm({
  mutators: {},
  validateOnBlur: true,
  navigationCheck: true,
  subscription: {
    values: true,
  },
})(withLocation(RenderImportModal));
