/* eslint-disable react/prop-types */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import css from './styles.css';

function EmptyData({ message }) {
  if (message) {
    return (
      <div className={css.emptyContainer}>
        <div style={{ paddingBottom: '10px' }}>{message}</div>
      </div>
    );
  }
  return (
    <div className={css.emptyContainer}>
      <div style={{ paddingBottom: '10px' }}>
        <FormattedMessage
          id="ui-inventory.copycat.components.common.emptyData.message"
          defaultMessage="No data to show!"
        />
      </div>
    </div>
  );
}
export default EmptyData;
