/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  Label,
  Modal,
  ModalFooter,
} from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { OnChange } from 'react-final-form-listeners';
import FieldAccordion from '../FieldAccordion';
import FieldControls from '../FieldControls';
import AvailableLists from './AvailableLists';
import RelatedFields from './RelatedFields';
import DrivedFieldLabel from './DrivedFieldLabel';

const propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  fieldData: PropTypes.object,
  fieldName: PropTypes.string,
  parentFields: PropTypes.object,
  parentFieldIndex: PropTypes.number,
  infoConfig: PropTypes.object,
};

function DriveNewFieldsModal({
  open,
  onClose,
  fieldData,
  fieldName,
  parentFields,
  parentFieldIndex,
  infoConfig,
}) {
  const { drivedFields, fieldArray, label } = fieldData;

  const { locale, formatMessage } = useIntl();
  const fieldLabel = label.find((field) => field.locale === locale)?.value;

  let formRenderProps;
  let drivedFieldsArray;

  const onHandleSubmit = (values) => {
    const primaryList = values.availableLists.find(
      (list) => list.isPrimary === true,
    );
    const fieldSource = fieldArray.find(
      (data) => data.name === primaryList.name,
    );

    const drivedFieldsData = {
      name: primaryList?.name,
      values: values.drivedFieldsValues,
      relatedTextField: values.relatedTextField,
      dataOptionsSource: fieldSource?.dataOptionsSource,
      type: 'drivedField',
    };

    parentFields.update(parentFieldIndex, {
      ...parentFields.value[parentFieldIndex],
      drivedFields: drivedFieldsData.values ? drivedFieldsData : undefined,
    });
    onClose();
  };

  const renderFooter = (renderProps) => {
    return (
      <ModalFooter>
        <Button
          id="inventory-template-plugin-drive-new-fields-modal-footer-save-btn"
          buttonStyle="primary"
          type="submit"
          onClick={(event) => {
            renderProps.handleSubmit(event);
          }}
          disabled={renderProps.pristine || renderProps.submitting}
        >
          <FormattedMessage
            id="ui-plugin-inventory-templates.buttons.save"
            defaultMessage="Save"
          />
        </Button>
        <Button
          id="inventory-template-plugin-add-field-modal-footer-close-btn"
          buttonStyle="slim"
          onClick={onClose}
        >
          <Icon icon="times-circle-solid" size="large">
            <FormattedMessage id="stripes-core.button.close" />
          </Icon>
        </Button>
      </ModalFooter>
    );
  };

  const getAvailableLists = () => {
    return fieldArray?.filter((field) => field.type === 'select');
  };

  const getRelatedFields = () => {
    return fieldArray?.filter((field) => field.type === 'text');
  };

  const initRelatedTextField = () => {
    const relatedFields = getRelatedFields();

    return (
      drivedFields?.relatedTextField ||
      (relatedFields?.length ? relatedFields[0]?.name : '')
    );
  };

  const initAvailableLists = () => {
    const availableLists = [];
    const selectableFields = getAvailableLists();
    (selectableFields || []).forEach((field) => {
      if (drivedFields?.name === field.name) {
        availableLists.push({
          name: drivedFields.name,
          values: drivedFields.values,
          isPrimary: true,
        });
      } else {
        availableLists.push({
          name: field.name,
          values: [],
          isPrimary: selectableFields.length === 1,
        });
      }
    });
    return availableLists;
  };

  const renderAvailableLists = ({ fields }) => {
    const selectableFields = getAvailableLists();

    return (
      <>
        <Label>
          <FormattedMessage
            id="ui-plugin-inventory-templates.driveNewFieldsModal.availableLists.label"
            defaultMessage="Available lists"
          />
        </Label>
        {fields?.map((field, index) => {
          return (
            <AvailableLists
              key={`${field.name}-${index}`}
              fieldData={selectableFields[index]}
              fieldName={field}
              index={index}
              fields={fields}
            />
          );
        })}
      </>
    );
  };

  const renderDrivedFields = ({ fields }) => {
    drivedFieldsArray = fields;

    const primaryList = formRenderProps.form
      .getState()
      .values.availableLists.find((list) => list.isPrimary === true);
    const fieldSource = fieldArray.find(
      (data) => data.name === primaryList?.name,
    );

    return fields.map((name, index) => {
      return (
        <FieldAccordion
          key={`${name}-template-field-accordion-${index}`}
          id={`${name}-template-field-accordion-${index}`}
          label={formatMessage({
            id: `ui-plugin-inventory-templates.${fieldSource.dataOptionsSource}.name.${fields.value[index].label}`,
            defaultMessage: fields.value[index].label,
          })}
          fieldName={name}
          fieldData={fields.value[index]}
          index={index}
        >
          <FieldControls
            fieldData={fields.value[index]}
            fieldName={name}
            fieldType="drivedField"
            hasBorder
          />
          <DrivedFieldLabel
            fieldName={name}
            fieldData={fields.value[index]}
            fieldSource={fieldSource}
          />
        </FieldAccordion>
      );
    });
  };

  const onChangeAvailableLists = (currentValue) => {
    currentValue.forEach((field) => {
      if (field.isPrimary) {
        const deletedFields = drivedFieldsArray?.value?.filter((tenantloc) => {
          return !field?.values?.some((availableLoc) => {
            return tenantloc.value === availableLoc.value;
          });
        });

        const newFields = field?.values?.filter((availableLoc) => {
          return !drivedFieldsArray?.value?.some((tenantloc) => {
            return availableLoc.value === tenantloc.value;
          });
        });

        if (newFields?.length) {
          newFields.map((newField) =>
            drivedFieldsArray.push({
              value: newField.value,
              label: newField.label,
              isRepeatable: false,
              isRequired: false,
              canEdit: true,
            }),
          );
        }

        if (deletedFields?.length) {
          deletedFields.forEach((deletedField) => {
            const deletedFieldIndex = drivedFieldsArray.value.findIndex(
              (delField) => delField.value === deletedField.value,
            );
            drivedFieldsArray.remove(deletedFieldIndex);
          });
        }
      }
    });
  };

  return (
    <Form
      onSubmit={onHandleSubmit}
      initialValues={{
        availableLists: initAvailableLists(),
        relatedTextField: initRelatedTextField(),
        drivedFieldsValues: fieldData?.drivedFields?.values,
      }}
      mutators={{
        ...arrayMutators,
      }}
      render={(renderProps) => {
        formRenderProps = renderProps;

        return (
          <form id="drive-new-fields-form" onSubmit={renderProps.handleSubmit}>
            <Modal
              id="inventory-template-plugin-drive-new-fields-modal"
              footer={renderFooter(renderProps)}
              open={open}
              onClose={onClose}
              dismissible
              label={
                <Icon icon="lightning">
                  <FormattedMessage
                    id="ui-plugin-inventory-templates.driveNewFieldsModal.label"
                    defaultMessage="Drive new fields from {fieldLabel} list"
                    values={{ fieldLabel }}
                  />
                </Icon>
              }
              size="large"
            >
              <FieldArray
                id={`template-drived-fields-editor-availableLists-${fieldName}`}
                name="availableLists"
                component={renderAvailableLists}
              />

              <RelatedFields fieldArray={fieldArray} />

              <OnChange name="availableLists">
                {(value) => {
                  onChangeAvailableLists(value);
                }}
              </OnChange>
              <hr />
              {/* <FieldGenerator fieldValues={fieldData} /> */}
              <FieldArray
                id={`template-drived-fields-editor-${fieldName}`}
                name="drivedFieldsValues"
                component={renderDrivedFields}
              />
              {/* <hr />
              <pre>{JSON.stringify(renderProps.values, 0, 2)}</pre> */}
            </Modal>
          </form>
        );
      }}
    />
  );
}

DriveNewFieldsModal.propTypes = propTypes;

export default DriveNewFieldsModal;
