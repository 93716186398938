import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Paneset } from '@folio/stripes-components';
import { isEqual, sortBy } from 'lodash';
import { stripesConnect } from '@folio/stripes-core';
import CopycatQueryBuilder from './components/CopycatQueryBuilder/CopycatQueryBuilder';
import CopycatResultsPreview from './components/CopycatResultsPreview/CopycatResultsPreview';
import useURLParams from './hooks/useURLParams';
import CopycatViewRecord from './components/CopycatRecord/CopycatViewRecord/CopycatViewRecord';
import ViewSourceModal from './shared/ViewSourceModal/ViewSourceModal';
import ImportSingleRecordModal from './shared/ImportSingleRecord/ImportSingleRecordModal';
import QuickMarcEditModal from './shared/QuickMarcEditModal/QuickMarcEditModal';

function Copycat(props) {
  const [resultSets, setResultSets] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showQueryPane, setShowQueryPane] = useState(true);
  const [showRecordPane, setShowRecordPane] = useState(false);
  const [sourcesList, setSourcesList] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [importedList, setImportedList] = useState([]);

  const [openViewSourceModal, setOpenViewSourceModal] = useState(false);
  const [openImportRecordModal, setOpenImportRecordModal] = useState(false);
  const [showQuickMarcEditModal, setShowQuickMarcEditModal] = useState(false);

  const [querySources, setQuerySources] = useURLParams(
    'sources',
    ['Koha Demo', 'Library of Congress'],
    'array'
  );
  const [currentPage, setCurrentPage] = useURLParams('page', 1, 'number');
  const [pageSize, setPageSize] = useURLParams('size', 50, 'number');

  useEffect(() => {
    const copycatProfiles = sortBy(
      (props.resources.copycatProfiles || {}).records || [],
      ['']
    );

    if (!isEqual(sourcesList, copycatProfiles)) {
      setSourcesList(
        copycatProfiles && copycatProfiles.length
          ? copycatProfiles[0].profiles
          : undefined
      );
    }
  }, [props.resources]);

  return (
    <>
      <Paneset>
        <CopycatQueryBuilder
          {...props}
          showQueryPane={showQueryPane}
          setShowQueryPane={setShowQueryPane}
          sourcesList={sourcesList}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setResultSets={setResultSets}
          setIsLoading={setIsLoading}
          setError={setError}
          isLoading={isLoading}
          querySources={querySources}
          setQuerySources={setQuerySources}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
        <CopycatResultsPreview
          showQueryPane={showQueryPane}
          setShowQueryPane={setShowQueryPane}
          searchResponse={{ resultSets, error, isLoading }}
          selectedSources={querySources}
          sourcesList={sourcesList}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          pageSize={pageSize}
          setShowRecordPane={setShowRecordPane}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          setOpenViewSourceModal={setOpenViewSourceModal}
          importedList={importedList}
        />
        <CopycatViewRecord
          showRecordPane={showRecordPane}
          onClose={setShowRecordPane}
          selectedItem={selectedItem}
          setOpenViewSourceModal={setOpenViewSourceModal}
          setOpenImportRecordModal={setOpenImportRecordModal}
          setShowQuickMarcEditModal={setShowQuickMarcEditModal}
        />
      </Paneset>
      <ViewSourceModal
        open={openViewSourceModal}
        onClose={() => setOpenViewSourceModal(false)}
        itemToView={selectedItem}
        selectedItems={selectedItems}
      />
      <QuickMarcEditModal
        open={showQuickMarcEditModal}
        onClose={() => setShowQuickMarcEditModal(false)}
        itemToEdit={selectedItem}
      />
      <ImportSingleRecordModal
        open={openImportRecordModal}
        onClose={() => setOpenImportRecordModal(false)}
        itemToImport={selectedItem}
        sourcesList={sourcesList}
        importedList={importedList}
        setImportedList={setImportedList}
      />
    </>
  );
}

Copycat.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  resources: PropTypes.shape({
    copycatProfiles: PropTypes.shape({
      records: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
  }),
  mutator: PropTypes.object.isRequired,
};

Copycat.manifest = Object.freeze({
  copycatProfiles: {
    type: 'okapi',
    path: 'copycat/profiles?query=enabled=true sortby name&limit=1000',
  },
});

export default stripesConnect((Copycat));
