import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, FilterAccordionHeader } from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';
import TemplateSectionsView from './components/TemplateSectionsView';

const propTypes = {
  template: PropTypes.object,
};

function TemplateFieldsAccordion({ template }) {
  const [toggle, setToggle] = useState(true);
  const namespaces = ['instance', 'holdings', 'items'];

  return (
    <Accordion
      id="plugin-inventory-templates-templateSectionsView-accordion"
      label={
        <h2>
          <FormattedMessage
            id="ui-plugin-inventory-templates.templateView.templateSections.accordion.label"
            defaultMessage="Template sections"
          />
        </h2>
      }
      onToggle={() => setToggle(!toggle)}
      open={toggle}
      separator
      header={FilterAccordionHeader}
    >
      {namespaces.map((templateKey) => {
        return (
          <TemplateSectionsView
            key={templateKey}
            templateKey={templateKey}
            template={template}
          />
        );
      })}
    </Accordion>
  );
}

TemplateFieldsAccordion.propTypes = propTypes;

export default TemplateFieldsAccordion;
