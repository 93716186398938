import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import FormFieldsGenerator from '../FormFieldsGenerator';

const propTypes = {
  formValues: PropTypes.arrayOf(PropTypes.object),
};

function FormPreView({ formValues }) {
  const onHandleSubmit = (values) => {
    console.log(values);
  };

  return (
    <Form
      onSubmit={onHandleSubmit}
      mutators={{
        ...arrayMutators,
      }}
      render={(renderProps) => (
        <form id="instance-form-generator" onSubmit={renderProps.handleSubmit}>
          <FormFieldsGenerator key="instance-form" formValues={formValues} />
          <hr />
          <pre>{JSON.stringify(renderProps.values, 0, 2)}</pre>
        </form>
      )}
    />
  );
}

FormPreView.propTypes = propTypes;

export default FormPreView;
