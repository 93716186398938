import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { LayoutHeader } from '@folio/stripes-components';
import styles from '../styles.css';

const propTypes = {
  selectedTemplate: PropTypes.object,
};

function TemplateDescription({ selectedTemplate }) {
  const intl = useIntl();

  const { description } = selectedTemplate;

  const localizedDesc = description?.find(
    (field) => field?.locale === intl.locale,
  )?.value;

  return (
    <div>
      <LayoutHeader
        title={
          <FormattedMessage
            id="ui-plugin-inventory-templates.addNewModal.descSection.label"
            defaultMessage="Template Description"
          />
        }
        noActions
      />
      <div className={styles.template_desc}>{localizedDesc}</div>
    </div>
  );
}

TemplateDescription.propTypes = propTypes;

export default TemplateDescription;
