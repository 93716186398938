import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import SectionsBuilder from '../../../ui-kit/template-builder/SectionsBuilder';
import instanceScheme from '../../../../constants/scheme/instanceScheme';
import instanceInitValues from '../../../../constants/initialValues/instanceInitValues';

function InstanceData(props) {
  return (
    <SectionsBuilder
      key="instance"
      fieldArrayName="instance"
      paneId="instance"
      paneTitle={
        <FormattedMessage
          id="ui-plugin-inventory-templates.templateFields.instance.builder.paneTitle"
          defaultMessage="Instance Builder"
        />
      }
      defaultScheme={instanceScheme}
      initialValues={instanceInitValues}
    />
  );
}

InstanceData.propTypes = {};

export default InstanceData;
