import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Modal, ModalFooter } from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import FieldAccordion from '../FieldAccordion';
import FieldControls from '../FieldControls';
import FieldLabel from '../FieldLabel';
import FieldTypeSelector from '../FieldTypeSelector';

const propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  fieldData: PropTypes.object,
  fieldName: PropTypes.string,
  parentFields: PropTypes.object,
  parentFieldIndex: PropTypes.number,
  infoConfig: PropTypes.object,
};

function EditFieldGroupModal({
  open,
  onClose,
  fieldData,
  fieldName,
  parentFields,
  parentFieldIndex,
  infoConfig,
}) {
  const { fieldArray, label } = fieldData;

  const { locale } = useIntl();
  const fieldLabel = label.find((field) => field.locale === locale)?.value;

  const onHandleSubmit = (values) => {
    parentFields.update(parentFieldIndex, {
      ...parentFields.value[parentFieldIndex],
      fieldArray: values.fieldArray,
    });
    onClose();
  };

  const renderFooter = (renderProps) => {
    return (
      <ModalFooter>
        <Button
          id="inventory-template-plugin-edit-field-group-modal-footer-save-btn"
          buttonStyle="primary"
          type="submit"
          onClick={(event) => {
            renderProps.handleSubmit(event);
          }}
          disabled={renderProps.pristine || renderProps.submitting}
        >
          <FormattedMessage
            id="ui-plugin-inventory-templates.buttons.save"
            defaultMessage="Save"
          />
        </Button>
        <Button
          id="inventory-template-plugin-edit-field-group-modal-footer-close-btn"
          buttonStyle="slim"
          onClick={onClose}
        >
          <Icon icon="times-circle-solid" size="large">
            <FormattedMessage id="stripes-core.button.close" />
          </Icon>
        </Button>
      </ModalFooter>
    );
  };

  const renderFieldGroup = ({ fields }) => {
    return (
      <>
        {fields.map((field, index) => {
          return (
            <FieldAccordion
              id={`${field}-template-field-accordion-${index}`}
              fieldName={field}
              fieldData={fields.value[index]}
              infoConfig={infoConfig}
              index={index}
            >
              <FieldControls
                fieldData={fields.value[index]}
                fieldName={field}
              />
              <FieldLabel fieldData={fields.value[index]} fieldName={field} />
              <FieldTypeSelector
                fieldName={field}
                fieldData={fields.value[index]}
              />
            </FieldAccordion>
          );
        })}
      </>
    );
  };

  return (
    <Form
      onSubmit={onHandleSubmit}
      initialValues={{ fieldArray }}
      mutators={{
        ...arrayMutators,
      }}
      render={(renderProps) => (
        <form id="edit-field-group-form" onSubmit={renderProps.handleSubmit}>
          <Modal
            id="inventory-template-plugin-edit-field-group-modal"
            footer={renderFooter(renderProps)}
            open={open}
            onClose={onClose}
            dismissible
            label={
              <Icon icon="edit">
                <FormattedMessage
                  id="ui-plugin-inventory-templates.editFieldGroupModal.label"
                  //   defaultMessage="تحرير مجموعة حقول {fieldLabel}"
                  defaultMessage="Edit {fieldLabel} fields group"
                  values={{ fieldLabel }}
                />
              </Icon>
            }
          >
            <FieldArray
              id={`template-field-group-editor-${fieldName}`}
              name="fieldArray"
              component={renderFieldGroup}
            />
          </Modal>
        </form>
      )}
    />
  );
}

EditFieldGroupModal.propTypes = propTypes;

export default EditFieldGroupModal;
