/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Label } from '@folio/stripes-components';

const propTypes = {
  fieldValues: PropTypes.object,
  index: PropTypes.number,
  parentField: PropTypes.object,
  fields: PropTypes.object,
  fieldname: PropTypes.string,
  noLabel: PropTypes.bool,
  fullWidth: PropTypes.bool,
  buttonLabel: PropTypes.object,
};

function ToggleButton({
  fieldValues,
  index,
  parentField,
  fields,
  fieldname,
  noLabel,
  buttonLabel,
  fullWidth = true,
}) {
  const { locale } = useIntl();
  const { name, label, canEdit, isRepeatable } = fieldValues;

  const fieldLabel = label.find((field) => field.locale === locale)?.value;

  const isPrimary = fields?.value[index][fieldname || name] === true;

  const handleClick = () => {
    if (isPrimary) {
      return;
    }

    // Reset other primary fields
    fields.forEach((_, id) => {
      fields.update(id, { ...fields.value[id], [fieldname || name]: false });
    });

    // Set primary flag for current field
    fields.update(index, { ...fields.value[index], [fieldname || name]: true });
  };

  return (
    <>
      {noLabel ||
      isRepeatable ||
      (parentField?.type === 'fieldArray' && index !== 0) ? (
        ''
      ) : (
        <Label fullWidth>{fieldLabel}</Label>
      )}
      <Button
        data-testid="primaryToggleButton"
        data-test-primary-toggle-button
        buttonStyle={isPrimary ? 'primary' : 'default'}
        onClick={handleClick}
        type="button"
        disabled={!canEdit}
        fullWidth={fullWidth}
        onMouseDown={(e) => e.preventDefault()}
      >
        {buttonLabel || (
          <FormattedMessage
            id={`ui-inventory.${isPrimary ? 'primary' : 'makePrimary'}`}
          />
        )}
      </Button>
    </>
  );
}

ToggleButton.propTypes = propTypes;

export default ToggleButton;
