import React from 'react';
import PropTypes from 'prop-types';
import { Icon, LayoutBox, LayoutHeader } from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';
import styles from '../styles.css';
import TemplateName from './TmeplateName';
import TemplateDescription from './TemplateDescription';
import useDataByName from '../../../hooks/useDataByName';

const propTypes = {
  selectedTemplate: PropTypes.object,
  setSelectedTemplate: PropTypes.func,
};

function TemplatesInfo({ selectedTemplate, setSelectedTemplate }) {
  const inventoryTemplates = useDataByName('inventoryTemplates');

  const renderTemplatesSection = () => {
    return (
      <>
        <LayoutHeader
          title={
            <FormattedMessage
              id="ui-plugin-inventory-templates.addNewModal.templateSection.label"
              defaultMessage="Template type"
            />
          }
          noActions
        />
        <div className={styles.container}>
          <div className={styles.templates}>
            {inventoryTemplates.map((template, index) => (
              <TemplateName
                index={index}
                key={`template-${template?.id}`}
                selectedTemplateId={selectedTemplate?.id}
                setSelectedTemplate={setSelectedTemplate}
                template={template}
              />
            ))}
          </div>
        </div>
      </>
    );
  };

  const renderTemplateDescSection = () => {
    return <TemplateDescription selectedTemplate={selectedTemplate} />;
  };

  return (
    <LayoutBox>
      <div>{renderTemplatesSection()}</div>
      <div>{renderTemplateDescSection()}</div>
    </LayoutBox>
  );
}

TemplatesInfo.propTypes = propTypes;

export default TemplatesInfo;
