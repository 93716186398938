import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { CalloutContext, useOkapiKy } from '@folio/stripes-core';
import useCustomFields from '../../../../hooks/useCustomFields';
import CustomFieldsForm from './CustomFieldsForm';

const propTypes = {
  basePath: PropTypes.string,
};

function CustomFieldsManager(props) {
  const history = useHistory();
  const params = useParams();
  const { customFields, isLoading } = useCustomFields(
    params.customFieldId,
  );

  const { locale } = useIntl();
  const context = useContext(CalloutContext);
  const okapiKy = useOkapiKy();

  const [initialValues, setInitialValues] = useState({
    name: '',
    type: '',
    schema: 'customField',
    label: [
      {
        value: '',
        locale: 'ar',
      },
      {
        value: '',
        locale: 'en-US',
      },
    ],
    singularLabel: [
      {
        value: '',
        locale: 'ar',
      },
      {
        value: '',
        locale: 'en-US',
      },
    ],
    isRepeatable: false,
    helpeText: '',
    canEdit: true,
    isRequired: false,
    fieldDataOption: [
      {
        value: crypto.randomUUID(),
        label: [
          {
            value: '',
            locale: 'ar',
          },
          {
            value: '',
            locale: 'en-US',
          },
        ],
        isDefault: false,
      },
    ],
    defaultValue: '',
    fieldArray: [],
    meta: {
      lockRepeatableOption: false,
      lockRequiredOption: false,
      lockCanEditOption: false,
    },
  });

  const localizedName = customFields?.label?.find(
    (field) => field?.locale === locale,
  )?.value;

  useEffect(() => {
    if (params.customFieldId && customFields) {
      setInitialValues(customFields);
    }
  }, [customFields, params.customFieldId]);

  const onAdd = (values) => {
    okapiKy
      .post('inventory-customFields', {
        timeout: 30000,
        method: 'POST',
        json: {
          name: values.name,
          type: values.type,
          schema: 'customField',
          label: values.label,
          helpeText: values.helpeText,
          singularLabel: values.isRepeatable ? values.singularLabel : [],
          isRepeatable: values.isRepeatable,
          canEdit: values.canEdit,
          isRequired: values.isRequired,
          fieldDataOption:
            values.type === 'select' ? values.fieldDataOption : [],
          defaultValue: values.defaultValue,
          fieldArray: values.fieldArray,
          meta: values.meta,
        },
      })
      .json()
      .then((res) => {
        if (res.id) {
          const message = (
            <FormattedMessage
              id="ui-plugin-inventory-templates.customFieldsForm.callout.create"
              defaultMessage="Custom field created successfully"
            />
          );
          history.push(`${props.basePath}/custom-fields-list`);
          context.sendCallout({ message });
        }
      })
      .catch(async (err) => {
        console.log(err);
        const res = err.response;
        const text = await res.text();
        console.log(text);
        context.sendCallout({ message: text });
      });
  };

  const onEdit = (values) => {
    okapiKy
      .put(`inventory-customFields/${params.customFieldId}`, {
        timeout: 30000,
        method: 'PUT',
        headers: {
          'content-Type': 'application/json',
          accept: 'text/plain',
        },
        json: {
          name: values.name,
          type: values.type,
          schema: 'customField',
          label: values.label,
          helpeText: values.helpeText,
          singularLabel: values.isRepeatable ? values.singularLabel : [],
          isRepeatable: values.isRepeatable,
          canEdit: values.canEdit,
          isRequired: values.isRequired,
          fieldDataOption:
            values.type === 'select' ? values.fieldDataOption : [],
          defaultValue: values.defaultValue,
          fieldArray: values.fieldArray,
          meta: values.meta,
        },
      })
      .json()
      .then(() => {
        const message = (
          <FormattedMessage
            id="ui-plugin-inventory-templates.customFieldsForm.callout.updated"
            defaultMessage="Custom field updated successfully"
          />
        );
        history.push(`${props.basePath}/custom-fields-list`);
        context.sendCallout({ message });
      })
      .catch(async (err) => {
        console.log(err);
        const res = err.response;
        const text = await res.text();
        console.log(text);
        context.sendCallout({ message: text });
      });
  };

  const onSubmit = (values) => {
    if (params.customFieldId) {
      onEdit(values);
    } else {
      onAdd(values);
    }
  };

  return (
    <CustomFieldsForm
      customFieldId={params.customFieldId}
      localizedName={localizedName}
      onClose={() => history.go(-1)}
      onHandleSubmit={onSubmit}
      initialValues={initialValues}
      isLoading={isLoading}
    />
  );
}

CustomFieldsManager.propTypes = propTypes;

export default CustomFieldsManager;
