import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'react-final-form-arrays';
import { useIntl } from 'react-intl';
import { Col, Label, Row } from '@folio/stripes-components';
import DrivedField from './DrivedField';
import DrivedRepeatableFields from './DrivedRepeatableFields';

const propTypes = {
  fieldValues: PropTypes.object,
};

function DrivedFieldComponent({ fieldValues }) {
  const [unRepeatableFields, setUnRepeatableFields] = useState([]);
  const [repeatableFields, setRepeatableFields] = useState([]);

  const { locale } = useIntl();

  const fieldLabel = fieldValues.label.find(
    (field) => field.locale === locale,
  )?.value;

  useEffect(() => {
    if (fieldValues.drivedFields) {
      const fieldsUnRepeatable = [];
      const fieldsRepeatable = [];

      fieldValues.drivedFields.values.forEach((driveField) => {
        if (!driveField.isRepeatable) {
          fieldsUnRepeatable.push({
            [fieldValues.drivedFields.name]: driveField.value,
            [fieldValues.drivedFields.relatedTextField]: '',
            driveField,
          });
        } else {
          fieldsRepeatable.push({
            [fieldValues.drivedFields.name]: driveField.value,
            [fieldValues.drivedFields.relatedTextField]: '',
            driveField,
          });
        }
      });
      setUnRepeatableFields(fieldsUnRepeatable);
      setRepeatableFields(fieldsRepeatable);
    }
  }, [fieldValues.drivedFields]);

  const initialValue = React.useMemo(
    () => [
      ...unRepeatableFields.map(({ driveField, ...rest }) => ({
        ...rest,
      })),
    ],
    [unRepeatableFields],
  );

  return (
    <>
      <Row>
        <Col xs={6}>
          <Label required={fieldValues.isRequired}><h3>{fieldLabel}</h3></Label>
        </Col>
      </Row>
      {unRepeatableFields.length !== 0 && (
        <FieldArray
          id={`form-fields-generator-${fieldValues.name}`}
          name={`${fieldValues.name}.drivedFields`}
          component={DrivedField}
          fieldValues={fieldValues}
          unRepeatableFields={unRepeatableFields}
          initialValue={initialValue}
        />
      )}
      {repeatableFields.length !== 0 && (
        <>
          <DrivedRepeatableFields
            fieldValues={fieldValues}
            repeatableFields={repeatableFields}
          />
        </>
      )}
    </>
  );
}

DrivedFieldComponent.propTypes = propTypes;

export default DrivedFieldComponent;
