import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from '@folio/stripes-components';
import TextFieldComponent from './TextFieldComponent';
import SelectFieldComponent from './SelectFieldComponent';
import DateFieldComponent from './DateFieldComponent';
import CheckboxFieldComponent from './CheckboxFieldComponent';
import ToggleButton from './ToggleButton';
import LocationFieldComponent from './LocationFieldComponent';

export const fieldTypesComponents = {
  text: TextFieldComponent,
  select: SelectFieldComponent,
  custom: () => <div>custom component</div>,
  date: DateFieldComponent,
  checkbox: CheckboxFieldComponent,
  toggleButton: ToggleButton,
  locationField: LocationFieldComponent
};

const propTypes = {
  fieldValues: PropTypes.object,
  fieldName: PropTypes.string,
  index: PropTypes.number,
  fields: PropTypes.object,
};

function FieldArrayComponent({ fieldValues, fieldName, index, fields }) {
  console.log('fieldValues: ', fieldValues);
  return (
    <Row>
      {fieldValues.fieldArray.map((field) => {
        const FieldComponent = fieldTypesComponents[field.type];
        const fieldSize = 12 / fieldValues.fieldArray.length;

        return (
          <Col xs={fieldSize} key={field.name}>
            <FieldComponent
              key={field.name}
              index={index}
              fieldValues={field}
              fieldName={`${fieldName}.${field.name}`}
              parentField={fieldValues}
              fields={fields}
            />
          </Col>
        );
      })}
    </Row>
  );
}

FieldArrayComponent.propTypes = propTypes;

export default FieldArrayComponent;
