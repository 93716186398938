import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, IconButton, Label } from '@folio/stripes-components';
import { useIntl } from 'react-intl';
import InfoModal from '../../../InfoModal';

const propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  fieldName: PropTypes.string,
  infoConfig: PropTypes.object,
  onRemove: PropTypes.func,
  fieldData: PropTypes.object,
  closedByDefault: PropTypes.bool,
  label: PropTypes.string,
};

function FieldAccordion({
  children,
  label,
  index,
  fieldName,
  infoConfig,
  onRemove,
  fieldData,
  closedByDefault = false,
}) {
  const intl = useIntl();

  const fieldLabel =
    label ||
    fieldData.label.find((locale) => locale.locale === intl.locale)?.value;

  const renderAccordionHeader = () => {
    return (
      <>
        {onRemove && (
          <IconButton
            icon="trash"
            onClick={onRemove}
            data-test-custom-field-delete-button
          />
        )}
        {infoConfig && (
          <InfoModal
            infoTitle={<strong>{fieldLabel}</strong>}
            infoContent={infoConfig.infoContent}
            allowAnchorClick
            iconSize="medium"
            buttonLabel={infoConfig.infoBtnLabel}
            buttonHref={infoConfig.infoBtnHref}
            buttonTarget={infoConfig.infoBtnTarget}
          />
        )}
      </>
    );
  };

  return (
    <Accordion
      id={`${fieldName}-template-field-${index}`}
      label={<h4>{fieldLabel}</h4>}
      displayWhenOpen={renderAccordionHeader()}
      displayWhenClosed={renderAccordionHeader()}
      closedByDefault={closedByDefault}
    >
      <div>{children}</div>
    </Accordion>
  );
}

FieldAccordion.propTypes = propTypes;

export default FieldAccordion;
