import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, IconButton, Label } from '@folio/stripes-components';
import { useIntl } from 'react-intl';
import { FieldArray } from 'react-final-form-arrays';
import AddNewSectionForm from './AddNewSectionForm';
import RenderSectionFieldsArray from './RenderSectionFieldsArray';

const propTypes = {
  fields: PropTypes.object,
  paneId: PropTypes.string,
  openSectionBuilder: PropTypes.bool,
  actionType: PropTypes.string,
  setActionType: PropTypes.func,
  setOpenSectionBuilder: PropTypes.func,
  setSectionInitValues: PropTypes.func,
  defaultScheme: PropTypes.arrayOf(PropTypes.object),
  sectionInitValues: PropTypes.object,
};

function RenderFieldArray({
  fields,
  paneId,
  openSectionBuilder,
  setActionType,
  setOpenSectionBuilder,
  setSectionInitValues,
  defaultScheme,
  actionType,
  sectionInitValues
}) {
  const intl = useIntl();

  const renderAccordionHeader = (index) => {
    const sectionValue = fields.value[index];
    return (
      <>
        <IconButton
          icon="edit"
          onClick={() => {
            setActionType('edit');
            setOpenSectionBuilder(true);
            setSectionInitValues(sectionValue);
          }}
        />
        <IconButton icon="trash" onClick={() => fields.remove(index)} />
      </>
    );
  };

  return (
    <>
      {fields.map((name, index) => (
        <Accordion
        //   key={`${name}-template-field-${crypto.randomUUID()}`}
          id={`${name}-template-field-${index}`}
          label={
            <Label fullWidth>
              <h2>
                {
                  fields?.value[index]?.sectionLabel?.find(
                    (locale) => locale.locale === intl.locale,
                  )?.value
                }
              </h2>
            </Label>
          }
          displayWhenOpen={renderAccordionHeader(index)}
          displayWhenClosed={renderAccordionHeader(index)}
        >
          <div style={{ padding: '30px' }}>
            <FieldArray
              id={`template-${paneId}-fields-builder`}
              name={`${name}.sectionFields`}
              component={RenderSectionFieldsArray}
            />
          </div>
        </Accordion>
      ))}
      <AddNewSectionForm
        open={openSectionBuilder}
        onClose={() => setOpenSectionBuilder(false)}
        arrayFields={fields}
        defaultScheme={defaultScheme}
        fieldName={fields.name}
        actionType={actionType}
        initialValues={sectionInitValues}
      />
    </>
  );
}

RenderFieldArray.propTypes = propTypes;

export default RenderFieldArray;
