import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TemplateField from '../TemplateField';

const propTypes = {
  fields: PropTypes.object,
};

function RenderSectionFieldsArray({ fields }) {
  return (
    <>
      {fields.map((name, index) => (
        <TemplateField
        //   key={crypto.randomUUID()}
          fieldName={`${name}`}
          fieldData={fields.value[index]}
          index={index}
          parentFields={fields}
          infoContent={
            <FormattedMessage
              id="ui-plugin-inventory-templates.templateField.info.content"
              defaultMessage="Put your content her."
            />
          }
          infoBtnLabel={
            <FormattedMessage
              id="ui-plugin-inventory-templates.templateField.info.btnLabel.readMore"
              defaultMessage="Reade more"
            />
          }
          infoBtnHref="https://docs.folio.org/docs"
          infoBtnTarget="_blank"
          onRemove={() => fields.remove(index)}
        />
      ))}
    </>
  );
}

RenderSectionFieldsArray.propTypes = propTypes;

export default RenderSectionFieldsArray;
