import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Icon,
  DropdownMenu,
  Dropdown,
  Button,
  MenuSection,
} from '@folio/stripes-components';
import { IfPermission, useOkapiKy } from '@folio/stripes-core';
import { useHistory } from 'react-router';
import { isArray, mergeWith } from 'lodash';

const propTypes = {
  setOpenViewSourceModal: PropTypes.func,
  selectedItem: PropTypes.object,
  cartList: PropTypes.arrayOf(PropTypes.object),
  setCartList: PropTypes.func,
  showCartList: PropTypes.bool,
  setShowCartList: PropTypes.func,
};

const ItemActionsMenu = ({
  setOpenViewSourceModal,
  cartList,
  setCartList,
  showCartList,
  setShowCartList,
  selectedItem,
}) => {
  const history = useHistory();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const abortController = new AbortController();
  const signal = abortController.signal;
  const okapiKy = useOkapiKy();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const parseMarcToInstance = (record) => {
    const instanceRecord = JSON.parse(record.marcToInstance);

    instanceRecord.source = record.serverName;
    instanceRecord.title = record.title;
    instanceRecord.id = record.id;

    return instanceRecord;
  };

  const loadRecord = async () => {
    // setIsLoading(true);
    try {
      const res = await okapiKy
        .post('copycat/imported-record', {
          timeout: 30000,
          method: 'POST',
          json: {
            userId: '',
            recordData: selectedItem,
            status: 'staged',
            cartListId: '',
          },
        })
        .json();
      if (!signal.aborted) {
        console.log('res: ', res);
        history.push(`/inventory/copycat/edit/${res.id}/bibframe`, {
          record: parseMarcToInstance(res.recordData),
        });
      }
    } catch (err) {
      if (!signal.aborted) {
        const res = err.response;
        const text = await res.text();

        let detail = text;
        if (res.headers.get('content-type') === 'application/json') {
          const obj = JSON.parse(text);
          detail = obj.errors[0].message;
        }
        alert(detail);
        // setError(detail);
      }
    } finally {
      if (!signal.aborted) {
        // setIsLoading(false);
      }
    }
  };

  const handleCartList = (isExists) => {
    if (!showCartList && !isExists) {
      setCartList([...cartList, selectedItem]);
    } else if (showCartList && !isExists && cartList.length === 1) {
      setCartList([]);
      setShowCartList(false);
    } else {
      setCartList(
        cartList.filter((cartItem) => cartItem.id !== selectedItem.id)
      );
    }
  };

  const getDropdownContent = () => {
    // const isExists = cartList.find((cartItem) => cartItem.id === selectedItem.id);
    return (
      <div>
        <MenuSection>
          {/* <NavListItem
            id="copycat-results-preview-item-actions-menu-add-to-cart"
            buttonStyle="slim"
            onClick={() => {
              handleCartList(isExists);
              toggleDropdown();
            }}
          >
            <Icon icon="cart">
              {!showCartList && !isExists ? (
                <FormattedMessage
                  id="ui-inventory.copycat.resultsPreview.itemActionsMenu.addToCart"
                  defaultMessage="Add to cart"
                />
              ) : (
                <FormattedMessage
                  id="ui-inventory.copycat.resultsPreview.itemActionsMenu.removeFromCart"
                  defaultMessage="Remove from cart"
                />
              )}
            </Icon>
          </NavListItem> */}
          <Button
            id="copycat-results-preview-item-actions-menu-view-source"
            buttonStyle="dropdownItem"
            onClick={() => {
              setOpenViewSourceModal(true);
              toggleDropdown();
            }}
          >
            <Icon icon="source">
              <FormattedMessage
                id="ui-inventory.copycat.resultsPreview.itemActionsMenu.viewSource"
                defaultMessage="View source"
              />
            </Icon>
          </Button>
          <IfPermission perm="ui-inventory.instance.create">
            <Button
              id="copycat-imported-list-action-menu-editInInventory-btn"
              buttonStyle="dropdownItem"
              onMouseDown={(e) => e.preventDefault()}
              // onClick={() => history.push('/inventory/copycat/edit/bibframe', {
              //   selectedItem,
              // })
              // }
              onClick={() => loadRecord()}
            >
              <Icon icon="edit" size="medium">
                <FormattedMessage
                  id="ui-inventory.copycat.buttons.editInInventory"
                  defaultMessage="Edit in inventory"
                />
              </Icon>
            </Button>
            <Button
              id="copycat-imported-list-action-menu-editInMarcEditor-btn"
              buttonStyle="dropdownItem"
              to="/inventory/copycat/edit/marc"
              onMouseDown={(e) => e.preventDefault()}
            >
              <Icon icon="lightning" size="medium">
                <FormattedMessage
                  id="ui-inventory.copycat.buttons.editInMarcEditor"
                  defaultMessage="Edit in marc editor"
                />
              </Icon>
            </Button>
          </IfPermission>
        </MenuSection>
      </div>
    );
  };

  const trigger = ({ getTriggerProps, ariaProps }) => {
    return (
      <Button
        buttonStyle="primary"
        marginBottom0
        {...ariaProps}
        {...getTriggerProps()}
      >
        <Icon icon="ellipsis" size="large" />
      </Button>
    );
  };

  const renderMenu = ({ open }) => (
    <DropdownMenu open={open}>{getDropdownContent()}</DropdownMenu>
  );

  return (
    <Dropdown
      id="copycat-results-preview-item-actions-menu"
      renderTrigger={trigger}
      renderMenu={renderMenu}
      open={dropdownOpen}
      onToggle={toggleDropdown}
      placement="bottom-end"
      relativePosition
      focusHandlers={{ open: () => null }}
      // disabled={disabled}
    />
  );
};

ItemActionsMenu.propTypes = propTypes;

export default ItemActionsMenu;
