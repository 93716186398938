import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Col,
  Icon,
  Row,
  Select,
  TextField,
} from '@folio/stripes-components';
import { FieldArray } from 'react-final-form-arrays';
import { FormattedMessage } from 'react-intl';
import { Field } from 'react-final-form';
import LocalizedTextField from '../../../ui-kit/LocalizedTextField';
import styles from '../styles.css';

const propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object),
};

function RenderFieldDataOption({ fields }) {
  const renderFieldDataOption = () => {
    return (
      <>
        {fields.map((fieldName, index) => (
          <Row>
            <Col xs={6}>
              <FieldArray
                name={`${fieldName}.label`}
                component={LocalizedTextField}
                fieldComponent={TextField}
                fieldLabel={
                  <FormattedMessage
                    id="ui-plugin-inventory-templates.templateField.optionLabel"
                    defaultMessage="Option label"
                  />
                }
              />
            </Col>
            <Col xs={3}>
              <Field
                id="isDefault"
                name={`${fieldName}.isDefault`}
                component={Checkbox}
                // checked={isRepeatable}
                fullWidth
                label={
                  <FormattedMessage
                    id="ui-plugin-inventory-templates.templateField.isDefault"
                    defaultMessage="Is default"
                  />
                }
                type="checkbox"
              />
            </Col>
            <Col xs={3} className={styles.remove_btn}>
              <Button
                id={`inventory-templates-localized-field-remove-${fieldName}-${index}-btn`}
                buttonStyle="slim"
                // disabled={fields.value.length === 1}
                onClick={() => fields.remove(index)}
              >
                <Icon icon="trash" />
              </Button>
            </Col>
          </Row>
        ))}
        <Button
          id="inventory-templates-localized-field-btn"
          buttonStyle="primary"
          onClick={() => fields.push({
            value: crypto.randomUUID(),
            label: [
              {
                value: '',
                locale: 'ar',
              },
              {
                value: '',
                locale: 'en-US',
              },
            ],
            isDefault: false,
          })
          }
        >
          <Icon icon="plus-sign">
            <FormattedMessage
              id="ui-plugin-inventory-templates.customFieldsForm.fieldtypes.select.fieldDataOption.addFieldOption"
              defaultMessage="Add field option"
            />
          </Icon>
        </Button>
      </>
    );
  };
  return <div>{renderFieldDataOption()}</div>;
}

RenderFieldDataOption.propTypes = propTypes;

export default RenderFieldDataOption;
