import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field } from 'react-final-form';
import { Col, MultiSelection, Row } from '@folio/stripes-components';
import useDataByName from '../../../../../../hooks/useDataByName';
import styles from './styles.css';
import ToggleButton from '../../../../FormFieldsGenerator/components/ToggleButton';

const propTypes = {
  fieldName: PropTypes.string,
  fieldData: PropTypes.object,
  index: PropTypes.number,
  fields: PropTypes.object,
};

function AvailableLists({ fieldName, fields, fieldData, index }) {
  const data = useDataByName(fieldData?.dataOptionsSource);

  const options = [];
  (data || []).forEach((opt) => {
    options.push({
      value: opt.id,
      label: opt.name,
      isRepeatable: false,
      isRequired: false,
      canEdit: true,
    });
  });

  const { label, dataOptions } = fieldData;
  const { locale, formatMessage } = useIntl();
  const fieldLabel = label?.find((field) => field?.locale === locale)?.value;
  const isPrimary = fields.value[index].isPrimary;

  const validate = (value, primary) => {
    if (value?.length && !primary) return undefined;

    return (
      <strong>
        <FormattedMessage
          id="ui-reports.validation.required"
          defaultMessage="required"
        />
      </strong>
    );
  };

  return (
    <Row>
      <Col xs={6}>
        <Field
          id={`${fieldName}.values`}
          name={`${fieldName}.values`}
          type="text"
          component={MultiSelection}
          dataOptions={options}
          formatter={({ option }) => (
            <div key={option.value}>
              {formatMessage({
                id: `ui-plugin-inventory-templates.${
                  fieldData.tableName || fieldData.dataOptionsSource
                }.name.${option.label}`,
                defaultMessage: option.label,
              })}
            </div>
          )}
          fullWidth
          label={fieldLabel}
          disabled={!isPrimary}
          required={isPrimary}
          // validate={(value) => validate(value, isPrimary)}
        />
      </Col>
      {fields.length > 1 && (
        <Col xs={6} className={styles.driveBtn}>
          <ToggleButton
            fieldValues={fieldData}
            fields={fields}
            index={index}
            fieldname="isPrimary"
            noLabel
            fullWidth={false}
            buttonLabel={
              <FormattedMessage
                id="ui-plugin-inventory-templates.driveNewFieldsModal.driveBtn.label"
                defaultMessage="Drive here"
              />
            }
          />
        </Col>
      )}
    </Row>
  );
}

AvailableLists.propTypes = propTypes;

export default AvailableLists;
