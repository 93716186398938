import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  Col,
  FilterAccordionHeader,
  KeyValue,
  Row,
} from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';

const propTypes = {
  template: PropTypes.object,
};

function TemplateInfoAccordion({ template }) {
  const [toggle, setToggle] = useState(true);
  const intl = useIntl();
  const localizedName = template.name?.find(
    (field) => field?.locale === intl.locale,
  )?.value;

  const localizedDesc = template.description?.find(
    (field) => field?.locale === intl.locale,
  )?.value;

  return (
    <Accordion
      id="plugin-inventory-templates-templateInfoView-accordion"
      label={
        <h2>
          <FormattedMessage
            id="ui-plugin-inventory-templates.templateView.templateInfo.accordion.label"
            defaultMessage="Template info"
          />
        </h2>
      }
      onToggle={() => setToggle(!toggle)}
      open={toggle}
      separator
      header={FilterAccordionHeader}
    >
      <Row>
        <Col smOffset={0} sm={3}>
          <KeyValue
            label={
              <FormattedMessage
                id="ui-plugin-inventory-templates.templateView.templateInfo.label"
                defaultMessage="Template label"
              />
            }
            value={localizedName}
          />
        </Col>
        <Col smOffset={0} sm={5}>
          <KeyValue
            label={
              <FormattedMessage
                id="ui-plugin-inventory-templates.templateView.templateInfo.description"
                defaultMessage="Template description"
              />
            }
            value={localizedDesc}
          />
        </Col>
        <Col smOffset={0} sm={2}>
          <KeyValue
            label={
              <FormattedMessage
                id="ui-plugin-inventory-templates.templateView.templateInfo.isDefault"
                defaultMessage="Is default"
              />
            }
            value={
              <FormattedMessage
                id={`ui-plugin-inventory-templates.inventoryTemplates.${template.isDefault.toString()}`}
                defaultMessage={template.isDefault.toString()}
              />
            }
          />
        </Col>
        <Col smOffset={0} sm={2}>
          <KeyValue
            label={
              <FormattedMessage
                id="ui-plugin-inventory-templates.templateView.templateInfo.isActive"
                defaultMessage="Active"
              />
            }
            value={
              <FormattedMessage
                id={`ui-plugin-inventory-templates.inventoryTemplates.${template.active.toString()}`}
                defaultMessage={template.active.toString()}
              />
            }
          />
        </Col>
      </Row>
      <Row>
        <Col smOffset={0} sm={6}>
          <KeyValue
            label={
              <FormattedMessage
                id="ui-plugin-inventory-templates.templateView.templateInfo.isLocked"
                defaultMessage="Is Locked"
              />
            }
            value={
              <FormattedMessage
                id={`ui-plugin-inventory-templates.inventoryTemplates.${template.isLocked.toString()}`}
                defaultMessage={template.isLocked.toString()}
              />
            }
          />
        </Col>
        <Col smOffset={0} sm={6}>
          <KeyValue
            label={
              <FormattedMessage
                id="ui-plugin-inventory-templates.templateView.templateInfo.code"
                defaultMessage="Code"
              />
            }
            value={template.code}
          />
        </Col>
      </Row>
    </Accordion>
  );
}

TemplateInfoAccordion.propTypes = propTypes;

export default TemplateInfoAccordion;
