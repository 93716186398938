import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, FilterAccordionHeader } from '@folio/stripes-components';
import { useIntl } from 'react-intl';
import SectionFieldsList from './SectionFieldsList';

const propTypes = {
  section: PropTypes.object,
};

function SectionAccordion({ section }) {
  const [toggle, setToggle] = useState(true);
  const { locale } = useIntl();
  const localizedName = section?.sectionLabel?.find(
    (field) => field?.locale === locale,
  )?.value;

  return (
    <div
      style={{ paddingLeft: '30px', paddingRight: '30px' }}
      key={section.sectionId}
    >
      <Accordion
        id="plugin-inventory-templates-templateSectionsView-sectionAccordion-accordion"
        key={section.sectionId}
        label={<h2>{localizedName}</h2>}
        onToggle={() => setToggle(!toggle)}
        open={toggle}
        separator
        header={FilterAccordionHeader}
      >
        <SectionFieldsList sectionFields={section.sectionFields} />
      </Accordion>
    </div>
  );
}

SectionAccordion.propTypes = propTypes;

export default SectionAccordion;
