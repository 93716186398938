import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import ChooseTemplateTypeModal from '../components/ChooseTemplete/ChooseTemplateTypeModal';

function ChooseTemplateRoute({ basePath }) {
  const [openChooseModal, setOpenChooseModal] = useState(false);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (
      location.pathname === '/inventory/add-new-instance/choose-source'
    ) {
      setOpenChooseModal(true);
    }
    return () => {
      setOpenChooseModal(false);
    };
  }, [location, location.pathname]);

  return (
    <ChooseTemplateTypeModal
      basePath={basePath}
      open={openChooseModal}
      onClose={() => {
        setOpenChooseModal(!openChooseModal);
        history.go(-1);
      }}
    />
  );
}

ChooseTemplateRoute.propTypes = {
  basePath: PropTypes.string.isRequired,
};

export default ChooseTemplateRoute;
