import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import SectionsBuilder from '../../../ui-kit/template-builder/SectionsBuilder';
import itemsScheme from '../../../../constants/scheme/itemsScheme';

function ItemsData(props) {
  return (
    <SectionsBuilder
      key="items"
      fieldArrayName="items"
      paneId="items"
      paneTitle={
        <FormattedMessage
          id="ui-plugin-inventory-templates.templateFields.items.builder.paneTitle"
          defaultMessage="Items Builder"
        />
      }
      defaultScheme={itemsScheme}
      initialValues={[]}
    />
  );
}

ItemsData.propTypes = {};

export default ItemsData;
