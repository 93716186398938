import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Datepicker } from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';

const propTypes = {
  fieldName: PropTypes.string,
  fieldData: PropTypes.object,
};

function DateFieldType({ fieldName, fieldData }) {
  return (
    <Field
      id={`inventory-templates-form-generator-${fieldData.type}-field-${fieldName}.defaultValue`}
      component={Datepicker}
      fullWidth
      name={`${fieldName}.defaultValue`}
      label={
        <FormattedMessage
          id="ui-plugin-inventory-templates.templateField.defaultValue.label"
          defaultMessage="Default value"
        />
      }
      type="text"
      dateFormat="YYYY-MM-DD"
      backendDateStandard="YYYY-MM-DD"
      defaultValue={new Date()}
    />
  );
}

DateFieldType.propTypes = propTypes;

export default DateFieldType;
