import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'react-final-form-arrays';
import { Col, Row, TextField } from '@folio/stripes-components';
import {
  createIntl,
  createIntlCache,
  FormattedMessage,
  useIntl,
} from 'react-intl';
import LocalizedTextField from '../../../../LocalizedTextField';

const propTypes = {
  fieldName: PropTypes.string,
  fieldData: PropTypes.object,
  fieldSource: PropTypes.string,
};

function DrivedFieldLabel({ fieldName, fieldData, fieldSource }) {
  const intl = useIntl();

  const getCollectionDefaultLabel = (localeValue) => {
    const cache = createIntlCache();
    const newIntl = {
      intl: createIntl(
        {
          locale: localeValue,
          messages: intl.messages,
        },
        cache,
      ),
    };
    return newIntl.intl.formatMessage({
      id: `ui-plugin-inventory-templates.${fieldSource.dataOptionsSource}.name.${fieldData.label}`,
      defaultMessage: fieldData.label,
    });
  };

  const initialValue = React.useMemo(() => {
    if (!fieldData?.collectionLabel?.length) {
      return [
        {
          value: getCollectionDefaultLabel('ar'),
          locale: 'ar',
        },
        {
          value: fieldData?.label,
          locale: 'en-US',
        },
      ];
    }

    return fieldData.collectionLabel;
  }, []);

  const singularLabelInitialValue = React.useMemo(() => {
    if (!fieldData?.singularLabel?.length) {
      return [
        {
          value: getCollectionDefaultLabel('ar'),
          locale: 'ar',
        },
        {
          value: fieldData?.label,
          locale: 'en-US',
        },
      ];
    }

    return fieldData.singularLabel;
  }, []);

  return (
    <Row>
      <Col xs={6}>
        <FieldArray
          id={`${fieldName}-singularLabel`}
          name={`${fieldName}.singularLabel`}
          component={LocalizedTextField}
          fieldComponent={TextField}
          fieldLabel={
            <FormattedMessage
              id="ui-plugin-inventory-templates.driveNewFieldsModal.singularLabel"
              defaultMessage="Singular Label"
            />
          }
          initialValue={singularLabelInitialValue}
        />
      </Col>
      {fieldData?.isRepeatable && (
        <Col xs={6}>
          <FieldArray
            id={`localizedArrayField-driveNewFieldsModal-isRepeatable-options-${fieldName}`}
            name={`${fieldName}.collectionLabel`}
            component={LocalizedTextField}
            fieldComponent={TextField}
            validateFields={[]}
            fieldLabel={
              <FormattedMessage
                id="ui-plugin-inventory-templates.driveNewFieldsModal.collectionLabel.label"
                defaultMessage="Collection Label"
              />
            }
            isRequired
            initialValue={initialValue}
          />
        </Col>
      )}
    </Row>
  );
}

DrivedFieldLabel.propTypes = propTypes;

export default DrivedFieldLabel;
