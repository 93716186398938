import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Icon, Label, Row } from '@folio/stripes-components';
import { uniqueId } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from '../styles.css';

const propTypes = {
  fields: PropTypes.object,
  fieldValues: PropTypes.object,
  RenderFieldComponent: PropTypes.elementType,
};

function FormFieldArray({ fields, fieldValues, RenderFieldComponent }) {
  const { locale } = useIntl();
  const { label, singularLabel, type, isRequired, isRepeatable, fieldArray } =
    fieldValues;

  const fieldLabel = label.find((field) => field.locale === locale)?.value;
  const unitLabel = singularLabel?.find(
    (field) => field.locale === locale,
  )?.value;

  const onAddNewField = useCallback(() => {
    if (type === 'fieldArray') {
      const fieldObj = {};
      fieldArray.forEach((key) => {
        fieldObj[key.name] = '';
      });
      fields.push(fieldObj);
    } else {
      fields.push('');
    }
  }, [fieldArray, fields, type]);

  useEffect(() => {
    if (!isRepeatable && fieldArray.length) {
      const fieldObj = {};
      fieldArray.forEach((key) => {
        fieldObj[key.name] = '';
      });
      fields.push(fieldObj);
    }
  }, []);

  const renderFieldArray = () => {
    return (
      <>
        <Row>
          <Col xs={6}>
            <Label required={isRequired}>{fieldLabel}</Label>
          </Col>
        </Row>
        {fields.map((fieldName, id) => (
          <Row key={fieldName}>
            <Col xs={11}>
              <RenderFieldComponent
                index={id}
                fieldValues={fieldValues}
                fieldName={fieldName}
                fields={fields}
              />
            </Col>
            {isRepeatable && (
              <Col
                xs={1}
                className={
                  type === 'fieldArray' && id === 0 ? styles.remove_btn : ''
                }
              >
                <Button
                  id={`inventory-templates-localized-field-remove-${fieldValues.name}-${id}-btn`}
                  buttonStyle="slim"
                  onClick={() => fields.remove(id)}
                >
                  <Icon icon="trash" />
                </Button>
              </Col>
            )}
          </Row>
        ))}
        {isRepeatable && (
          <Button
            id={uniqueId('inventory-templates-localized-field-add-btn')}
            onClick={() => onAddNewField()}
            icon="plus-sign"
          >
            <FormattedMessage
              id="ui-plugin-inventory-templates.formGenerator.addBtn"
              values={{ unitLabel }}
              defaultMessage="Add {unitLabel}"
            />
          </Button>
        )}
      </>
    );
  };

  return <div className={styles.container}>{renderFieldArray()}</div>;
}

FormFieldArray.propTypes = propTypes;

export default FormFieldArray;
