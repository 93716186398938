import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Datepicker, Label } from '@folio/stripes-components';
import { required } from '../../../../utils/utils';

const propTypes = {
  fieldValues: PropTypes.object,
  index: PropTypes.number,
  locale: PropTypes.string,
};

function DateFieldComponent({ fieldValues, index, locale }) {
  const fieldLabel = fieldValues.label.find(
    (label) => label.locale === locale,
  )?.value;

  return (
    <Field
      id={`inventory-templates-form-generator-${fieldValues.type}-field-${index}`}
      component={Datepicker}
      fullWidth
      name={fieldValues.name}
      label={<Label fullWidth>{fieldLabel}</Label>}
      required={fieldValues.isRequired}
      validate={fieldValues.isRequired ? required : undefined}
      type="text"
      disabled={!fieldValues.canEdit}
      dateFormat="YYYY-MM-DD"
      backendDateStandard="YYYY-MM-DD"
      defaultValue={fieldValues.defaultValue}
    />
  );
}

DateFieldComponent.propTypes = propTypes;

export default DateFieldComponent;
