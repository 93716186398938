import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FieldAccordion from './components/FieldAccordion';
import FieldTypeSelector from './components/FieldTypeSelector';
import FieldControls from './components/FieldControls';
import FieldLabel from './components/FieldLabel';
import FieldGroupType from './components/FieldTypeSelector/fieldTypes/FieldGroupType';
import DriveNewFieldsModal from './components/DriveNewFieldsModal';

const propTypes = {
  index: PropTypes.number,
  fieldName: PropTypes.string,
  infoContent: PropTypes.node,
  infoBtnLabel: PropTypes.string,
  infoBtnHref: PropTypes.string,
  infoBtnTarget: PropTypes.string,
  onRemove: PropTypes.func,
  fieldData: PropTypes.object,
  parentFields: PropTypes.object,
};

function TemplateField({
  index,
  fieldName,
  fieldData,
  parentFields,
  infoBtnLabel,
  infoBtnHref,
  infoBtnTarget,
  infoContent,
  onRemove,
}) {
  const [openDrivedModal, setOpenDrivedModal] = useState(false);
  return (
    <FieldAccordion
      id={`${fieldName}-template-field-${index}`}
      fieldName={fieldName}
      fieldData={fieldData}
      infoConfig={{
        infoBtnHref,
        infoBtnLabel,
        infoBtnTarget,
        infoContent,
      }}
      infoBtnHref={infoBtnHref}
      infoBtnLabel={infoBtnLabel}
      infoBtnTarget={infoBtnTarget}
      infoContent={infoContent}
      onRemove={onRemove}
      index={index}
      closedByDefault
    >
      <FieldControls
        fieldData={fieldData}
        fieldName={fieldName}
        setOpenDrivedModal={setOpenDrivedModal}
      />
      <FieldLabel fieldData={fieldData} fieldName={fieldName} />
      {/** To avoid Dependency cycle via ../../EditFieldGroupModal,
       * we need to call FieldGroupType component
       * outside FieldTypeSelector component
       */}
      {fieldData.type === 'fieldArray' ? (
        <FieldGroupType
          fieldData={fieldData}
          fieldName={fieldName}
          parentFields={parentFields}
          parentFieldIndex={index}
          infoConfig={{
            infoBtnHref,
            infoBtnLabel,
            infoBtnTarget,
            infoContent,
          }}
        />
      ) : (
        <FieldTypeSelector fieldName={fieldName} fieldData={fieldData} />
      )}
      <DriveNewFieldsModal
        open={openDrivedModal}
        onClose={() => setOpenDrivedModal(false)}
        fieldData={fieldData}
        fieldName={fieldName}
        parentFields={parentFields}
        parentFieldIndex={index}
        infoConfig={{
          infoBtnHref,
          infoBtnLabel,
          infoBtnTarget,
          infoContent,
        }}
      />
    </FieldAccordion>
  );
}

TemplateField.propTypes = propTypes;

export default TemplateField;
