import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Col,
  Icon,
  Label,
  Row,
  Tooltip,
} from '@folio/stripes-components';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import styles from '../styles.css';

const propTypes = {
  fieldName: PropTypes.string,
  fieldData: PropTypes.object,
  setOpenDrivedModal: PropTypes.func,
  hasBorder: PropTypes.bool,
  fieldType: PropTypes.string,
};

const defaultProps = {
  hasBorder: true,
};

function FieldControls({
  fieldName,
  fieldData,
  fieldType,
  setOpenDrivedModal,
  hasBorder,
}) {
  const { type, meta, isRepeatable, isRequired, canEdit, canDrive = true } = fieldData;

  return (
    <>
      <Row>
        <Col xs={6}>
          <Label>
            <FormattedMessage
              id="ui-plugin-inventory-templates.templateField.fieldDataTypelabel"
              defaultMessage="Field type: {type}"
              values={{
                type: (
                  <FormattedMessage
                    id={`ui-plugin-inventory-templates.templateField.fieldDataTypeName.${fieldType || type}`}
                    defaultMessage={fieldType || type}
                  />
                ),
              }}
            />
          </Label>
        </Col>
        {type === 'fieldArray' && canDrive && (
          <Col xs={6} className={styles.driveBtn}>
            <Tooltip
              id="template-field-customize-data-options-tooltip"
              text={
                <FormattedMessage
                  id="ui-plugin-inventory-templates.templateField.tooltip.driveNewFields.msg"
                  defaultMessage="Drive new fields from the list"
                />
              }
            >
              {({ ref, ariaIds }) => (
                <Button
                  id="template-field-customize-data-options-btn"
                  // buttonStyle="slim"
                  marginBottom0
                  onClick={() => setOpenDrivedModal(true)}
                  aria-labelledby={ariaIds.text}
                  ref={ref}
                >
                  <Icon icon="lightning">
                    <FormattedMessage
                      id="ui-plugin-inventory-templates.templateField.tooltip.driveNewFields.msg"
                      defaultMessage="Drive new fields"
                    />
                  </Icon>
                </Button>
              )}
            </Tooltip>
          </Col>
        )}
      </Row>
      <div className={hasBorder ? styles.fieldArrayBorder : styles.fieldArray}>
        <Row>
          <Col xs={4} className={styles.fieldArray_controls}>
            <Field
              id={`${fieldName}.isRepeatable`}
              name={`${fieldName}.isRepeatable`}
              component={Checkbox}
              checked={isRepeatable}
              fullWidth
              label={
                <FormattedMessage
                  id="ui-plugin-inventory-templates.templateField.isRepeatable"
                  defaultMessage="Is repeatable"
                />
              }
              type="checkbox"
              disabled={meta?.lockRepeatableOption}
            />
          </Col>
          <Col xs={4} className={styles.fieldArray_controls}>
            <Field
              id={`${fieldName}.isRequired`}
              name={`${fieldName}.isRequired`}
              component={Checkbox}
              checked={isRequired}
              fullWidth
              label={
                <FormattedMessage
                  id="ui-plugin-inventory-templates.templateField.isRequired"
                  defaultMessage="Is required"
                />
              }
              type="checkbox"
              disabled={meta?.lockRequiredOption}
            />
          </Col>
          <Col xs={4} className={styles.fieldArray_controls}>
            <Field
              id={`${fieldName}.canEdit`}
              name={`${fieldName}.canEdit`}
              component={Checkbox}
              checked={canEdit}
              fullWidth
              label={
                <FormattedMessage
                  id="ui-plugin-inventory-templates.templateField.canEdit"
                  defaultMessage="Can edit"
                />
              }
              type="checkbox"
              disabled={meta?.lockCanEditOption}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

FieldControls.propTypes = propTypes;
FieldControls.defaultProps = defaultProps;

export default FieldControls;
