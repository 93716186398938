import React from 'react';
import { FormattedMessage } from 'react-intl';
import SectionsBuilder from '../../../ui-kit/template-builder/SectionsBuilder';
import holdingsScheme from '../../../../constants/scheme/holdingsScheme';

function HoldingsData() {
  return (
    <SectionsBuilder
      key="holdings"
      fieldArrayName="holdings"
      paneId="holdings"
      paneTitle={
        <FormattedMessage
          id="ui-plugin-inventory-templates.templateFields.holdings.builder.paneTitle"
          defaultMessage="Holdings Builder"
        />
      }
      defaultScheme={holdingsScheme}
      initialValues={[]}
    />
  );
}

HoldingsData.propTypes = {};

export default HoldingsData;
