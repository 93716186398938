import React from 'react';
import PropTypes from 'prop-types';
import SelectFieldType from './fieldTypes/SelectFieldType';
import DateFieldType from './fieldTypes/DateFieldType';
import CheckBoxFieldType from './fieldTypes/CheckBoxFieldType';

const components = {
  text: () => <div />,
  textArea: () => <div />,
  select: SelectFieldType,
  custom: () => <div />,
  date: DateFieldType,
  checkbox: CheckBoxFieldType,
  fieldArray: () => <div />,
  toggleButton: () => <div />,
  locationField: () => <div />,
};

function FieldTypeSelector({ fieldName, fieldData }) {
  const FieldComponent = components[fieldData.type];

  return <FieldComponent fieldData={fieldData} fieldName={fieldName} />;
}

FieldTypeSelector.propTypes = {
  fieldName: PropTypes.string,
  fieldData: PropTypes.object,
};

export default FieldTypeSelector;
