import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'react-final-form-arrays';
import TextFieldComponent from './components/TextFieldComponent';
import SelectFieldComponent from './components/SelectFieldComponent';
import DateFieldComponent from './components/DateFieldComponent';
import FormFieldArray from './components/FormFieldArray';
import CheckboxFieldComponent from './components/CheckboxFieldComponent';
import FieldArrayComponent from './components/FieldArrayComponent';
import ToggleButton from './components/ToggleButton';
import DrivedFieldComponent from './components/DrivedFieldComponent';
import TextAreaFieldComponent from './components/TextAreaFieldComponent';
import LocationFieldComponent from './components/LocationFieldComponent';

export const fieldTypesComponents = {
  text: TextFieldComponent,
  textArea: TextAreaFieldComponent,
  select: SelectFieldComponent,
  custom: () => <div>custom component</div>,
  date: DateFieldComponent,
  checkbox: CheckboxFieldComponent,
  fieldArray: FieldArrayComponent,
  toggleButton: ToggleButton,
  locationField: LocationFieldComponent
};

const propTypes = {
  fieldValues: PropTypes.object,
  index: PropTypes.number,
  locale: PropTypes.string,
};

function FieldGenerator({ fieldValues, index, locale }) {
  const FieldComponent = fieldTypesComponents[fieldValues.type];

  if (fieldValues.drivedFields) {
    return <DrivedFieldComponent fieldValues={fieldValues} />;
  }

  if (fieldValues.isRepeatable || fieldValues.fieldArray.length) {
    return (
      <FieldArray
        id={`form-fields-generator-${fieldValues.name}`}
        name={fieldValues.name}
        component={FormFieldArray}
        RenderFieldComponent={FieldComponent}
        fieldValues={fieldValues}
      />
    );
  }

  return (
    <FieldComponent
      key={index}
      index={index}
      fieldValues={fieldValues}
      fieldName={fieldValues.name}
      locale={locale}
    />
  );
}

FieldGenerator.propTypes = propTypes;

export default FieldGenerator;
