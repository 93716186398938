import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  Col,
  IconButton,
  KeyValue,
  Row,
} from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';
import InfoModal from '../../../ui-kit/InfoModal';
import FieldDataOptionView from './FieldDataOptionView';
import DeleteCustomFieldsModal from '../DeleteCustomFieldsModal';

const propTypes = {
  inventoryCustomFields: PropTypes.arrayOf(PropTypes.object),
  basePath: PropTypes.string,
  refetch: PropTypes.func,
};

function CustomFieldsView({ inventoryCustomFields, basePath, refetch }) {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedCustomField, setDeletedCustomField] = useState({});

  const intl = useIntl();

  const renderAccordionHeader = (fieldLabel, customField, isOpen) => {
    return (
      <>
        <IconButton
          icon="edit"
          to={`${basePath}/customfields/${customField?.id}/edit`}
          data-test-custom-field-delete-button
        />
        <IconButton
          icon="trash"
          onClick={() => {
            setDeletedCustomField(customField);
            setOpenDeleteModal(true);
          }}
          data-test-custom-field-delete-button
        />
        {!isOpen && (
          <InfoModal
            infoTitle={<strong>{fieldLabel}</strong>}
            infoContent={
              customField.helpeText || (
                <FormattedMessage
                  id="ui-plugin-inventory-templates.customFieldsView.info.defaultContent"
                  defaultMessage="No helpe text found."
                />
              )
            }
            buttonLabel={
              customField.helpeText ? (
                <FormattedMessage
                  id="ui-plugin-inventory-templates.customFieldsView.info.btnLabel.readMore"
                  defaultMessage="Reade more"
                />
              ) : undefined
            }
            buttonHref="https://docs.folio.org/docs"
            buttonTarget="_blank"
            allowAnchorClick
            iconSize="medium"
          />
        )}
      </>
    );
  };

  return (
    <>
      {inventoryCustomFields.map((customField, index) => {
        const localizedName = customField.label?.find(
          (field) => field?.locale === intl.locale,
        )?.value;

        const localizedSingularName = customField.singularLabel?.find(
          (field) => field?.locale === intl.locale,
        )?.value;

        return (
          <Accordion
            id={`${customField.name}-template-field-${index}`}
            key={customField.id}
            label={<h4>{localizedName}</h4>}
            displayWhenOpen={renderAccordionHeader(
              localizedName,
              customField,
              true,
            )}
            displayWhenClosed={renderAccordionHeader(
              localizedName,
              customField,
            )}
            closedByDefault
            separator={index !== 0}
          >
            <Row>
              {customField.isRepeatable && (
                <Col smOffset={0} sm={4}>
                  <KeyValue
                    label={
                      <FormattedMessage
                        id="ui-plugin-inventory-templates.customFields.view.singularLabel"
                        defaultMessage="Singular label"
                      />
                    }
                    value={localizedSingularName}
                  />
                </Col>
              )}
              <Col smOffset={0} sm={4}>
                <KeyValue
                  label={
                    <FormattedMessage
                      id="ui-plugin-inventory-templates.customFields.view.type"
                      defaultMessage="Field type"
                    />
                  }
                  value={customField.type}
                />
              </Col>
              <Col smOffset={0} sm={4}>
                <KeyValue
                  label={
                    <FormattedMessage
                      id="ui-plugin-inventory-templates.customFields.view.isRepeatable"
                      defaultMessage="Is repeatable"
                    />
                  }
                  value={customField.isRepeatable.toString()}
                />
              </Col>
              <Col smOffset={0} sm={4}>
                <KeyValue
                  label={
                    <FormattedMessage
                      id="ui-plugin-inventory-templates.customFields.view.helpeText"
                      defaultMessage="Helpe text"
                    />
                  }
                  value={customField.helpeText}
                />
              </Col>
            </Row>
            <Row>
              <Col smOffset={0} sm={4}>
                <KeyValue
                  label={
                    <FormattedMessage
                      id="ui-plugin-inventory-templates.customFields.view.isRequired"
                      defaultMessage="Is required"
                    />
                  }
                  value={customField.isRequired.toString()}
                />
              </Col>
              <Col smOffset={0} sm={4}>
                <KeyValue
                  label={
                    <FormattedMessage
                      id="ui-plugin-inventory-templates.customFields.view.canEdit"
                      defaultMessage="Can edit"
                    />
                  }
                  value={customField.canEdit.toString()}
                />
              </Col>
            </Row>
            {customField.type === 'select' && (
              <Row>
                <Col smOffset={0} sm={12}>
                  <KeyValue
                    label={
                      <FormattedMessage
                        id="ui-plugin-inventory-templates.customFields.view.fieldDataOption"
                        defaultMessage="Field data options"
                      />
                    }
                    value={
                      <FieldDataOptionView
                        fieldDataOption={customField.fieldDataOption}
                      />
                    }
                  />
                </Col>
              </Row>
            )}
          </Accordion>
        );
      })}
      <DeleteCustomFieldsModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        deletedCustomField={deletedCustomField}
        refetch={refetch}
      />
    </>
  );
}

CustomFieldsView.propTypes = propTypes;

export default CustomFieldsView;
