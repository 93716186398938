import React from 'react';
import PropTypes from 'prop-types';
import { Icon, InfoPopover } from '@folio/stripes-components';
import styles from './styles.css';

function InfoModal({
  infoTitle,
  infoContent,
  buttonLabel,
  iconSize = 'medium',
  buttonHref,
  buttonTarget,
}) {
  return (
    <InfoPopover
      content={
        <>
          <div className={styles.infoTitle}>
            <Icon icon="info" size="medium">
              {infoTitle}
            </Icon>
            <hr />
          </div>
          <div className={styles.infoContent}>{infoContent}</div>
        </>
      }
      allowAnchorClick
      iconSize={iconSize}
      buttonLabel={buttonLabel}
      buttonHref={buttonHref}
      buttonTarget={buttonTarget}
    />
  );
}

InfoModal.propTypes = {
  infoTitle: PropTypes.node,
  infoContent: PropTypes.node,
  buttonLabel: PropTypes.string,
  iconSize: PropTypes.string,
  buttonHref: PropTypes.string,
  buttonTarget: PropTypes.string,
};

export default InfoModal;
