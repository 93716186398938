import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import CopycatBibframeEdit from '../components/Copycat/components/CopycatRecord/CopycatEdit/CopycatBibframeEdit/CopycatBibframeEdit';
import { DataContext } from '../contexts';

function CopycatBibEditRoute(props) {
  const { id: importId } = useParams();
  const referenceData = useContext(DataContext);

  console.log('referenceData', referenceData);

  return (
    <CopycatBibframeEdit
      importId={importId}
      referenceData={referenceData}
      {...props}
    />
  );
}

CopycatBibEditRoute.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.object,
};

export default CopycatBibEditRoute;
