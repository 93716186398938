import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, TextField } from '@folio/stripes-components';
import { FieldArray } from 'react-final-form-arrays';
import { FormattedMessage } from 'react-intl';
import LocalizedTextField from '../../../LocalizedTextField';

const propTypes = {
  fieldName: PropTypes.string,
  fieldData: PropTypes.object,
};

function FieldLabel({ fieldName, fieldData }) {
  const { isRepeatable } = fieldData;

  return (
    <Row>
      <Col xs={isRepeatable ? 6 : 12}>
        <FieldArray
          id={`${fieldName}-label`}
          name={`${fieldName}.label`}
          // component={LocalizedTextField}
          render={({ fields }) => {
            return (
              <LocalizedTextField
                fields={fields}
                fieldComponent={TextField}
                fieldLabel={
                  <FormattedMessage
                    id="ui-plugin-inventory-templates.templateField.label"
                    defaultMessage="Label"
                  />
                }
              />
            );
          }}
        />
      </Col>
      {isRepeatable && (
        <Col xs={6}>
          <FieldArray
            id={`${fieldName}-singularLabel`}
            name={`${fieldName}.singularLabel`}
            // component={LocalizedTextField}
            render={({ fields }) => {
              return (
                <LocalizedTextField
                  fields={fields}
                  fieldComponent={TextField}
                  fieldLabel={
                    <FormattedMessage
                      id="ui-plugin-inventory-templates.templateField.singularLabel"
                      defaultMessage="Singular Label"
                    />
                  }
                />
              );
            }}
          />
        </Col>
      )}
    </Row>
  );
}

FieldLabel.propTypes = propTypes;

export default FieldLabel;
