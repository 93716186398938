import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon, Paneset } from '@folio/stripes-components';
import WizardForm from '../ui-kit/WizardForm';
import useTemplate from '../../hooks/useTemplate';
import TemplateFormPages from './pages';

function CreateNewInstance(props) {
  const history = useHistory();
  const params = useParams();
  const intl = useIntl();

  const { templates } = useTemplate(params.templateId);

  const localizedName = templates?.name?.find(
    (field) => field?.locale === intl.locale,
  )?.value;

  const onSubmit = (values) => {
    alert(JSON.stringify(values, 0, 2));
  };

  return (
    <Paneset>
      <WizardForm
        // initialValues={{ instance, holding, item: {} }}
        onSubmit={onSubmit}
        pages={TemplateFormPages}
        onClose={() => history.go(-1)}
        paneTitle={
          <FormattedMessage
            id="ui-plugin-inventory-templates.createNewInstance.pane.paneTitle"
            values={{ template: localizedName }}
            defaultMessage="Create new instance ({template})"
          />
        }
        appIcon={<Icon icon={templates?.icon} size="large" />}
        formId="inventory-templates-plugin-create-new-instance"
        {...props}
      >
        {/* <div style={{ paddingLeft: '150px', paddingRight: '150px' }}> */}
        {TemplateFormPages.map((page) => (
          <WizardForm.Page key={page.id}>
            <page.contenet template={templates} />
          </WizardForm.Page>
        ))}
        {/* </div> */}
      </WizardForm>
    </Paneset>
  );
}

CreateNewInstance.propTypes = {};

export default CreateNewInstance;
