import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Icon, Row } from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';
import EditFieldGroupModal from '../../EditFieldGroupModal';

const propTypes = {
  fieldName: PropTypes.string,
  fieldData: PropTypes.object,
  parentFields: PropTypes.object,
  parentFieldIndex: PropTypes.number,
  infoConfig: PropTypes.object,
};

function FieldGroupType({
  fieldName,
  fieldData,
  parentFields,
  parentFieldIndex,
  infoConfig,
}) {
  const [openEditModal, setOpenEditModal] = useState(false);

  return (
    <>
      <Row>
        <Col xs={12}>
          <Button
            id="template-field-edit-field-group-btn"
            marginBottom0
            onClick={() => setOpenEditModal(true)}
            fullWidth
          >
            <Icon icon="edit">
              <FormattedMessage
                id="ui-plugin-inventory-templates.templateField.fieldGroup.editBtn"
                defaultMessage="Edit field group"
              />
            </Icon>
          </Button>
        </Col>
      </Row>
      <EditFieldGroupModal
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        fieldData={fieldData}
        fieldName={fieldName}
        parentFields={parentFields}
        parentFieldIndex={parentFieldIndex}
        infoConfig={infoConfig}
      />
    </>
  );
}

FieldGroupType.propTypes = propTypes;

export default FieldGroupType;
