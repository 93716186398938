/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Button,
  Icon,
  PaneFooter,
  TextField,
} from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';
import { Field, Form } from 'react-final-form';

function ResultsPaneFooter({
  currentPage,
  resultSets,
  setCurrentPage,
  showCartList,
  isLoading,
}) {
  const required = (value) => (value ? undefined : (
    <strong>
      <FormattedMessage
        id="ui-inventory.copycat.form.validation.required"
        defaultMessage="Required"
      />
    </strong>
  ));

  const mustBeNumber = (value) => (!isNaN(value) ? undefined : (
    <strong>
      <FormattedMessage
        id="ui-inventory.copycat.form.validation.mustBeNumber"
        defaultMessage="Must be a number"
      />
    </strong>
  ));

  const minValue = (value) => (!(isNaN(value) || value < 1) ? undefined : (
    <strong>
      <FormattedMessage
        id="ui-inventory.copycat.form.validation.minValue"
        defaultMessage="Should be greater than 0"
      />
    </strong>
  ));

  const maxValue = (value) => (!(isNaN(value) || value > resultSets?.totalPages) ? undefined : (
    <strong>
      <FormattedMessage
        id="ui-inventory.copycat.form.validation.maxValue"
        values={{ pages: resultSets?.totalPages }}
        defaultMessage="Should be less than {pages}"
      />
    </strong>
  ));

  const composeValidators =
    (...validators) => (value) => validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

  const onFormSubmit = (values) => {
    setCurrentPage(parseInt(values.currentPage, 10));
  };
  const renderSetCurrentPageForm = () => {
    return (
      <Form
        onSubmit={onFormSubmit}
        initialValues={{
          currentPage,
        }}
        render={({ handleSubmit }) => (
          <form id="copycat-set-current-page-form" onSubmit={handleSubmit}>
            <div
              style={{
                width: '120px',
                display: 'flex',
                flexFlow: 'row',
                alignItems: 'center',
              }}
            >
              <Field
                id="copycat-query-builder-form-pageSize-select-field"
                name="currentPage"
                component={TextField}
                marginBottom0
                required
                validate={composeValidators(required, mustBeNumber, minValue, maxValue)}
              />
              <Button
                buttonStyle="slim"
                marginBottom0
                id="copycat-results-preview-pane-footer-prev-btn"
                onClick={handleSubmit}
                onMouseDown={(e) => e.preventDefault()}
              >
                <FormattedMessage
                  id="ui-inventory.copycat.buttons.goToPage"
                  defaultMessage="Go"
                />
              </Button>
            </div>
          </form>
        )}
      />
    );
  };

  return (
    <PaneFooter
      renderStart={
        !isLoading && resultSets?.totalPages && !showCartList ? (
          <>
            <div
              style={{
                display: 'flex',
                flexFlow: 'row',
                alignItems: 'center',
                justifyContent: 'space-evenly',
              }}
            >
              {renderSetCurrentPageForm()}
              <Button
                buttonStyle="slim"
                marginBottom0
                id="copycat-results-preview-pane-footer-prev-btn"
                onClick={() => setCurrentPage(currentPage - 1)}
                onMouseDown={(e) => e.preventDefault()}
                disabled={currentPage === 1}
              >
                <Icon icon="caret-left">
                  <FormattedMessage id="stripes-components.previous" />
                </Icon>
              </Button>
              <Badge size="large">
                <FormattedMessage
                  id="ui-inventory.copycat.resultsPreview.pane.footer.currentPage.label"
                  defaultMessage="Showing page {currentPage} from {pages}"
                  values={{ currentPage, pages: resultSets?.totalPages }}
                />
              </Badge>
              <Button
                buttonStyle="slim"
                marginBottom0
                id="copycat-results-preview-pane-footer-next-btn"
                onClick={() => setCurrentPage(currentPage + 1)}
                onMouseDown={(e) => e.preventDefault()}
                disabled={currentPage >= resultSets?.totalPages}
              >
                <FormattedMessage id="stripes-components.next" />
                <Icon icon="caret-right" />
              </Button>
            </div>
          </>
        ) : (
          <div />
        )
      }
    />
  );
}

ResultsPaneFooter.propTypes = {
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  resultSets: PropTypes.arrayOf(PropTypes.object),
  showCartList: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default ResultsPaneFooter;
