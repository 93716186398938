import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  Layer,
  Loading,
  ModalFooter,
  Pane,
  PaneFooter,
} from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import FormFields from './FormFields';

const propTypes = {
  customFieldId: PropTypes.string,
  localizedName: PropTypes.string,
  onClose: PropTypes.func,
  initialValues: PropTypes.object,
  onHandleSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};

function CustomFieldsForm({
  customFieldId,
  localizedName,
  onClose,
  initialValues,
  onHandleSubmit,
  isLoading,
}) {
  const renderPaneFooter = (submit, pristine, submitting) => {
    return (
      <PaneFooter
        renderEnd={
          <Button
            buttonStyle="mega primary"
            disabled={pristine || submitting}
            id="create-new-instance-pane-footer-submit-btn"
            marginBottom0
            type="submit"
            onClick={(event) => {
              submit(event);
            }}
            onMouseDown={(e) => e.preventDefault()}
          >
            <FormattedMessage
              id="stripes-components.saveAndClose"
              defaultMessage="Save and close"
            />
          </Button>
        }
        renderStart={
          <Button
            buttonStyle="mega primary"
            id="create-new-instance-pane-footer-previous-btn"
            marginBottom0
            onClick={onClose}
          >
            <Icon icon="times-circle-solid">
              <FormattedMessage
                id="stripes-components.cancel"
                defaultMessage="Previous: {prevPage}"
              />
            </Icon>
          </Button>
        }
      />
    );
  };

  return (
    <Form
      onSubmit={onHandleSubmit}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
      }}
    >
      {({ handleSubmit, pristine, submitting, values }) => {
        return (
          <form
            id="new-inventory-templates-customField-form"
            onSubmit={handleSubmit}
          >
            <Layer contentLabel="View inventory template custom fields" isOpen>
              <Pane
                id="inventory-templates-plugin-custom-fields-form-pane"
                defaultWidth="fill"
                paneTitle={
                  customFieldId ? (
                    <FormattedMessage
                      id="ui-plugin-inventory-templates.customFieldsForm.edit.pane.paneTitle"
                      values={{ customField: localizedName }}
                      defaultMessage="Edit {customField} field"
                    />
                  ) : (
                    <FormattedMessage
                      id="ui-plugin-inventory-templates.customFieldsForm..create.pane.paneTitle"
                      defaultMessage="Create new custom field"
                    />
                  )
                }
                dismissible
                onClose={onClose}
                footer={renderPaneFooter(handleSubmit, pristine, submitting)}
              >
                {isLoading && <Loading size="large" />}

                {!isLoading && <FormFields />}
                {/* <hr />
                <pre>{JSON.stringify(values, 0, 2)}</pre> */}
              </Pane>
            </Layer>
          </form>
        );
      }}
    </Form>
  );
}

CustomFieldsForm.propTypes = propTypes;

export default CustomFieldsForm;
