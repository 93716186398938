import React from 'react';
import PropTypes from 'prop-types';
import DrivedFieldArray from './DrivedFieldArray';

const propTypes = {
  fieldValues: PropTypes.object,
  repeatableFields: PropTypes.arrayOf(PropTypes.object),
};

function DrivedRepeatableFields({ fieldValues, repeatableFields }) {
  return (
    <>
      {repeatableFields.map((fieldData, index) => {
        return (
          <>
            <DrivedFieldArray
              fieldIndex={index}
              fieldData={fieldData.driveField}
              fieldValues={fieldValues}
              repeatableFields={repeatableFields.map(
                ({ driveField, ...rest }) => ({ ...rest }),
              )}
            />
          </>
        );
      })}
    </>
  );
}

DrivedRepeatableFields.propTypes = propTypes;

export default DrivedRepeatableFields;
