import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  Icon,
  DropdownMenu,
  Dropdown,
  Button,
  MenuSection,
  Checkbox,
} from '@folio/stripes-components';
import { columnMapping, sortedVisibleColumns } from '../../../utils';

const propTypes = {
  visibleColumns: PropTypes.arrayOf(PropTypes.string),
  setVisibleColumns: PropTypes.func,
};

const VisibleColumnsMenu = ({ visibleColumns, setVisibleColumns }) => {
  const [DropdownOpen, setDropdownOpen] = useState(false);
  const intl = useIntl();

  const toggleDropdown = () => {
    setDropdownOpen(!DropdownOpen);
  };

  const getDropdownContent = () => {
    return (
      <MenuSection
        label={intl.formatMessage({ id: 'ui-inventory.showColumns' })}
        id="copycat-results-preview-visible-columns-menu-section"
      >
        {sortedVisibleColumns.map((key) => (
          <Checkbox
            key={key}
            name={key}
            data-testid={key}
            label={columnMapping[key]}
            // disabled={key === 'title' && visibleColumns.length === 1}
            id={`copycat-results-preview-visible-columns-menu-checkbox-${key}`}
            checked={visibleColumns.includes(key)}
            value={key}
            onChange={() => setVisibleColumns(
              visibleColumns.includes(key)
                ? visibleColumns.filter((k) => key !== k)
                : [...visibleColumns, key]
            )
            }
          />
        ))}
      </MenuSection>
    );
  };


  const trigger = ({ getTriggerProps, ariaProps }) => {
    return (
      <Button
        buttonStyle="slim"
        marginBottom0
        {...ariaProps}
        {...getTriggerProps()}
      >
        <Icon icon="gear" size="large" />
      </Button>
    );
  };

  const renderMenu = ({ open }) => (
    <DropdownMenu open={open}>{getDropdownContent()}</DropdownMenu>
  );

  return (
    <Dropdown
      id="copycat-results-preview-visible-columns-menu"
      renderTrigger={trigger}
      renderMenu={renderMenu}
      open={DropdownOpen}
      onToggle={toggleDropdown}
      placement="bottom-start"
      relativePosition
      focusHandlers={{ open: () => null }}
    />
  );
};

VisibleColumnsMenu.propTypes = propTypes;

export default VisibleColumnsMenu;
