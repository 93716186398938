import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { FormattedMessage } from 'react-intl';

import { Pluggable } from '@folio/stripes/core';

const InventoryTemplatesRoute = ({ match }) => {
  return (
    <>
      <Pluggable
        type="inventory-templates"
        basePath={match.path}
        redirectTo="choose-source"
      >
        <FormattedMessage id="ui-inventory.templatesPluginNotAvailable" />
      </Pluggable>
    </>
  );
};

InventoryTemplatesRoute.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default InventoryTemplatesRoute;
