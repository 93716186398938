import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

function ItemsData(props) {
  return <div />;
}

ItemsData.propTypes = {};

export default ItemsData;
