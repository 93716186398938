import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Select } from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';

const propTypes = {
  fieldName: PropTypes.string,
  fieldData: PropTypes.object,
};

function CheckBoxFieldType({ fieldName, fieldData }) {
  const intl = useIntl();

  return (
    <Field
      name={`${fieldName}.defaultValue`}
      type="text"
      component={Select}
      dataOptions={[
        {
          label: intl.formatMessage({
            id: 'ui-plugin-inventory-templates.templateField.defaultValue.checkboxFieldtype.chooseMsg',
            defaultMessage: 'Choose default value',
          }),
          value: '',
        },
        {
          label: intl.formatMessage({
            id: 'ui-plugin-inventory-templates.templateField.defaultValue.checkboxFieldtype.checked',
            defaultMessage: 'Yes',
          }),
          value: true,
        },
        {
          label: intl.formatMessage({
            id: 'ui-plugin-inventory-templates.templateField.defaultValue.checkboxFieldtype.unchecked',
            defaultMessage: 'No',
          }),
          value: false,
        },
      ]}
      fullWidth
      label={
        <FormattedMessage
          id="ui-plugin-inventory-templates.templateField.defaultValue.label"
          defaultMessage="Default value"
        />
      }
    />
  );
}

CheckBoxFieldType.propTypes = propTypes;

export default CheckBoxFieldType;
