/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Icon,
  Loading,
  Pane,
  Paneset,
} from '@folio/stripes-components';
import CustomFieldsView from './components/CustomFieldsView';
import useCustomFields from '../../hooks/useCustomFields';

function CustomFieldsSettings({ basePath }) {
  const { customFields, isLoading, refetch } = useCustomFields();

  return (
    <Paneset>
      <Pane
        id="inventory-plugin-template-customFields-list-pane"
        paneTitle={
          <FormattedMessage
            id="ui-plugin-inventory-templates.customFields.pane.paneTitle"
            defaultMessage="Inventory custom fields"
          />
        }
        fluidContentWidth
        centeredContent
        lastMenu={
          <Button
            id="customFields-list-pane-lastMenu-new-btn2"
            buttonStyle="primary"
            marginBottom0
            to={`${basePath}/customfields/create`}
          >
            <Icon icon="plus-sign">
              <FormattedMessage
                id="ui-plugin-inventory-templates.customFields.buttons.createNew"
                defaultMessage="New"
              />
            </Icon>
          </Button>
        }
      >
        {isLoading && <Loading size="large" />}
        {!isLoading && customFields?.length && (
          <CustomFieldsView
            inventoryCustomFields={customFields}
            basePath={basePath}
            refetch={refetch}
          />
        )}
      </Pane>
    </Paneset>
  );
}

CustomFieldsSettings.propTypes = {
  basePath: PropTypes.string,
};

export default CustomFieldsSettings;
