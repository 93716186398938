import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from '../styles.css';

const propTypes = {
  template: PropTypes.object,
  selectedTemplateId: PropTypes.string,
  setSelectedTemplate: PropTypes.func,
  index: PropTypes.number,
};

function TemplateName({
  template,
  selectedTemplateId,
  setSelectedTemplate,
  index,
}) {
  const { id, name, icon } = template;
  const intl = useIntl();

  const localizedName = name?.find(
    (field) => field?.locale === intl.locale,
  )?.value;

  return (
    <div
      className={
        id === selectedTemplateId ? styles.selectedButton : styles.button
      }
      id={`add-new-instance-${name}-btn`}
      key={template.id}
      role="button"
      onClick={() => setSelectedTemplate(template)}
      onKeyDown={() => setSelectedTemplate(template)}
      tabIndex={index}
    >
      <Icon icon={icon} size="large">
        {localizedName}
      </Icon>
    </div>
  );
}

TemplateName.propTypes = propTypes;

export default TemplateName;
