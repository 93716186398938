import { useContext } from 'react';
import { DataContext } from '../contexts';

const useDataByName = (name) => {
  const { data } = useContext(DataContext);

  return data[name];
};

export default useDataByName;
