import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import FormFieldsGenerator from '../../ui-kit/FormFieldsGenerator';
import EmptyData from '../../ui-kit/EmptyData';

const propTypes = {
  template: PropTypes.object,
};

function InstanceData({ template }) {
  if (!template?.templateFields?.instance.length) {
    return (
      <EmptyData
        message={
          <FormattedMessage
            id="ui-plugin-inventory-templates.createTemplate.form.page.noFields"
            defaultMessage="No fields found."
          />
        }
      />
    );
  }

  return (
    <div style={{ paddingLeft: '200px', paddingRight: '200px' }}>
      <FormFieldsGenerator key="instance-template" formValues={template?.templateFields?.instance} />
    </div>
  );
}

InstanceData.propTypes = propTypes;

export default InstanceData;
