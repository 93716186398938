/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  Pane,
  PaneFooter,
  Paneset,
} from '@folio/stripes-components';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from 'react-intl';
import Stepper from '../Stepper';
import styles from './styles.css';

const propTypes = {
  initialValues: PropTypes.object,
  children: PropTypes.node,
  onSubmit: PropTypes.func,
  pages: PropTypes.arrayOf(PropTypes.string),
  paneTitle: PropTypes.node,
  appIcon: PropTypes.node,
  formId: PropTypes.string,
  onClose: PropTypes.func,
  isPageSave: PropTypes.bool,
};

function WizardForm({
  children,
  initialValues,
  onSubmit,
  pages,
  paneTitle,
  appIcon,
  formId,
  onClose,
  isPageSave = false,
}) {
  const [state, setState] = useState({
    values: initialValues || {},
    page: 0,
    isComplete: false,
  });

  useEffect(() => {
    if (initialValues) {
      setState({
        page: 0,
        values: initialValues,
        isComplete: false,
      });
    }
  }, [initialValues]);

  const activePage = useMemo(
    () => React.Children.toArray(children)[state.page],
    [state.page, children],
  );

  const isLastPage = useMemo(
    () => state.page === React.Children.count(children) - 1,
    [state.page, children],
  );

  const onNextStep = useCallback(
    () =>
      setState({
        page: Math.min(state.page + 1, children.length - 1),
        values: state.values,
        isComplete: true,
      }),
    [state.page, state.values, children.length],
  );

  const onBackStep = useCallback(
    () =>
      setState({
        page: Math.max(state.page - 1, 0),
        values: state.values,
        isComplete: false,
      }),
    [state],
  );

  const validate = useCallback(
    (values) =>
      activePage.props.validate ? activePage.props.validate(values) : {},
    [activePage],
  );

  const onHandleSubmit = useCallback(
    (values) => (isLastPage ? onSubmit(values) : onNextStep(values)),
    [isLastPage, onSubmit, onNextStep],
  );

  /** Handle calling submit button from outside the final form */
  // let submit;

  const renderPaneFooter = (pristine, submitting, submit) => {
    return (
      <PaneFooter
        renderEnd={
          <>
            {!isLastPage && (
              <>
                <Button
                  buttonStyle="mega primary"
                  //   disabled={pristine || submitting}
                  id="create-new-instance-pane-footer-next-btn"
                  marginBottom0
                  onMouseDown={(e) => e.preventDefault()}
                  type="submit"
                  onClick={(event) => {
                    submit(event);
                  }}
                >
                  <FormattedMessage
                    id="ui-plugin-inventory-templates.createNewInstance.pane.footer.nextBtn"
                    values={{ nextPage: pages[state.page + 1].name }}
                    defaultMessage="Next: {nextPage}"
                  />
                  <div className={styles.icon}>
                    <Icon icon="arrow-right" />
                  </div>
                </Button>
                {isPageSave && (
                  <Button
                    buttonStyle="mega primary"
                    //   disabled={pristine || submitting}
                    id="create-new-instance-pane-footer-submit-btn"
                    marginBottom0
                    type="submit"
                    onClick={(event) => {
                      submit(event);
                    }}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    <FormattedMessage
                      id="stripes-components.saveAndClose"
                      defaultMessage="Save and close"
                    />
                  </Button>
                )}
              </>
            )}
            {isLastPage && (
              <Button
                buttonStyle="mega primary"
                // disabled={pristine || submitting}
                id="create-new-instance-pane-footer-submit-btn"
                marginBottom0
                type="submit"
                onClick={(event) => {
                  submit(event);
                }}
                onMouseDown={(e) => e.preventDefault()}
              >
                <FormattedMessage
                  id="ui-plugin-inventory-templates.createNewInstance.pane.footer.submitBtn"
                  defaultMessage="Submit"
                />
              </Button>
            )}
          </>
        }
        renderStart={
          state.page > 0 && (
            <Button
              buttonStyle="mega primary"
              id="create-new-instance-pane-footer-previous-btn"
              marginBottom0
              onClick={onBackStep}
            >
              <Icon icon="arrow-left">
                <FormattedMessage
                  id="ui-plugin-inventory-templates.createNewInstance.pane.footer.previousBtn"
                  values={{ prevPage: pages[state.page - 1].name }}
                  defaultMessage="Previous: {prevPage}"
                />
              </Icon>
            </Button>
          )
        }
      />
    );
  };

  return (
    <Form
      validate={validate}
      onSubmit={onHandleSubmit}
      initialValues={state.values}
      subscription={{ handeleSubmit: true }}
      mutators={{
        ...arrayMutators,
      }}
      render={(renderProps) => (
        <Pane
          id={`${formId}-pane`}
          appIcon={appIcon || <div />}
          paneTitle={paneTitle}
          subheader={
            <div className={styles.form_stepper}>
              <Stepper formPages={pages} currentPage={state.page} />
            </div>
          }
          fluidContentWidth
          centeredContent
          footer={renderPaneFooter(
            renderProps.pristine,
            renderProps.submitting,
            renderProps.handleSubmit,
          )}
          dismissible
          onClose={onClose}
        >
          <form id={`${formId}-form`} onSubmit={renderProps.handleSubmit}>
            {activePage}
            {/* <hr />
            <pre>{JSON.stringify(renderProps.values, 0, 2)}</pre> */}
          </form>
        </Pane>
      )}
    />
  );
}

WizardForm.Page = ({ children }) => children;

WizardForm.propTypes = propTypes;

export default WizardForm;
