import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, TextField } from '@folio/stripes-components';
import { useIntl } from 'react-intl';
import { Field } from 'react-final-form';
import { required } from '../../../../../utils/utils';

const propTypes = {
  fields: PropTypes.object,
  fieldValues: PropTypes.object,
  unRepeatableFields: PropTypes.arrayOf(PropTypes.object),
};

function DrivedField({ fields, fieldValues, unRepeatableFields }) {
  const { formatMessage, locale } = useIntl();
  const { drivedFields } = fieldValues;

  const renderFieldArray = () => {
    return (
      <>
        {fields.map((fieldName, index) => {
          const fieldData = unRepeatableFields[index].driveField;
          const unitLabel = fieldData?.singularLabel?.find(
            (field) => field.locale === locale,
          )?.value;

          return (
            <Row key={fieldName}>
              <Col xs={12}>
                <Field
                  id={`inventory-templates-form-generator-${drivedFields?.type}-field-${index}`}
                  component={TextField}
                  fullWidth
                  name={`${fieldName}.${drivedFields.relatedTextField}`}
                  label={unitLabel || formatMessage({
                    id: `ui-plugin-inventory-templates.${drivedFields.dataOptionsSource}.name.${fieldData?.label}`,
                    defaultMessage: fieldData?.label,
                  })}
                  required={fieldData?.isRequired}
                  validate={fieldData?.isRequired ? required : undefined}
                  type="text"
                  disabled={!fieldData?.canEdit}
                />
              </Col>
            </Row>
          );
        })}
      </>
    );
  };
  return <div>{renderFieldArray()}</div>;
}

DrivedField.propTypes = propTypes;

export default DrivedField;
