import React from 'react';
import PropTypes from 'prop-types';

function CopycatMarcEdit(props) {
  return <div>CopycatMarcEdit</div>;
}

CopycatMarcEdit.propTypes = {};

export default CopycatMarcEdit;
