import React from 'react';
import PropTypes from 'prop-types';
import { Label, MultiColumnList } from '@folio/stripes-components';
import { useIntl } from 'react-intl';

const propTypes = {
  fieldDataOption: PropTypes.arrayOf(PropTypes.object),
};

function FieldDataOptionView({ fieldDataOption }) {
  const intl = useIntl();

  return (
    <MultiColumnList
      interactive={false}
      contentData={fieldDataOption}
      visibleColumns={['label', 'isDefault']}
      columnWidths={{
        label: '80%',
        isDefault: '20%',
      }}
      columnMapping={{
        label: intl.formatMessage({
          id: 'ui-plugin-inventory-templates.customFields.fieldDataOption.column.label',
          defaultMessage: 'Label',
        }),
        isDefault: intl.formatMessage({
          id: 'ui-plugin-inventory-templates.customFields.fieldDataOption.column.isDefault',
          defaultMessage: 'Is default',
        }),
      }}
      formatter={{
        label: (field) => (
          <Label>
            {field.label.find((locale) => locale.locale === intl.locale)?.value}
          </Label>
        ),
        isDefault: (field) => field.isDefault?.toString(),
      }}
    />
  );
}

FieldDataOptionView.propTypes = propTypes;

export default FieldDataOptionView;
