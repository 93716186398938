import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalFooter,
  Button,
  Icon,
  Row,
  Col,
} from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MarcRecord } from '@natlibfi/marc-record';

function QuickMarcEditModal({ onClose, open, itemToEdit }) {
  const renderFooter = () => {
    return (
      <ModalFooter>
        <Button
          id="copycat-results-preview-print-record-source-btn"
          buttonStyle="primary"
        >
          <Icon icon="print">
            <FormattedMessage
              id="ui-inventory.copycat.buttons.print"
              defaultMessage="Print"
            />
          </Icon>
        </Button>
        <Button
          id="copycat-results-preview-view-source-modal-close-btn"
          buttonStyle="slim"
          onClick={onClose}
        >
          <Icon icon="times-circle-solid" size="large">
            <FormattedMessage id="stripes-core.button.close" />
          </Icon>
        </Button>
      </ModalFooter>
    );
  };


  return (
    <Modal
      id="copycat-results-preview-view-source-modal"
      footer={renderFooter()}
      open={open}
      onClose={onClose}
      dismissible
      label={
        <Icon icon="info" size="large">
          <FormattedMessage
            id="ui-inventory.copycat.resultsPreview.QuickMarcEditModal.view.label"
            defaultMessage="View record source"
          />
        </Icon>
      }
      size="large"
    >
      <div />
    </Modal>
  );
}

QuickMarcEditModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  itemToEdit: PropTypes.object,
};

export default QuickMarcEditModal;
