import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Label } from '@folio/stripes-components';
import { useIntl } from 'react-intl';
import FieldGenerator from './FieldGenerator';

const propTypes = {
  formValues: PropTypes.arrayOf(PropTypes.object),
};

function FormFieldsGenerator({ formValues }) {
  const { locale } = useIntl();

  if (!formValues) {
    return <div>No fields found</div>;
  }

  return (
    <>
      {formValues.map((section) => (
        <div key={section.id}>
          <Accordion
            id={`template-form-generator-${section.id}`}
            label={
              <Label fullWidth>
                <h2>
                  {
                    section?.sectionLabel?.find(
                      (label) => label.locale === locale,
                    )?.value
                  }
                </h2>
              </Label>
            }
          >
            {section?.sectionFields?.map((field, index) => (
              <FieldGenerator
                key={`${section.id}`}
                fieldValues={field}
                index={index}
                locale={locale}
              />
            ))}
          </Accordion>
        </div>
      ))}
    </>
  );
}

FormFieldsGenerator.propTypes = propTypes;

export default FormFieldsGenerator;
