import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, FilterAccordionHeader } from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';
import SectionAccordion from './SectionAccordion';

const propTypes = {
  templateKey: PropTypes.string,
  template: PropTypes.object,
};

function TemplateSectionsView({ templateKey, template }) {
  const [toggle, setToggle] = useState(true);

  return (
    <div style={{ paddingLeft: '30px', paddingRight: '30px' }}>
      <Accordion
        id="plugin-inventory-templates-templateSectionsView-accordion"
        key={templateKey}
        label={
          <h2>
            <FormattedMessage
              id={`ui-plugin-inventory-templates.templateView.templateSections.namespace.${templateKey}`}
              defaultMessage={templateKey}
            />
          </h2>
        }
        onToggle={() => setToggle(!toggle)}
        open={toggle}
        separator
        header={FilterAccordionHeader}
      >
        {template.templateFields[templateKey].map((section) => {
          return <SectionAccordion section={section} key={section.sectionId} />;
        })}
      </Accordion>
    </div>
  );
}

TemplateSectionsView.propTypes = propTypes;

export default TemplateSectionsView;
