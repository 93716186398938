/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Icon,
  IconButton,
  Label,
  Row,
  Select,
  Tooltip,
} from '@folio/stripes-components';
import { Field } from 'react-final-form';
import {
  createIntl,
  createIntlCache,
  FormattedMessage,
  useIntl,
} from 'react-intl';
import { uniqueId } from 'lodash';
import { required } from '../../../utils/utils';
import useData from '../../../hooks/useData';
import styles from './styles.css';
import InfoModal from '../InfoModal';

function LocalizedTextField({
  fields,
  fieldLabel,
  fieldComponent,
  isRequired,
}) {
  const { locales } = useData();
  const intl = useIntl();

  const getLocaleLabel = (localeValue) => {
    const cache = createIntlCache();
    const locale = {
      intl: createIntl(
        {
          locale: localeValue,
          messages: {},
        },
        cache,
      ),
    };

    return intl.formatDisplayName(localeValue, { type: 'language' }) !==
      undefined
      ? `${intl.formatDisplayName(localeValue, {
          type: 'language',
        })} - ${locale.intl.formatDisplayName(localeValue, {
          type: 'language',
        })}`
      : intl.formatMessage({
          id: `stripes-core.ul.button.languageName.${localeValue}`,
        });
  };

  const getDataOptions = () => {
    return locales.map((locale) => ({
      label: getLocaleLabel(locale.value),
      value: locale.value,
      disabled:
        fields.value.find((field) => field.locale === locale.value) !==
        undefined,
    }));
  };

  const renderFieldArray = () => {
    if (!fields.length) {
      return (
        <div className={styles.emptyMsg}>
          <FormattedMessage
            id="ui-plugin-inventory-templates.localizedTextField.emptyMsg"
            values={{
              fieldLabel: <strong>{fieldLabel}</strong>,
              icon: <Icon icon="plus-sign" />,
            }}
            defaultMessage="No field labels found, click on {icon} icon to add localized {fieldLabel}"
          />
        </div>
      );
    }

    return (
      <>
        {fields.map((fieldName, index) => (
          <Row>
            <Col xs={6}>
              <Field
                id={`inventory-templates-localized-field-${fieldName}-${index}-locale-value`}
                component={fieldComponent}
                fullWidth
                name={`${fieldName}.value`}
                required
                validate={required}
                type="text"
                aria-labelledby={fieldLabel}
              />
            </Col>
            <Col xs={4}>
              <Field
                id={`inventory-templates-localized-field-${fieldName}-${index}-select-locale`}
                component={Select}
                name={`${fieldName}.locale`}
                placeholder={intl.formatMessage({
                  id: 'ui-plugin-inventory-templates.form.fields.localizedField.language.placeholder',
                  defaultMessage: 'Choose language',
                })}
                dataOptions={getDataOptions()}
                required
                validate={required}
                aria-labelledby={fieldLabel}
              />
            </Col>
            <Col xs={2} className={styles.remove_btn}>
              <Button
                id={`inventory-templates-localized-field-remove-${fieldName}-${index}-btn`}
                buttonStyle="slim"
                disabled={fields.value.length === 1}
                onClick={() => fields.remove(index)}
              >
                <Icon icon="trash" />
              </Button>
            </Col>
          </Row>
        ))}
      </>
    );
  };

  return (
    <div className={styles.container}>
      <div>
        <Row>
          <Col xs={6}>
            <Label required={isRequired}>{fieldLabel}</Label>
          </Col>
          <Col xs={6} className={styles.infoIcon}>
            <Tooltip
              id="fields-builder-hide-pane-tooltip"
              text={
                <FormattedMessage
                  id="ui-plugin-inventory-templates.localizedTextField.addLabel"
                  values={{ fieldLabel }}
                  defaultMessage="Add new {fieldLabel}"
                />
              }
            >
              {({ ref, ariaIds }) => (
                <IconButton
                  id={uniqueId(
                    'inventory-templates-localized-field-remove-btn',
                  )}
                  onClick={() => fields.push({})}
                  disabled={fields?.value?.length === locales?.length}
                  icon="plus-sign"
                  aria-labelledby={ariaIds.text}
                  ref={ref}
                />
              )}
            </Tooltip>
            <InfoModal
              infoTitle={
                <FormattedMessage
                  id="ui-plugin-inventory-templates.form.fields.localizedField.info.title"
                  defaultMessage="<strong>Translatable field</strong>"
                />
              }
              infoContent={
                <FormattedMessage
                  id="ui-plugin-inventory-templates.form.fields.localizedField.info.content"
                  values={{
                    fieldName: <strong>{fieldLabel}</strong>,
                    br: <br />,
                  }}
                  defaultMessage="You can enter {fieldName} field in more than one language. Then use these values when displaying according to the interface language."
                />
              }
            />
          </Col>
        </Row>
      </div>
      <div className={styles.fieldArray}>{renderFieldArray()}</div>
    </div>
  );
}

LocalizedTextField.propTypes = {
  fields: PropTypes.object,
  fieldLabel: PropTypes.string,
  fieldComponent: PropTypes.node,
  isRequired: PropTypes.bool,
};

export default LocalizedTextField;
