import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.css';

function Stepper({ currentPage = 0, isComplete, formPages = [] }) {
  const [margins, setMargins] = useState({
    marginLeft: 0,
    marginRight: 0,
  });

  const stepRef = useRef([]);

  useEffect(() => {
    setMargins({
      marginLeft: stepRef?.current[0]?.offsetWidth / 2,
      marginRight: stepRef?.current[formPages.length - 1]?.offsetWidth / 2,
    });
  }, [formPages, stepRef]);

  if (!formPages.length) {
    return <></>;
  }

  const calculateProgressBarWidth = () => {
    return (currentPage / (formPages.length - 1)) * 100;
  };

  return (
    <div className={styles.stepper}>
      {formPages.map((step, index) => {
        return (
          <div
            key={step.id}
            ref={(el) => {
              stepRef.current[index] = el;
            }}
            className={`${styles.step} ${
              currentPage > index || isComplete ? styles.complete : ''
            } ${currentPage === index ? styles.active : ''} `}
          >
            <div className={styles.step_number}>
              {currentPage > index || isComplete ? (
                <span>&#10003;</span>
              ) : (
                index + 1
              )}
            </div>
            <div className={styles.step_name}>{step.name}</div>
          </div>
        );
      })}

      <div
        className={styles.progress_bar}
        style={{
          width: `calc(100% - ${margins.marginLeft + margins.marginRight}px)`,
          marginLeft: margins.marginLeft,
          marginRight: margins.marginRight,
        }}
      >
        <div
          className={styles.progress}
          style={{ width: `${calculateProgressBarWidth()}%` }}
        />
      </div>
    </div>
  );
}

Stepper.propTypes = {
  formPages: PropTypes.arrayOf(PropTypes.object),
  currentPage: PropTypes.number,
  isComplete: PropTypes.bool,
};

export default Stepper;
