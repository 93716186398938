import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Layout, Loading, MultiColumnList } from '@folio/stripes-components';
import { Link } from 'react-router-dom';
import useCheckForDuplicates from '../../hooks/useCheckForDuplicates';
import { columnMapping } from '../../utils';
import ErrorMessage from '../../components/CopycatResultsPreview/components/errorMessage/ErrorMessage';

function CheckForDuplicates({
  checkForDuplicatesOptions,
  handleSubmit,
  setIsDuplicated,
  setCheckForDuplicates,
  serverName,
  setIsDuplicatedError,
}) {
  const [response, error, isLoading] = useCheckForDuplicates(
    checkForDuplicatesOptions
  );

  useEffect(() => {
    if (response?.recordList.length === 0) {
      handleSubmit();
      setCheckForDuplicates(false);
    }
    if (!isLoading && response?.recordList.length > 0) {
      setIsDuplicated(true);
    }
    if (error) {
      setIsDuplicatedError(true);
    }
  }, [isLoading, error, response?.recordList]);

  const renderLoadingDuplicates = () => {
    return (
      <>
        <FormattedMessage
          id="ui-inventory.copycat.importSingleRecord.checkForDuplicates.loading.msg"
          defaultMessage="Duplicates checking"
        />
        <Layout
          className="centered"
          style={{ maxWidth: '20rem', height: '10rem' }}
        >
          <br />
          &nbsp; &nbsp; &nbsp;
          <Loading size="xlarge" />
        </Layout>
      </>
    );
  };

  const viewInInventory = (item) => {
    return (
      <Link to={`/inventory/view/${item.id}`} target="_blank">
        {item.title}
      </Link>
    );
  };

  const renderDuplicatesPreview = () => {
    return (
      <>
        <FormattedMessage
          id="ui-inventory.copycat.importSingleRecord.checkForDuplicates.duplicateMsg"
          values={{
            title: <strong>{checkForDuplicatesOptions?.title}</strong>,
            br: <br />,
          }}
          defaultMessage="Other instances were found in the inventory with the same title:{br}{title}.{br}{br} You can process these duplicates and come back to complete the import process. if you continue, you will create a duplicate of the same title."
        />
        <br />
        <br />
        <MultiColumnList
          id="copycat-import-single-record-duplicates-list"
          interactive={false}
          visibleColumns={['rowIndex', 'title', 'Actions']}
          contentData={response?.recordList}
          columnMapping={columnMapping}
          columnWidths={{
            rowIndex: '5%',
            title: '85%',
            Actions: '10%',
          }}
          formatter={{
            rowIndex: (item) => item.rowIndex + 1,
            title: (item) => viewInInventory(item),
          }}
        />
      </>
    );
  };

  return (
    <>
      {isLoading && renderLoadingDuplicates()}
      {!isLoading &&
        !error &&
        response?.recordList.length !== 0 &&
        renderDuplicatesPreview()}
      {!isLoading && error && (
        <ErrorMessage
          errorMessage={
            <FormattedMessage
              id="ui-inventory.copycat.importSingleRecord.checkForDuplicates.errorMessage"
              values={{
                title: <strong>{checkForDuplicatesOptions?.title}</strong>,
                br: <br />,
              }}
              defaultMessage="Failed to check duplicates for title {title}.{br} The import cannot be completed before checking for duplicates. {br} Please check with your system administrator about this error."
            />
          }
          error={error.toString()}
        />
      )}
    </>
  );
}

CheckForDuplicates.propTypes = {
  checkForDuplicatesOptions: PropTypes.object,
  handleSubmit: PropTypes.func,
  setIsDuplicated: PropTypes.func,
  setCheckForDuplicates: PropTypes.func,
  serverName: PropTypes.string,
  setIsDuplicatedError: PropTypes.func,
};

export default CheckForDuplicates;
