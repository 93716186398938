import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, Icon, Modal, ModalFooter } from '@folio/stripes/components';
import { DefaultTemplate } from '../../constants/templates';
import TemplatesInfo from './components/TemplatesInfo';
import InstanceSourcesTabs from './InstanceSourcesTabs';

function ChooseTemplateTypeModal({ onClose, open, basePath }) {
  const [selectedTemplate, setSelectedTemplate] = useState(DefaultTemplate);
  const [currentTab, setCurrentTab] = useState('bibFrameTemplates');

  const renderFooter = () => {
    return (
      <ModalFooter>
        <Button
          id="choose-instance-template-type-modal-accept-btn"
          buttonStyle="primary"
          to={`${basePath}/create/${selectedTemplate?.id}`}
        >
          <FormattedMessage
            id="ui-plugin-inventory-templates.addNewModal.buttons.create"
            defaultMessage="Create"
          />
        </Button>
        <Button
          id="choose-instance-template-type-modal-cancel-btn"
          onClick={onClose}
        >
          <FormattedMessage
            id="ui-plugin-inventory-templates.addNewModal.buttons.cancel"
            defaultMessage="Cancel"
          />
        </Button>
      </ModalFooter>
    );
  };

  return (
    <Modal
      id="choose-instance-template-type-modal"
      open={open}
      onClose={onClose}
      footer={renderFooter()}
      label={
        <Icon icon="plus-sign" size="large">
          <FormattedMessage
            id="ui-plugin-inventory-templates.chooseTemplateType.modal.label"
            defaultMessage="Add new Instance"
          />
        </Icon>
      }
      dismissible
      size="large"
    >
      <InstanceSourcesTabs
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
      {currentTab === 'bibFrameTemplates' && (
        <TemplatesInfo
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
        />
      )}
      {currentTab === 'import' && (
        <div />
      )}
    </Modal>
  );
}

ChooseTemplateTypeModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  basePath: PropTypes.string.isRequired,
};

export default ChooseTemplateTypeModal;
