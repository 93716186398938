import { FormattedMessage } from 'react-intl';
import InstanceData from './InstanceData';
import HoldingsData from './HoldingsData';
import ItemsData from './ItemsData';
import TemplateInfo from './TemplateInfo';

const TemplateFormPages = [
  {
    id: '1',
    name: (
      <FormattedMessage
        id="ui-plugin-inventory-templates.createTemplate.form.page.templateInfo"
        defaultMessage="Template info"
      />
    ),
    contenet: TemplateInfo,
  },
  {
    id: '2',
    name: (
      <FormattedMessage
        id="ui-plugin-inventory-templates.createTemplate.form.page.instanceData"
        defaultMessage="Instance data"
      />
    ),
    contenet: InstanceData,
  },
  {
    id: '3',
    name: (
      <FormattedMessage
        id="ui-plugin-inventory-templates.createTemplate.form.page.holdingsData"
        defaultMessage="Holdings data"
      />
    ),
    contenet: HoldingsData,
  },
  {
    id: '4',
    name: (
      <FormattedMessage
        id="ui-plugin-inventory-templates.createTemplate.form.page.itemsData"
        defaultMessage="Items data"
      />
    ),
    contenet: ItemsData,
  },
  // {
  //   id: '5',
  //   name: (
  //     <FormattedMessage
  //       id="ui-plugin-inventory-templates.createTemplate.form.page.summary"
  //       defaultMessage="Summary"
  //     />
  //   ),
  //   contenet: <div>Template summary</div>,
  // },
];

export default TemplateFormPages;
