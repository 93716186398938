import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Layout, Loading, LoadingView } from '@folio/stripes-components';
import { stripesConnect, stripesShape, useCallout, useOkapiKy } from '@folio/stripes-core';
import { useLocation, withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import CopycatBibframeEditForm from './CopycatBibframeEditForm';
import ErrorMessage from '../../../CopycatResultsPreview/components/errorMessage/ErrorMessage';
import InstanceForm from '../../../../../../edit/InstanceForm';
import { marshalInstance, parseHttpError } from '../../../../../../utils';

function CopycatBibframeEdit(props) {
  const location = useLocation();
  const abortController = new AbortController();
  const signal = abortController.signal;
  const okapiKy = useOkapiKy();
  const callout = useCallout();

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  const { identifierTypesById, identifierTypesByName } =
    props.referenceData ?? {};
  const [httpError, setHttpError] = useState();
  // const callout = useCallout();
  // const { instance, isLoading: isInstanceLoading } = useInstance(instanceId, mutator.instanceEdit);
  // const parentInstances = useLoadSubInstances(instance?.parentInstances, 'superInstanceId');
  // const childInstances = useLoadSubInstances(instance?.childInstances, 'subInstanceId');

  const parseMarcToInstance = (record) => {
    const instanceRecord = JSON.parse(record.marcToInstance);

    instanceRecord.source = record.serverName;
    instanceRecord.title = record.title;
    instanceRecord.id = record.id;

    return instanceRecord;
  };

  const loadRecord = async () => {
    setIsLoading(true);
    try {
      const res = await okapiKy
        .get(`copycat/imported-record/${props.importId}`, {
          timeout: 30000,
          method: 'GET',
        })
        .json();
      if (!signal.aborted) {
        setInitialValues(parseMarcToInstance(res.recordData));
      }
    } catch (err) {
      if (!signal.aborted) {
        const res = err.response;
        const text = await res.text();

        let detail = text;
        if (res.headers.get('content-type') === 'application/json') {
          const obj = JSON.parse(text);
          detail = obj.errors[0].message;
        }
        setError(detail);
      }
    } finally {
      if (!signal.aborted) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (location?.state?.record) {
      setInitialValues(location.state.record);
    } else {
      loadRecord();
    }
  }, []);

  console.log(
    'initialValues: ',
    initialValues
  );

  const onSuccess = useCallback((updatedInstance) => {
    callout.sendCallout({
      type: 'success',
      message: <FormattedMessage
        id="ui-inventory.instance.successfullySaved"
        values={{ hrid: updatedInstance.hrid }}
      />,
    });
  }, [callout]);

  const onError = async saveError => {
    const parsedError = await parseHttpError(saveError);
    setHttpError(parsedError);
  };

  const onSubmit = useCallback((updatedInstance) => {
    console.log('updatedInstance: ', updatedInstance);
    return props.mutator.instanceEdit.POST(marshalInstance(updatedInstance, identifierTypesByName))
      .then(() => onSuccess(updatedInstance))
      .catch(onError);
  }, [identifierTypesByName, onError]);

  if (isLoading) return <LoadingView />;

  return (
    <>
      {props.referenceData && (
        <InstanceForm
          onSubmit={onSubmit}
          httpError={error}
          initialValues={initialValues}
          instanceSource={initialValues?.source}
          referenceTables={props.referenceData}
          stripes={props.stripes}
          onCancel={() => props.history.goBack()}
          paneTitle={initialValues?.title}
        />
      )}
      {error && <ErrorMessage error={error.toString()} />}
    </>
  );
}

CopycatBibframeEdit.manifest = Object.freeze({
  instanceEdit: {
    type: 'okapi',
    records: 'instances',
    throwErrors: false,
    path: 'inventory/instances',
    accumulate: true,
  },
});

CopycatBibframeEdit.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  importId: PropTypes.string,
  referenceData: PropTypes.object,
  stripes: stripesShape.isRequired,
  mutator: PropTypes.object.isRequired,
};

export default withRouter(stripesConnect(CopycatBibframeEdit));
