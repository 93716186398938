/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Label, Row } from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldArray } from 'react-final-form-arrays';
import RenderDrivedField from './RenderDrivedField';

const propTypes = {
  fieldValues: PropTypes.object,
  repeatableFields: PropTypes.arrayOf(PropTypes.object),
  fieldIndex: PropTypes.number,
  fieldData: PropTypes.string,
};

function DrivedFieldArray({
  fieldValues,
  repeatableFields,
  fieldIndex,
  fieldData,
}) {
  const { drivedFields } = fieldValues;

  const initialValue = React.useMemo(() => [repeatableFields[fieldIndex]], []);

  const { locale } = useIntl();

  const fieldLabel = fieldData?.collectionLabel?.find(
    (field) => field.locale === locale,
  )?.value;

  const singularLabel = fieldData?.singularLabel?.find(
    (field) => field.locale === locale,
  )?.value;

  const defaultLabel = (
    <FormattedMessage
      id={`ui-plugin-inventory-templates.${drivedFields.dataOptionsSource}.name.${fieldData?.label}`}
      defaultMessage={fieldData?.label}
    />
  );

  return (
    <>
      <Row>
        <Col xs={6}>
          <Label required={fieldData?.isRequired}>{fieldLabel}</Label>
        </Col>
      </Row>
      <Row key={fieldData.value}>
        <Col xs={12}>
          <FieldArray
            id={`form-fields-generator-${fieldValues.name}-drivedRepeatableField`}
            name={`${fieldValues?.name}.${fieldData?.label}`}
            component={RenderDrivedField}
            drivedFields={drivedFields}
            fieldData={fieldData}
            unitLabel={singularLabel || defaultLabel}
            initialValue={initialValue}
          />
        </Col>
      </Row>
    </>
  );
}

DrivedFieldArray.propTypes = propTypes;

export default DrivedFieldArray;
