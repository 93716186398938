import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Label, languageOptions, Select } from '@folio/stripes-components';
import { useIntl } from 'react-intl';
import { required } from '../../../../utils/utils';
import useDataByName from '../../../../hooks/useDataByName';

const propTypes = {
  fieldValues: PropTypes.object,
  index: PropTypes.number,
  fieldName: PropTypes.string,
  parentField: PropTypes.object,
};

function SelectFieldComponent({ fieldValues, index, fieldName, parentField }) {
  const {
    name,
    label,
    type,
    isRequired,
    canEdit,
    isRepeatable,
    dataOptionsLimits,
    dataOptionsSource,
    defaultValue,
    tableName,
    schema,
    fieldDataOption,
  } = fieldValues;

  const data = useDataByName(dataOptionsSource);
  const { locale, formatMessage } = useIntl();
  const fieldLabel = label.find((field) => field.locale === locale)?.value;
  const intl = useIntl();
  const langOptions = languageOptions(intl);

  const handleDataOptions = () => {
    const fieldOptions = [];
    (data || []).forEach((opt) => {
      fieldOptions.push({ value: opt.id, label: opt.name });
    });

    if (schema === 'default') {
      if (name === 'languages') {
        return langOptions;
      } else {
        return fieldOptions;
      }
    } else {
      return fieldDataOption;
    }
  };
  const defaultValueFormatter = () => {
    const defaultData = dataOptionsLimits?.length
      ? dataOptionsLimits
      : handleDataOptions();

    if (schema === 'default') {
      return defaultData?.map((opt) => ({
        label: intl.formatMessage({
          id: `ui-inventory.${tableName || dataOptionsSource}.name.${
            opt.label
          }`,
          defaultMessage: opt.label,
        }),
        value: opt.value,
      }));
    }

    return defaultData?.map((opt) => ({
      label: opt.label.find((loc) => loc.locale === intl.locale)?.value,
      value: opt.value,
    }));
  };

  return (
    <Field
      id={`inventory-templates-form-generator-${type}-field-${index}`}
      component={Select}
      fullWidth
      name={fieldName}
      label={
        isRepeatable || (parentField?.type === 'fieldArray' && index !== 0) ? (
          ''
        ) : (
          <Label fullWidth>{fieldLabel}</Label>
        )
      }
      dataOptions={[
        {
          label: formatMessage(
            {
              id: 'ui-plugin-inventory-templates.formGenerator.selectField.chooseMsg',
              defaultMessage: 'Choose {fieldLabel}',
            },
            { fieldLabel },
          ),
          value: '',
        },
        ...defaultValueFormatter(),
      ]}
      defaultValue={isRepeatable ? '' : defaultValue}
      required={isRequired}
      validate={isRequired ? required : () => undefined}
      type="text"
      disabled={!canEdit}
    />
  );
}

SelectFieldComponent.propTypes = propTypes;

export default SelectFieldComponent;
