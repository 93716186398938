import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Modal, ModalFooter } from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';
import { CalloutContext, useOkapiKy } from '@folio/stripes-core';

const propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  deletedCustomField: PropTypes.object,
  refetch: PropTypes.func,
};

function DeleteCustomFieldsModal({ onClose, open, deletedCustomField, refetch }) {
  const okapiKy = useOkapiKy();
  const context = useContext(CalloutContext);

  const onDelete = () => {
    okapiKy
      .delete(`inventory-customFields/${deletedCustomField.id}`, {
        timeout: 30000,
        method: 'delete',
        headers: {
          'content-Type': 'application/json',
          accept: 'text/plain',
        },
      })
      .json()
      .then(() => {
        const message = (
          <FormattedMessage
            id="ui-plugin-inventory-templates.customFieldsForm.callout.updated"
            defaultMessage="Custom field deleted successfully"
          />
        );
        context.sendCallout({ message });
        onClose();
        refetch();
      })
      .catch(async (err) => {
        const res = err.response;
        const text = await res.text();
        context.sendCallout({ message: text });
      });
  };
  const renderFooter = () => {
    return (
      <ModalFooter>
        <Button
          id="delete-custom-fields-modal-btn"
          buttonStyle="primary"
          onClick={() => onDelete()}
        >
          <FormattedMessage
            id="ui-plugin-inventory-templates.deleteCustomFieldsModal.buttons.delete"
            defaultMessage="Delete"
          />
        </Button>
        <Button
          id="choose-instance-template-type-modal-cancel-btn"
          onClick={onClose}
        >
          <FormattedMessage
            id="ui-plugin-inventory-templates.deleteCustomFieldsModal.buttons.cancel"
            defaultMessage="Cancel"
          />
        </Button>
      </ModalFooter>
    );
  };

  return (
    <Modal
      id="delete-custom-fields-modal"
      open={open}
      onClose={onClose}
      footer={renderFooter()}
      label={
        <Icon icon="plus-sign" size="large">
          <FormattedMessage
            id="ui-plugin-inventory-templates.deleteCustomFieldsModal.label"
            defaultMessage="Delete Custom field"
          />
        </Icon>
      }
      dismissible
    >
      <FormattedMessage
        id="ui-plugin-inventory-templates.deleteCustomFieldsModal"
        defaultMessage="Are you sure to delete"
      />
    </Modal>
  );
}

DeleteCustomFieldsModal.propTypes = propTypes;
export default DeleteCustomFieldsModal;
