import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Pane, PaneFooter } from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';
import { propTypes } from '@folio/stripes-components/lib/Button';
import { AppIcon } from '@folio/stripes-core';
import {
  InstanceAdministrativeView,
  InstanceClassificationView,
  InstanceContributorsView,
  InstanceDescriptiveView,
  InstanceElecAccessView,
  InstanceIdentifiersView,
  InstanceNotesView,
  InstanceSubjectView,
  InstanceTitle,
  InstanceTitleData,
} from '../../../../../Instance';
import { DataContext } from '../../../../../contexts';
import ItemActionsMenu from './ItemActionsMenu';

const accordions = {
  administrative: 'acc01',
  title: 'acc02',
  identifiers: 'acc03',
  contributors: 'acc04',
  descriptiveData: 'acc05',
  notes: 'instance-details-notes',
  electronicAccess: 'acc07',
  subjects: 'acc08',
  classifications: 'acc09',
  relationship: 'acc10',
  acquisition: 'acc11',
};

function CopycatViewRecord({
  showRecordPane,
  onClose,
  selectedItem,
  setOpenViewSourceModal,
  setOpenImportRecordModal,
  cartList,
  setCartList,
  showCartList,
  setShowCartList,
}) {
  const referenceData = useContext(DataContext);

  const parseMarcToInstance = () => {
    return (
      selectedItem.marcToInstance && JSON.parse(selectedItem.marcToInstance)
    );
  };

  console.log('selectedItem: ', parseMarcToInstance());


  const renderPaneLastMenu = () => {
    return (
      <ItemActionsMenu
        setOpenViewSourceModal={setOpenViewSourceModal}
        setCartList={setCartList}
        cartList={cartList}
        showCartList={showCartList}
        setShowCartList={setShowCartList}
        selectedItem={selectedItem}
      />
    );
  };

  const renderPaneFooter = () => {
    return (
      <PaneFooter
        renderEnd={
          <Button
            buttonStyle="primary"
            id="copycat-view-record-pane-footer-import-btn"
            marginBottom0
            type="submit"
            onClick={() => {
              setOpenImportRecordModal(true);
            }}
            onMouseDown={(e) => e.preventDefault()}
          >
            <FormattedMessage
              id="ui-inventory.copycat.buttons.import"
              defaultMessage="Import"
            />
          </Button>
        }
      />
    );
  };

  return (
    <>
      {showRecordPane && Object.keys(selectedItem).length !== 0 ? (
        <Pane
          id="copycat-record-detail-pane"
          appIcon={<AppIcon app="inventory" iconKey="instance" />}
          defaultWidth="30%"
          paneTitle={selectedItem?.title}
          dismissible
          onClose={onClose}
          fluidContentWidth
          centeredContent
          lastMenu={renderPaneLastMenu()}
          footer={renderPaneFooter()}
        >
          <InstanceTitle
            instance={parseMarcToInstance()}
            instanceTypes={referenceData.instanceTypes}
          />
          <InstanceAdministrativeView
            id={accordions.administrative}
            instance={parseMarcToInstance()}
            instanceStatuses={referenceData.instanceStatuses}
            issuanceModes={referenceData.modesOfIssuance}
            statisticalCodes={referenceData.statisticalCodes}
            statisticalCodeTypes={referenceData.statisticalCodeTypes}
          />

          <InstanceTitleData
            id={accordions.title}
            instance={parseMarcToInstance()}
            titleTypes={referenceData.alternativeTitleTypes}
            identifierTypesById={referenceData.identifierTypesById}
            source={parseMarcToInstance().source}
            segment="instances"
          />

          <InstanceIdentifiersView
            id={accordions.identifiers}
            identifiers={parseMarcToInstance().identifiers}
            identifierTypes={referenceData.identifierTypes}
          />

          <InstanceContributorsView
            id={accordions.contributors}
            contributors={parseMarcToInstance().contributors}
            contributorTypes={referenceData.contributorTypes}
            contributorNameTypes={referenceData.contributorNameTypes}
            source={parseMarcToInstance().source}
            segment="instances"
          />

          <InstanceDescriptiveView
            id={accordions.descriptiveData}
            instance={parseMarcToInstance()}
            resourceTypes={referenceData.instanceTypes}
            resourceFormats={referenceData.instanceFormats}
            natureOfContentTerms={referenceData.natureOfContentTerms}
          />

          <InstanceNotesView
            id={accordions.notes}
            instance={parseMarcToInstance()}
            noteTypes={referenceData.instanceNoteTypes}
          />

          <InstanceElecAccessView
            id={accordions.electronicAccess}
            electronicAccessLines={parseMarcToInstance().electronicAccess}
            electronicAccessRelationships={
              referenceData.electronicAccessRelationships
            }
          />

          <InstanceSubjectView
            id={accordions.subjects}
            subjects={parseMarcToInstance().subjects}
            source={parseMarcToInstance().source}
            segment="instances"
          />

          <InstanceClassificationView
            id={accordions.classifications}
            classifications={parseMarcToInstance()?.classifications}
            classificationTypes={referenceData.classificationTypes}
          />

          {/* <InstanceAcquisition
            accordionId={accordions.acquisition}
            instanceId={parseMarcToInstance().id}
          /> */}

          {/* <InstanceRelationshipView
            id={accordions.relationship}
            parentInstances={instance.parentInstances}
            childInstances={instance.childInstances}
          /> */}
        </Pane>
      ) : (
        <div />
      )}
    </>
  );
}

CopycatViewRecord.propTypes = {
  onClose: PropTypes.func,
  showRecordPane: propTypes.bool,
  selectedItem: PropTypes.object,
  setOpenViewSourceModal: PropTypes.func,
  setOpenImportRecordModal: PropTypes.func,
  cartList: PropTypes.arrayOf(PropTypes.object),
  setCartList: PropTypes.func,
  showCartList: PropTypes.bool,
  setShowCartList: PropTypes.func,
};

export default CopycatViewRecord;
