import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Checkbox, Label, TextField } from '@folio/stripes-components';

import { useIntl } from 'react-intl';
import { required } from '../../../../utils/utils';

const propTypes = {
  fieldValues: PropTypes.object,
  index: PropTypes.number,
  fieldName: PropTypes.string,
  parentField: PropTypes.object,
};

function CheckboxFieldComponent({
  fieldValues,
  index,
  fieldName,
  parentField,
}) {
  const { locale } = useIntl();
  const { label, type, isRequired, canEdit, defaultValue, isRepeatable } =
    fieldValues;

  const fieldLabel = label.find((field) => field.locale === locale)?.value;

  return (
    <Field
      id={`inventory-templates-form-generator-${type}-field-${index}`}
      // label={<Label fullWidth>{fieldLabel}</Label>}
      label={
        isRepeatable || (parentField?.type === 'fieldArray' && index !== 0) ? (
          ''
        ) : (
          <Label fullWidth>{fieldLabel}</Label>
        )
      }
      name={fieldName}
      component={Checkbox}
      type="checkbox"
      required={isRequired}
      validate={isRequired ? required : undefined}
      disabled={!canEdit}
      defaultValue={defaultValue}
      inline={parentField?.type === 'fieldArray'}
      vertical={parentField?.type === 'fieldArray'}
    />
  );
}

CheckboxFieldComponent.propTypes = propTypes;

export default CheckboxFieldComponent;
