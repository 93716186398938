import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonGroup } from '@folio/stripes/components';

const tabsList = [
  {
    tabName: 'bibFrameTemplates',
    tabLabel: (
      <FormattedMessage
        id="ui-plugin-inventory-templates.instanceSourcesTabs.bibFrameTemplates"
        defaultMessage="Bibframe templates"
      />
    ),
  },
  {
    tabName: 'marcTemplates',
    tabLabel: (
      <FormattedMessage
        id="ui-plugin-inventory-templates.instanceSourcesTabs.marcTemplates"
        defaultMessage="MARC templates"
      />
    ),
  },
  {
    tabName: 'import',
    tabLabel: (
      <FormattedMessage
        id="ui-plugin-inventory-templates.instanceSourcesTabs.importFromExternalResources"
        defaultMessage="Import from external resources"
      />
    ),
  },
];

const InstanceSourcesTabs = ({ currentTab, setCurrentTab }) => (
  <ButtonGroup fullWidth>
    {tabsList.map((tab) => (
      <Button
        key={`${tab.tabName}`}
        onClick={() => setCurrentTab(tab.tabName)}
        onMouseDown={(e) => e.preventDefault()}
        buttonStyle={`${currentTab === tab.tabName ? 'primary' : 'default'}`}
        aria-selected={currentTab === tab.tabName}
        id={`inventory-templates-addFieldsTabs-${tab.tabName}`}
      >
        {tab.tabLabel}
      </Button>
    ))}
  </ButtonGroup>
);

InstanceSourcesTabs.propTypes = {
  currentTab: PropTypes.string,
  setCurrentTab: PropTypes.func,
};

export default InstanceSourcesTabs;
